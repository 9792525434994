import React, { Fragment, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Redirect } from 'react-router-dom';
import {
  Modal,
  Form,
  Select,
  Button,
  Radio,
  Row,
  Col,
  Image,
  Typography,
} from 'antd';
import { isMobile } from 'react-device-detect';

import { languages } from '../../../../I18n/TranslateText';

import PrintPreview from './PrintPreview';
import LayoutA from '../../../../img/A.png';
import LayoutB from '../../../../img/B.png';
import LayoutC from '../../../../img/C.png';

const PrintPreviewModal = ({ open, handleClose, printProps }) => {
  const { currentPatient } = printProps;

  const [currLanguage, setCurrLanguage] = useState(null);
  const [currLayout, setCurrLayout] = useState('A');
  const printRef = useRef(null);

  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.submit();

    setCurrLanguage(form.getFieldValue('currLanguage'));
    setCurrLayout(form.getFieldValue('currLayout'));
  };

  return (
    <Modal
      title="Print Preview"
      open={open}
      onCancel={handleClose}
      maskClosable={false}
      width={950}
      centered
      bodyStyle={{
        ...(currLanguage && { height: '80vh' }),
        overflow: 'auto',
      }}
      footer={[
        currLanguage ? (
          <Fragment>
            <ReactToPrint
              onBeforeGetContent={() => {
                document.title = `${currentPatient.FirstName}_${currentPatient.LastName}`;
              }}
              onAfterPrint={() => {
                document.title = 'PT Wired';
              }}
              removeAfterPrint={true}
              content={() => printRef.current}
              trigger={() => (
                <Button type="primary" className="ptw-btn">
                  Print
                </Button>
              )}
            />
            <Button onClick={() => setCurrLanguage(null)}>
              Change Settings
            </Button>
          </Fragment>
        ) : (
          <Button type="primary" className="ptw-btn" onClick={handleSubmit}>
            Continue
          </Button>
        ),
        <Button
          type="default"
          className="ptw-btn"
          onClick={() => {
            form.resetFields();
            setCurrLanguage(null);
            setCurrLayout('A');
            handleClose();
          }}
        >
          Cancel
        </Button>,
      ]}
    >
      {currLanguage ? (
        <Fragment>
          {isMobile ? (
            <Redirect
              to={{
                pathname: '/print-preview',
                state: { currLocale: currLanguage, currLayout, printProps },
              }}
            />
          ) : (
            <PrintPreview
              printRef={printRef}
              currLocale={currLanguage}
              currLayout={currLayout}
              printProps={printProps}
            />
          )}
        </Fragment>
      ) : (
        <Form
          form={form}
          initialValues={{ currLanguage: 'en', currLayout: 'A' }}
        >
          <Form.Item
            label="Language"
            name="currLanguage"
            help="Select the language you'd like to use for this printout."
          >
            <Select
              showSearch
              placeholder="Select a language"
              filterOption={(input, option) => {
                if (option && option.label) {
                  const label = String(option.label).toLowerCase();
                  if (label.includes(String(input).toLowerCase())) {
                    return option;
                  }
                }
              }}
              options={languages
                .sort((a, b) => a.name - b.name)
                .map(l => ({
                  value: l.locale,
                  label: l.name,
                }))}
            />
          </Form.Item>
          <Form.Item label="Layout" name="currLayout" style={{ marginTop: 36 }}>
            <Radio.Group>
              <Row gutter={[24, 24]}>
                <Col span={8} align="center">
                  <Radio value="A" onChange={() => setCurrLayout('A')}>
                    <Image
                      src={LayoutA}
                      preview={false}
                      alt="Layout A"
                      className={currLayout === 'A' && 'selected-layout'}
                    />
                    <Typography.Text>4 Exercises Per Page</Typography.Text>
                  </Radio>
                </Col>
                <Col span={8} align="center">
                  <Radio value="B" onChange={() => setCurrLayout('B')}>
                    <Image
                      src={LayoutB}
                      preview={false}
                      alt="Layout B"
                      className={currLayout === 'B' && 'selected-layout'}
                    />
                    <Typography.Text>2 Exercises Per Page</Typography.Text>
                  </Radio>
                </Col>
                <Col span={8} align="center">
                  <Radio value="C" onChange={() => setCurrLayout('C')}>
                    <Image
                      src={LayoutC}
                      preview={false}
                      alt="Layout C"
                      className={currLayout === 'C' && 'selected-layout'}
                    />
                    <Typography.Text>1 Exercise Per Page</Typography.Text>
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default PrintPreviewModal;
