import { Space, Typography } from 'antd';
import React, { Fragment } from 'react';
import TextTranslation from '../../../I18n/TranslateText';

const formatPhoneNumber = phoneNumber => {
  if (phoneNumber.includes('-')) {
    return phoneNumber;
  }

  return (
    '(' +
    phoneNumber.slice(0, 3) +
    ')' +
    phoneNumber.slice(3, 6) +
    '-' +
    phoneNumber.slice(6)
  );
};

const PDFFooter = ({ profile, creatorEmail, currLocale = 'en' }) => {
  const { GroupInfo } = profile;

  const getClinicInfo = () => {
    if (profile.Clinic && profile.Clinics) {
      const { Clinic, Clinics } = profile;
      const clinicInfo = Clinics.find(
        item => String(item.Id) === String(Clinic)
      );

      return {
        Email: clinicInfo ? clinicInfo.ClinicEmail : creatorEmail,
        PhoneNumber: clinicInfo
          ? clinicInfo.ClinicPhoneNumber
          : GroupInfo.PhoneNumber,
      };
    } else {
      return {
        Email: creatorEmail,
        PhoneNumber: GroupInfo.PhoneNumber,
      };
    }
  };

  return (
    <Space direction="vertical" size={2}>
      <Typography.Title level={4}>
        <TextTranslation
          currLocale={currLocale}
          currText={'Have Questions? Contact us'}
        />
      </Typography.Title>
      {GroupInfo.PrintEmail ? (
        <Fragment>
          {GroupInfo.PrintEmail === 'clinic' ? (
            <Fragment>
              <Typography.Text>
                <strong>
                  <TextTranslation
                    currLocale={currLocale}
                    currText={'Phone: '}
                  />
                </strong>
                {formatPhoneNumber(getClinicInfo().PhoneNumber)}
              </Typography.Text>
              <Typography.Text>
                <strong>
                  <TextTranslation
                    currLocale={currLocale}
                    currText={'Email: '}
                  />
                </strong>
                {getClinicInfo().Email}
              </Typography.Text>
            </Fragment>
          ) : (
            <Fragment>
              <Typography.Text>
                <strong>
                  <TextTranslation
                    currLocale={currLocale}
                    currText={'Phone: '}
                  />
                </strong>
                {formatPhoneNumber(getClinicInfo().PhoneNumber)}
              </Typography.Text>
              <Typography.Text>
                <strong>
                  <TextTranslation
                    currLocale={currLocale}
                    currText={'Email: '}
                  />
                </strong>
                {creatorEmail}
              </Typography.Text>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Typography.Text>
            <strong>
              <TextTranslation currLocale={currLocale} currText={'Phone: '} />
            </strong>
            {formatPhoneNumber(getClinicInfo().PhoneNumber)}
          </Typography.Text>
          <Typography.Text>
            <strong>
              <TextTranslation currLocale={currLocale} currText={'Email: '} />
            </strong>
            {creatorEmail}
          </Typography.Text>
        </Fragment>
      )}
    </Space>
  );
};

export default PDFFooter;
