import apiFetch from '../utils/fetch.utils';

export const getList = async groupId => {
  try {
    const method = 'GET';
    const uri = `exercises/template?groupId=${groupId}`;

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw err;
  }
};
