import apiFetch from '../utils/fetch.utils';

export const getLink = async (email, groupId) => {
  try {
    const uri = `leanworlds?email=${encodeURIComponent(
      email
    )}&groupId=${encodeURIComponent(groupId)}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
