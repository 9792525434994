import { atom } from 'recoil';

const defaultState = {
  analytics: []
};

const RTM_STATE = atom({
  key: 'RTM_STATE',
  default: defaultState
});

export default RTM_STATE;
