import React, { Component, Fragment } from 'react';
import {
  Typography,
  Space,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Tag,
  Popover
} from 'antd';
import '../../styles/atomic.css';
import {
  openModal,
  updateCurrentUser,
  dischargePatient,
  getValidationLink
} from './actions/patients';
import { isLoading, doneLoading, getAdmins } from '../users/actions/users';
import AssignProviderModal from './Modals/AssignProviderModal';
import DeletePatientWindow from './DeletePatientWindow';
import DischargeModal from './Modals/Discharge';
import MessageModal from '../../components/MessageModal';
import VideoModal from '../video/VideoModal';
import PatientDetailDrawer from './../patients/RTMDashboard/components/PatientDetailDrawer/index';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import v from 'voca';
import {
  DeleteOutlined,
  DesktopOutlined,
  EditOutlined,
  LinkOutlined,
  MailOutlined,
  MessageOutlined,
  MoreOutlined,
  PlusSquareFilled,
  SettingOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import ToggleMessagingModal from './Modals/ToggleMessagingModal';
import setNotification from '../../utils/setNotification.utils';

import { getRecoil } from 'recoil-nexus';
import states from '../../states';
import services from '../../services';

const getCardColorIndicator = (status) => {
  switch (status) {
    case 'GREEN':
      return {
        title: 'Ready for Billing',
        className: 'card-ready-to-bill',
        description: `This patient has met billing requirements for one or more RTM Codes. Click on the 'RTM' badge and select the 'Billing' tab for more information on the bill dates, codes, and supporting documentation.`
      };
    case 'BLUE':
      return {
        title: 'On Track for Billing',
        className: 'card-high-engagement',
        description: `Based on this patient's current activity levels thus far in the billing period, they will be eligible for billing code 98977 and the end of the billing period.`
      };
    case 'YELLOW':
      return {
        title: 'Not On Track for Billing',
        className: 'card-low-engagement',
        description: `Based on this patient's current activity levels thus far in the billing period, they will NOT be eligible for billing code 98977 and the end of the billing period.`
      };
    case 'RED':
      return {
        title: 'No Activity Reported',
        className: 'card-no-engagement',
        description: `This patient has not used the app at all thus far in the current billing cycle.`
      };
    default:
      return {
        title: '',
        className: ''
      };
  }
};

const popoverContent = (description) => (
  <Space direction="vertical">
    <div className="popover-title-container">
      <Space direction="vertical" style={{ width: 350 }}>
        <Typography.Text>{description}</Typography.Text>
      </Space>
    </div>
  </Space>
);

class PatientPageInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeletePatient: false,
      showDischarge: false,
      showVideo: false,
      showMessage: null,
      showAssignProvider: false,
      discharge: false,
      activationLink: null,
      ccActivationLinkBtnDisabled: false,
      openPatientDetail: false,
      showManagePreferences: false
    };
  }

  async componentDidMount() {
    const { patient } = this.props;
    this.setState({
      discharge: patient.Discharge
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { showMessage } = this.state;

    if (showMessage === null) return;

    if (showMessage !== prevState.showMessage) {
      const { history, setExitActivity, clearMonitoredPatient } = this.props;

      if (showMessage === false) {
        const prescriptionState = getRecoil(states.prescription);
        const { list } = prescriptionState;

        if (
          history &&
          history.location.pathname.includes('/patients') &&
          list.length <= 1
        ) {
          setExitActivity('View Adherence Log');
        } else {
          clearMonitoredPatient();
        }
      } else {
        setExitActivity('Message Patient');
      }
    }
  }

  fetchAdmins = async () => {
    const {
      dispatch,
      visibleProfile: { GroupId }
    } = this.props;
    dispatch(isLoading());

    const queries = [];

    queries.push(dispatch(getAdmins(GroupId)));

    await Promise.all(queries).then(() => {
      dispatch(doneLoading());
    });
  };

  dischargePatient = () => {
    this.setState({ showDischarge: true });
  };

  hideDischarge = () => {
    this.setState({ showDischarge: false });
  };

  dischargeAction = async (activeValue, isDischarged) => {
    const { patient, dispatch, visibleProfile } = this.props;
    const { discharge } = this.state;
    const { GroupId, Sub } = patient;
    const {
      GroupInfo: { GroupEmailDisplay }
    } = visibleProfile;

    await dispatch(
      dischargePatient(
        GroupEmailDisplay,
        Sub,
        GroupId,
        isDischarged,
        activeValue
      ),
      () => {
        dispatch(updateCurrentUser(patient));
      }
    );

    if (isDischarged === false) {
      const ptFname = v.titleCase(visibleProfile.FirstName);
      const ptLname = v.capitalize(visibleProfile.LastName);

      services.email.send('readmission', Sub, ptFname, ptLname);
      setNotification('success', 'Success!', 'Patient has been readmitted.');
    } else {
      ReactGA.event({
        category: 'Patient',
        action: 'Discharge',
        label: GroupEmailDisplay
      });

      if (activeValue > 0) {
        setNotification(
          'success',
          'Success!',
          'Patient has been set to discharge.'
        );
      } else {
        setNotification('success', 'Success!', 'Patient has been discharged.');
      }
    }

    this.setState({
      discharge: !discharge
    });
  };

  handleShowDeletePatient = () => {
    const { showDeletePatient } = this.state;
    this.setState({
      showDeletePatient: !showDeletePatient
    });
  };

  handleShowAssignProvider = () => {
    const { showAssignProvider } = this.state;
    this.setState({
      showAssignProvider: !showAssignProvider
    });
  };

  handleShowManagePreferences = () => {
    const { showManagePreferences } = this.state;
    this.setState({
      showManagePreferences: !showManagePreferences
    });
  };

  hasExercises = () => {
    const { patient } = this.props;
    const { Prescription } = patient;

    return Prescription.exercises && Prescription.exercises.length !== 0;
  };

  handleResendSetupLink = async () => {
    const { patient, dispatch, visibleProfile } = this.props;
    const { activationLink } = this.state;
    const { EmailAddress, PhoneNumber, Sub, Providers } = patient;
    const { providers } = getRecoil(states.groups);

    let ptFname = visibleProfile.FirstName;
    let ptLname = visibleProfile.LastName;

    if (Array.isArray(Providers) && Providers.length) {
      if (Providers.length > 1) {
        ptFname = 'Your';
        ptLname = 'Therapists';
      } else {
        const provider = providers.find((p) => p.Sub === Providers[0]);
        const isActive = !!provider?.Active;

        if (isActive) {
          ptFname = provider.FirstName;
          ptLname = provider.LastName;
        }
      }
    } else if (patient.Creator !== visibleProfile.Sub) {
      const provider = providers.find((p) => p.Sub === patient.Creator);
      const isActive = !!provider?.Active;

      if (isActive) {
        ptFname = provider.FirstName;
        ptLname = provider.LastName;
      }
    }

    let type = 'success';
    let message = 'Success!';
    let desc = 'Activation email has been re-sent.';

    try {
      if (!activationLink) {
        const input = EmailAddress || PhoneNumber;
        const response = await dispatch(getValidationLink(input));
        if (response && response.value && response.value.code) {
          this.setState({
            activationLink: response.value.code
          });
        }
      }

      await services.email.send('newPatient', Sub, ptFname, ptLname);
    } catch (error) {
      type = 'error';
      message = 'Error!';
      desc = 'Failed to send setup link. Please contact customer service.';
    } finally {
      setNotification(type, message, desc);
    }
  };

  handleCopySetupLink = async () => {
    const {
      patient: { EmailAddress, PhoneNumber },
      dispatch
    } = this.props;

    const { activationLink } = this.state;
    let link = activationLink;

    try {
      this.setState({
        ccActivationLinkBtnDisabled: true
      });

      if (!activationLink) {
        console.log('phoneNumber', PhoneNumber);
        const input = PhoneNumber || EmailAddress;
        const response = await dispatch(getValidationLink(input));
        if (response && response.value && response.value.code) {
          link = response.value.code;

          this.setState({
            activationLink: response.value.code
          });
        }
      }

      if (link) {
        const clip = `${window.location.origin}/#/validate/${link}`;
        const input = document.createElement('input');

        document.body.appendChild(input);
        input.value = clip;
        input.textContent = clip;

        const selection = getSelection();
        const range = document.createRange();

        range.selectNode(input);
        selection.removeAllRanges();
        selection.addRange(range);

        await navigator.clipboard.writeText(clip);

        setNotification(
          'success',
          'Success!',
          'Patient activation link has been copied to your clipboard.'
        );
        document.body.removeChild(input);
      }
    } catch (error) {
      console.log(error);

      setNotification(
        'error',
        'Error!',
        'Could not copy to clipboard. Please try again later.'
      );
    } finally {
      this.setState({
        ccActivationLinkBtnDisabled: false
      });
    }
  };

  // Patient Details Functions
  handleOpenPatientDetail = () => {
    this.setState({ openPatientDetail: !this.state.openPatientDetail });
  };

  contactDetails = () => {
    const { patient } = this.props;
    const { GroupInfo, EmailAddress, PhoneNumber } = patient;

    if (!!GroupInfo.EnablePhoneNumber) {
      if (EmailAddress && PhoneNumber) {
        return `${EmailAddress} | ${PhoneNumber}`;
      } else {
        return EmailAddress || PhoneNumber;
      }
    } else {
      return EmailAddress || 'NOT AVAILABLE';
    }
  };

  render() {
    const {
      patient,
      patient: { EmailAddress, PhoneNumber },
      dispatch,
      visibleProfile,
      visibleProfile: { Role, GroupInfo, FirstName, LastName },
      history,
      patientAnalytics,
      hasPendingBill
    } = this.props;
    const {
      showDeletePatient,
      showAssignProvider,
      showDischarge,
      showVideo,
      discharge,
      showMessage,
      ccActivationLinkBtnDisabled,
      openPatientDetail,
      showManagePreferences
    } = this.state;

    const patientsState = getRecoil(states.patients);
    const { details } = patientsState;
    const toggle =
      details &&
      details.preferences &&
      details.preferences.messaging &&
      details.preferences.messaging === 'disabled'
        ? 'Enable'
        : 'Disable';

    const engagementStatus =
      patientAnalytics && getCardColorIndicator(patientAnalytics.Status);

    const patientDropdown = (
      <Menu className="patient-profile-dropdown">
        <Menu.Item
          key="edit-patient"
          onClick={() => {
            dispatch(openModal);
          }}
          icon={<EditOutlined style={{ fontSize: 18 }} />}
        >
          Edit
        </Menu.Item>

        {this.hasExercises && (
          <Menu.Item
            key="delete-patient"
            onClick={this.handleShowDeletePatient}
            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
          >
            Delete
          </Menu.Item>
        )}

        {this.hasExercises && (
          <Menu.Item
            key="assign-providers"
            onClick={this.handleShowAssignProvider}
            icon={<UsergroupAddOutlined style={{ fontSize: 18 }} />}
          >
            Assign Providers
          </Menu.Item>
        )}

        {this.hasExercises && (
          <Menu.Item
            key="resend-setup-email"
            onClick={this.handleResendSetupLink}
            icon={<MailOutlined style={{ fontSize: 18 }} />}
          >
            Resend Setup Link
          </Menu.Item>
        )}

        {this.hasExercises && (
          <Menu.Item
            key="copy-setup-link"
            disabled={ccActivationLinkBtnDisabled}
            onClick={this.handleCopySetupLink}
            icon={<LinkOutlined style={{ fontSize: 18 }} />}
          >
            Copy Setup Link
          </Menu.Item>
        )}

        {this.hasExercises && (
          <Menu.Item
            key="enable-messaging"
            onClick={this.handleShowManagePreferences}
            icon={<SettingOutlined style={{ fontSize: 18 }} />}
          >
            {toggle} Messaging
          </Menu.Item>
        )}

        {this.hasExercises && (
          <Menu.Item
            key="readmit-or-discharge"
            onClick={this.dischargePatient}
            icon={
              discharge ? (
                <PlusSquareFilled style={{ fontSize: 18 }} />
              ) : (
                <span style={{ marginRight: 8 }}>
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    style={{ fontSize: 18 }}
                  />
                </span>
              )
            }
          >
            {discharge ? 'Readmit Patient' : 'Discharge'}
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <React.Fragment>
        {showDischarge && (
          <DischargeModal
            hasPendingBill={hasPendingBill}
            doDischarge={discharge}
            onHide={this.hideDischarge}
            dischargeAction={this.dischargeAction}
          />
        )}
        {showMessage && (
          <MessageModal
            open={showMessage}
            onClose={() => this.setState({ showMessage: false })}
            sub={patient.Sub}
          />
        )}
        <AssignProviderModal
          showAssignProvider={showAssignProvider}
          handleShowAssignProvider={this.handleShowAssignProvider}
        />
        <DeletePatientWindow
          groupName={GroupInfo.Name}
          showDeletePatient={showDeletePatient}
          handleShowDeletePatient={this.handleShowDeletePatient}
        />
        <ToggleMessagingModal
          open={showManagePreferences}
          onClose={this.handleShowManagePreferences}
        />
        <VideoModal
          dispatch={dispatch}
          groupId={visibleProfile.GroupId}
          therapist={visibleProfile.EmailAddress}
          patientContact={EmailAddress || PhoneNumber}
          patient={EmailAddress || PhoneNumber}
          ptFirstName={FirstName}
          ptLastName={LastName}
          role={Role}
          showVideo={showVideo}
          onHide={() => {
            this.setState({
              showVideo: false
            });
          }}
        />
        <PatientDetailDrawer
          openPatientDetail={openPatientDetail}
          handleOpenPatientDetail={this.handleOpenPatientDetail}
          selectedPatient={patientAnalytics}
          provider={visibleProfile.EmailAddress}
          history={history}
        />
        <div className="profile-info-container">
          {patient && (
            <Space direction="vertical">
              <div className="profile-fullname-container">
                <Typography.Title level={4}>
                  {v.capitalize(patient.FirstName)}{' '}
                  {v.capitalize(patient.LastName)}{' '}
                </Typography.Title>
                {GroupInfo.EnableRTM &&
                  typeof GroupInfo.EnableRTM === 'boolean' &&
                  !!GroupInfo.EnableRTM &&
                  patient.EnableRTM && (
                    <Fragment>
                      <Tag
                        className="profile-rtm-tag"
                        icon={<DesktopOutlined />}
                        color="cyan"
                        onClick={() => this.handleOpenPatientDetail()}
                        style={{ cursor: 'pointer' }}
                      >
                        RTM
                      </Tag>
                      <Popover
                        className="statistic-popover"
                        content={popoverContent(engagementStatus.description)}
                        trigger="hover"
                        placement="right"
                        arrowPointAtCenter
                      >
                        <Tag
                          className={`profile-rtm-tag ${
                            getCardColorIndicator(patientAnalytics.Status)
                              .className
                          }`}
                          style={{
                            color: '#fff',
                            borderRadius: 4,
                            borderColor: patientAnalytics.Status
                          }}
                        >
                          {engagementStatus.title}
                        </Tag>
                      </Popover>
                    </Fragment>
                  )}
                {this.hasExercises && discharge && (
                  <Tag
                    color="volcano"
                    onClick={() => {
                      this.setState({
                        showDischarge: true
                      });
                    }}
                  >
                    Discharged
                  </Tag>
                )}
              </div>
              <Typography.Text type="secondary">
                {this.contactDetails()}
              </Typography.Text>
            </Space>
          )}
          {history && !history.location.pathname.includes('/prescription') && (
            <Space direction="horizontal" size={20}>
              <Tooltip title="Message">
                <Button
                  onClick={() => {
                    this.setState({
                      showMessage: true
                    });
                  }}
                  shape="circle"
                  type="link"
                  className="btn-link"
                  icon={<MessageOutlined style={{ fontSize: 24 }} />}
                />
              </Tooltip>
              {(GroupInfo.EnableTelehealth === undefined ||
                GroupInfo.EnableTelehealth === true) && (
                <Tooltip title="Video Call">
                  <Button
                    onClick={() => this.setState({ showVideo: true })}
                    shape="circle"
                    type="link"
                    className="btn-link"
                    icon={<VideoCameraOutlined style={{ fontSize: 24 }} />}
                  />
                </Tooltip>
              )}
              <Dropdown
                overlay={patientDropdown}
                placement="bottomRight"
                trigger={['click']}
              >
                <Button
                  shape="circle"
                  type="link"
                  className="btn-link"
                  icon={<MoreOutlined style={{ fontSize: 24 }} />}
                />
              </Dropdown>
            </Space>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    socket: state.socket,
    isFetching: state.patients.isFetching,
    admins: state.users.admins,
    patient: state.patients.currentPatient,
    visibleProfile: state.visibleProfile,
    patientAnalytics: state.patients.patientAnalytics,
    lastPatientSubmissionType: state.patients.lastPatientSubmissionType
  };
};

export default connect(mapStateToProps)(PatientPageInfo);
