import React, { useEffect, useState } from 'react';
import { Row, Col, Space, Typography, Skeleton } from 'antd';
import V from 'voca';
import {
  MessageFilled,
  PhoneFilled,
  TrophyFilled,
  YoutubeFilled
} from '@ant-design/icons';

import PDFFooter from '../../../patients/Modals/PDFFooter';
import services from '../../../../services';
import TextTranslation from '../../../../I18n/TranslateText';
import ExerciseItem from './ExerciseItem';

const generatePages = (currLayout, exercises) => {
  const pages = [];
  let exPerPage = 4;

  if (currLayout === 'B') {
    exPerPage = 2;
  } else if (currLayout === 'C') {
    exPerPage = 1;
  }

  if (exercises) {
    const filtered = exercises.filter((e) => !e.deleted);

    const numberOfPages = Math.ceil(filtered.length / exPerPage);

    for (let i = 0; i < numberOfPages; i++) {
      const start = i * exPerPage;
      const end = i * exPerPage + exPerPage;
      const cut = filtered.slice(start, end);
      pages.push(cut);
    }
  }

  return pages;
};

const PrintPreview = ({
  printProps,
  printRef,
  currLocale = 'en',
  currLayout = 'A',
  raintreePDF = false
}) => {
  const {
    currentPatient,
    currentPatient: { FirstName, LastName, GroupId, Creator },
    visibleProfile,
    siteDesign,
    prescription
  } = printProps;

  const { exercises, perdiem, frequency, instructions } = prescription;
  const pages = generatePages(currLayout, exercises);

  const [fetching, setFetching] = useState(false);
  const [creatorEmail, setCreatorEmail] = useState('N/A');

  useEffect(() => {
    getCreatorEmail(Creator);
  }, []);

  const getCreatorEmail = async (sub) => {
    try {
      setFetching(true);

      let emailAddress;
      if (sub.includes('@')) {
        emailAddress = sub;
      } else {
        const response = await services.user.getDetails('sub', sub);
        const { data } = response;

        emailAddress = data.EmailAddress;
      }

      setCreatorEmail(emailAddress || 'N/A');
    } finally {
      setFetching(false);
    }
  };

  const truncateText = (text) => {
    const maxLimit = 28;

    if (text.length >= maxLimit) {
      return text.substring(0, maxLimit - 3).trim() + '...';
    } else {
      return text;
    }
  };

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <div id="divToPrint">
          <div className="first-page">
            <Row gutter={[16, 16]} className="header-container">
              <Col lg={12} md={12} sm={12} xs={12}>
                <Space direction="vertical" size={18}>
                  <img src={siteDesign.logoUrl} alt="logo" />
                  <Space direction="vertical" size={2}>
                    <Typography.Text>
                      <strong>
                        <TextTranslation
                          currLocale={currLocale}
                          currText={'Created for: '}
                        />
                      </strong>
                      {V.titleCase(FirstName)} {V.titleCase(LastName)}
                    </Typography.Text>

                    {visibleProfile && (
                      <Typography.Text>
                        <strong>
                          <TextTranslation
                            currLocale={currLocale}
                            currText={'Prepared by: '}
                          />
                        </strong>
                        <Typography.Text
                          style={{ textDecoration: 'capitalize' }}
                        >
                          {truncateText(
                            `${visibleProfile.FirstName} ${visibleProfile.LastName}`
                          )}
                        </Typography.Text>
                      </Typography.Text>
                    )}
                  </Space>
                </Space>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <PDFFooter
                  profile={currentPatient}
                  creatorEmail={creatorEmail}
                  currLocale={currLocale}
                />
              </Col>
            </Row>
            <Row
              gutter={[16, 16]}
              className="home-exercise-program-container"
              style={
                GroupId &&
                GroupId === 'b592cd97-5fb7-4d73-b4de-255af54d7df9' && {
                  marginTop: 40
                }
              }
            >
              <Col lg={24} md={24} sm={24} xs={24}>
                <Typography.Title level={2}>
                  <TextTranslation
                    currLocale={currLocale}
                    currText={'Home Exercise Program'}
                  />
                </Typography.Title>
                <Space direction="vertical" size={12}>
                  {exercises.length !== 0 && !exercises[0].frequency && (
                    <Space direction="vertical" size={2}>
                      <Typography.Text strong>
                        {' '}
                        <TextTranslation
                          currLocale={currLocale}
                          currText={'Frequency'}
                        />
                      </Typography.Text>
                      <Typography.Text>{`${frequency}x per ${perdiem}`}</Typography.Text>
                    </Space>
                  )}
                  <Space direction="vertical" size={2}>
                    <Typography.Text strong>
                      {' '}
                      <TextTranslation
                        currLocale={currLocale}
                        currText={'Instructions'}
                      />
                    </Typography.Text>
                    <Typography.Text>{instructions}</Typography.Text>
                  </Space>
                </Space>
              </Col>
            </Row>
            {GroupId && GroupId !== 'b592cd97-5fb7-4d73-b4de-255af54d7df9' && (
              <div>
                <Row gutter={[16, 16]} className="app-features-container">
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Typography.Title level={4}>
                      <TextTranslation
                        currLocale={currLocale}
                        currText={'You can also access on our mobile app!'}
                      />
                    </Typography.Title>
                    <Typography.Text>
                      <TextTranslation
                        currLocale={currLocale}
                        currText={'By using your app you can access...'}
                      />
                    </Typography.Text>
                    <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Space direction="vertical" size={16}>
                          <Space size={16} className="full-width">
                            <YoutubeFilled style={{ fontSize: 24 }} />
                            <Typography.Text strong>
                              <TextTranslation
                                currLocale={currLocale}
                                currText={'HD Exercise Videos'}
                              />
                            </Typography.Text>
                          </Space>
                          <Space size={16} className="full-width">
                            <TrophyFilled style={{ fontSize: 24 }} />
                            <Typography.Text strong>
                              <TextTranslation
                                currLocale={currLocale}
                                currText={'Earn Achievements'}
                              />
                            </Typography.Text>
                          </Space>
                        </Space>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Space direction="vertical" size={16}>
                          <Space size={16} className="full-width">
                            <MessageFilled style={{ fontSize: 24 }} />
                            <Typography.Text strong>
                              <TextTranslation
                                currLocale={currLocale}
                                currText={'Instant Messaging'}
                              />
                            </Typography.Text>
                          </Space>
                          <Space size={16} className="full-width">
                            <PhoneFilled style={{ fontSize: 24 }} />
                            <Typography.Text strong>
                              <TextTranslation
                                currLocale={currLocale}
                                currText={'Much More!'}
                              />
                            </Typography.Text>
                          </Space>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  gutter={[16, 16]}
                  className="access-instructions-container"
                >
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Space direction="vertical" size={2}>
                      <Typography.Title level={4}>
                        <TextTranslation
                          currLocale={currLocale}
                          currText={'How do I access?'}
                        />
                      </Typography.Title>
                      <Typography.Text>
                        <TextTranslation
                          currLocale={currLocale}
                          currText={`
                            1. Check your email for an email from admin@ptwired.com.
                            Open the email.
                            `}
                        />
                      </Typography.Text>
                      <Typography.Text>
                        <TextTranslation
                          currLocale={currLocale}
                          currText={`2. Select 'Activate My Account'.`}
                        />
                      </Typography.Text>
                      <Typography.Text>
                        <TextTranslation
                          currLocale={currLocale}
                          currText={'3. Set your password.'}
                        />
                      </Typography.Text>
                      <Typography.Text>
                        <TextTranslation
                          currLocale={currLocale}
                          currText={`
                            4. You'll be linked to our App Store page to download
                            our app and start accessing! You can also access via web
                            on your computer.
                          `}
                        />
                      </Typography.Text>
                    </Space>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Space direction="vertical" size={2}>
                      <Typography.Title level={4}>
                        <TextTranslation
                          currLocale={currLocale}
                          currText={`Having trouble accessing?`}
                        />
                      </Typography.Title>
                      <Typography.Text>
                        <TextTranslation
                          currLocale={currLocale}
                          currText={`Email us at admin@ptwired.com and we'll assist you.`}
                        />
                      </Typography.Text>
                    </Space>
                  </Col>
                </Row>
              </div>
            )}
          </div>

          <div
            style={{
              marginTop: raintreePDF ? 260 : 0
            }}
          >
            {pages.map((exercisePage, i) => {
              const colSpan = currLayout === 'A' ? 12 : 24;
              return (
                <React.Fragment key={i}>
                  <Row
                    gutter={[24, 24]}
                    className={`exercise-page ${currLayout}`}
                    id={`p-${i - 1}`}
                  >
                    {exercisePage.map((exercise, j) => (
                      <Col
                        lg={colSpan}
                        md={colSpan}
                        sm={colSpan}
                        xs={colSpan}
                        key={`${i - 1}-${j}`}
                      >
                        <ExerciseItem
                          currLocale={currLocale}
                          exercise={exercise}
                          raintreePDF={raintreePDF}
                        />
                      </Col>
                    ))}
                  </Row>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      );
    }
  }

  return fetching ? <Skeleton active /> : <ComponentToPrint ref={printRef} />;
};

export default PrintPreview;
