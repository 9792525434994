import React, { Fragment, useEffect, useState } from 'react';
import { Card, Space, Button, Form, DatePicker, Select, Row, Col } from 'antd';

import BarChart from '../../../components/BarChart';
import moment from 'moment';
import services from '../../../services';
import * as reportsUtils from '../../../utils/reports.utils';

const codes = [98980, 98981, 98977, 98975];

const RtmBilled = ({
  title,
  showFilter,
  onSetLoading,
  onSetError,
  onSetDateRange,
  onSetReport
}) => {
  const [filter, setFilter] = useState({
    dateRange: null,
    rtmCodes: []
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [chartTitle, setChartTitle] = useState('');
  const [report, setReport] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    initChart();
  }, [filter]);

  const initChart = async () => {
    try {
      if (error) {
        onSetError(false);
        setError(false);
      }

      onSetLoading(true);
      setLoading(true);

      let startDate;
      let endDate;
      let label = 'Last 30 days';

      if (filter.dateRange !== null) {
        startDate = moment(filter.dateRange[0]).format('MMM DD, YYYY');
        endDate = moment(filter.dateRange[1]).format('MMM DD, YYYY');
        label = `${startDate} - ${endDate}`;
      }

      const reportResponse = await fetchReports();
      const generatedReport = reportsUtils.generateRtmBilled(
        label,
        reportResponse
      );
      onSetReport(generatedReport);
      setReport(generatedReport);

      setChartTitle(
        `${title} ${!filter.dateRange ? '(Last 30 days)' : ''} Report ${
          filter.dateRange !== null ? `for ${startDate} - ${endDate}` : ''
        }`
      );
    } catch (error) {
      console.log('initChart', error);

      onSetError(true);
      setError(true);
    } finally {
      onSetLoading(false);
      setLoading(false);
    }
  };

  const fetchReports = async () => {
    try {
      let query = reportsUtils.generateDateQuery();

      if (filter.dateRange) {
        query = reportsUtils.generateDateQuery(
          moment(filter.dateRange[0]).format() || null,
          moment(filter.dateRange[1]).format() || null
        );
      }

      const response = await services.reports.getRtmBilled(
        query,
        filter.rtmCodes.length > 0 ? filter.rtmCodes : null
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log('fetchReports', error);
      throw error;
    }
  };

  const handleFilter = values => {
    onSetDateRange(
      values.dateRange
        ? [values.dateRange[0].format(), values.dateRange[1].format()]
        : null
    );

    setFilter({
      dateRange: values.dateRange
        ? [values.dateRange[0].format(), values.dateRange[1].format()]
        : null,
      rtmCodes: Array.isArray(values.rtmCodes) ? [...values.rtmCodes] : []
    });
  };

  const handleClear = () => {
    form.resetFields();

    onSetDateRange(null);
    setFilter({
      dateRange: null,
      rtmCodes: []
    });
  };

  return (
    <Fragment>
      {showFilter && (
        <Card style={{ background: '#f7f7f7', marginBottom: 20 }}>
          <Form
            className="filter-chart-form"
            form={form}
            onFinish={handleFilter}
          >
            <Row gutter={[16, 16]} style={{ flex: 1 }}>
              <Col span={12}>
                <Form.Item
                  label="Date Range"
                  name="dateRange"
                  rules={[
                    {
                      required: true,
                      message: 'Please select date range'
                    }
                  ]}
                >
                  <DatePicker.RangePicker
                    disabledDate={current =>
                      (current && current < moment().subtract(1, 'year')) ||
                      current > moment().endOf('day')
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="RTM Code/s" name="rtmCodes">
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="Select RTM code/s"
                    filterOption={(input, option) => {
                      if (option && option.label) {
                        const label = String(option.label).toLowerCase();
                        if (label.includes(String(input).toLowerCase())) {
                          return option;
                        }
                      }
                    }}
                    options={codes.map(code => ({
                      label: code.toString(),
                      value: code
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space>
                <Button htmlType="submit" type="primary">
                  Apply Filter
                </Button>
                <Button htmlType="button" type="default" onClick={handleClear}>
                  Clear
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      )}
      <BarChart loading={loading} chartTitle={chartTitle} data={report} />
    </Fragment>
  );
};

export default RtmBilled;
