import React, { useRef } from 'react';
import { Modal, Button, Typography, Input } from 'antd';
import setNotification from '../../../utils/setNotification.utils';

const ExportHEPGeneratedIdModal = props => {
  const { togglExportHEPGeneratedIdModal, generatedId } = props;
  const textAreaRef = useRef();

  const copyToClipboard = e => {
    navigator.clipboard.writeText(generatedId);

    setNotification('success', 'Success!', 'Copied to clipboard.');
  };

  return (
    <Modal
      title={
        <Typography.Title level={4} style={{ marginBottom: -8 }}>
          Export HEP Generated ID
        </Typography.Title>
      }
      visible={true}
      maskClosable={false}
      onCancel={togglExportHEPGeneratedIdModal}
      footer={[
        <Button
          key="export"
          size="large"
          type="primary"
          className="ptw-btn btn-primary"
          onClick={copyToClipboard}
        >
          Copy to Clipboard
        </Button>,
      ]}
    >
      <Input.TextArea ref={textAreaRef} readOnly value={generatedId} />
    </Modal>
  );
};

export default ExportHEPGeneratedIdModal;
