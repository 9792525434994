import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Switch, Typography } from 'antd';
import isEmail from 'validator/lib/isEmail';

const FormInput = props => {
  const {
    tooltip,
    feedbackIcon,
    input,
    inputType,
    label,
    placeholder,
    type,
    componentClass,
    hideErrorMessage,
    isRequired,
    defaultChecked,
    checkedChildren,
    unCheckedChildren,
    meta: { error, warning, validating, touched, asyncValidating },
    children,
    onSearch = null,
    ...rest
  } = props;

  const [showHelperText, setShowHelperText] = useState(false);

  const validationState =
    (touched && error && 'error') ||
    (warning && 'warning') ||
    (validating && 'validating') ||
    null;

  let message;
  if (!hideErrorMessage && touched && (error || warning || validating)) {
    message = error || warning || validating;
  }

  const formatInput = (type, value) => {
    if (type === 'tel') {
      const number = value.replace(/\D/g, '');
      const output = `(${number.substring(0, 3)}) ${number.substring(
        3,
        6
      )} - ${number.substring(6, 10)}`;

      const regExp =
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

      if (!error || !warning || !validating) {
        if (output.match(regExp)) {
          setShowHelperText(true);
        } else {
          setShowHelperText(false);
        }
      }

      input.onChange(output);
    } else {
      const trimmed = value.trim();

      if (isEmail(trimmed)) {
        input.onChange(trimmed);
      } else {
        input.onChange(value);
      }
    }
  };

  const handleInputChange = e => {
    const value = e.target.value;

    if (value.length > 2 && inputType === 'tel') {
      formatInput('tel', value);
    } else {
      formatInput('text', value);
    }
  };

  const handleInputPaste = e => {
    e.preventDefault();
    const value = e.clipboardData.getData('Text');

    if (!/\s/g.test(value) && /^\d+$/.test(value)) {
      formatInput('tel', value);
    } else {
      formatInput('text', value);
    }
  };

  return (
    <React.Fragment>
      <Form.Item
        tooltip={tooltip}
        label={label}
        validateStatus={validationState}
        help={
          asyncValidating && (
            <Typography.Text type="secondary">
              Validating {type}...
            </Typography.Text>
          )
        }
      >
        {type === 'switch' ? (
          <Switch
            defaultChecked={defaultChecked}
            checkedChildren={checkedChildren}
            unCheckedChildren={unCheckedChildren}
            checked={input.checked}
            onChange={input.onChange}
          />
        ) : type === 'select' ? (
          <Select
            showSearch
            size="large"
            placeholder={placeholder}
            onSearch={val =>
              type === 'select' && onSearch ? onSearch(val) : null
            }
            filterOption={onSearch && false}
            {...input}
            onBlur={() => {}}
          >
            {children}
          </Select>
        ) : type === 'textarea' ? (
          <Input.TextArea
            {...input}
            componentClass={componentClass}
            placeholder={placeholder}
            size="large"
            {...rest}
          >
            {children}
          </Input.TextArea>
        ) : (
          <Input
            {...input}
            onChange={handleInputChange}
            onPaste={handleInputPaste}
            type={type}
            componentClass={componentClass}
            placeholder={placeholder}
            size="large"
            {...rest}
          >
            {children}
          </Input>
        )}
        {message && <Typography.Text type="danger">{message}</Typography.Text>}
        {showHelperText && !asyncValidating && !message && (
          <Typography.Text
            style={{ fontSize: 12, marginTop: -36, marginBottom: 24 }}
          >
            By submitting this form, you are verifying that this patient agrees
            to receive automated messages at this cell number. The patient can
            reply STOP to terminate. Message and data rates may apply. View{' '}
            <Typography.Link
              href="https://www.ptwired.com/ptwtermsofservice"
              target="_blank"
            >
              Terms
            </Typography.Link>{' '}
            &{' '}
            <Typography.Link
              href="https://www.ptwired.com/privacy-policy"
              target="_blank"
            >
              Privacy
            </Typography.Link>
            .
          </Typography.Text>
        )}
      </Form.Item>
    </React.Fragment>
  );
};

export default FormInput;
