import React, { useEffect, useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRecoil } from 'recoil-nexus';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
  Upload,
  message,
  notification
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import isEmail from 'validator/lib/isEmail';
import config from '../../config';
import services from '../../services';
import states from '../../states';
import ProfileImage from '../patients/Modals/ProfileImage';

const { Option } = Select;

const PT = 'pt';
const GA = 'ga';

const getBillingRole = (role) => {
  if (!role) return '';

  switch (role.toLowerCase()) {
    case 'pt':
      return 'Provider';

    case 'pta':
      return 'Provider Assistant';

    case 'tech':
      return 'Technician';

    default:
      return role;
  }
};

function UserForm(props) {
  const { currentUser, onSubmit, uploadImage, submitting } = props;

  const [cropping, setCropping] = useState(false);
  const [file, setFile] = useState(null);
  const [imgUrl, setImgUrl] = useState('');

  const formRef = useRef(null);
  const groups = getRecoil(states.groups);

  const [messaging, setMessaging] = useState('enabled');
  const [location, setLocation] = useState(null);
  const [rtmStatus, setRtmStatus] = useState('disabled');
  const [notifyBillableCodes, setNotifyBillableCodes] = useState('enabled');
  const [promptLocationPopup, setPromptLocationPopup] = useState('disabled');
  const [painScaleThreshold, setPainScaleThreshold] = useState(null);
  const [painScaleNotification, setPainScaleNotification] = useState('enabled');

  /**
   * Create Default Image
   * */
  useEffect(() => {
    const { GroupId, Sub, Preferences } = currentUser;

    if (GroupId || Sub) {
      setImgUrl(
        `https://ptwired-exercise-images-prod.s3.amazonaws.com/${GroupId}/${Sub}.jpg`
      );
    }

    if (Preferences) {
      setMessaging(Preferences.Messaging || 'enabled');
      setLocation(Preferences.Location || null);
      setRtmStatus(Preferences.RTMStatus || 'disabled');
      setNotifyBillableCodes(Preferences.NotifyBillableCodes || 'enabled');
      setPromptLocationPopup(Preferences.PromptLocationPopup || 'disabled');
      setPainScaleThreshold(Preferences.PainScaleThreshold || null);
      setPainScaleNotification(Preferences.PainScaleNotification || 'enabled');
    }
  }, []);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  }

  const [imageLoading, setImageLoading] = useState(false);

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setImageLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file, (imageUrl) => {
        setImgUrl(imageUrl);
        setImageLoading(false);
      });
    }
  };

  const handleImage = (params) => {
    setFile(params.file);
    setCropping(true);
  };

  const updateImage = async (blob) => {
    try {
      const { GroupId, Sub } = currentUser;
      const imageSrc = `https://ptwired-exercise-images-prod.s3.amazonaws.com/${GroupId}/${Sub}.jpg`;

      await uploadImage(blob).then(() => {
        setImgUrl(imageSrc);

        notification.success({
          message: 'Success',
          description: 'Image uploaded successfully!'
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was an error uploading the image. Please try again.'
      });
    } finally {
      setImageLoading(false);
    }
  };

  const isActive = (value) => {
    if (value === true || value === 'true') {
      return true;
    } else if (value === false || value === 'false') {
      return false;
    } else return true;
  };

  const checkAdmin = (value) => {
    if (value === GA) {
      return true;
    } else if (value === PT) {
      return false;
    } else return false;
  };

  const {
    initialValues: {
      Active,
      BillingRole,
      EmailAddress,
      FirstName,
      LastName,
      Role,
      PartTime
    }
  } = props;

  const [firstName, setFirstName] = useState(FirstName);
  const [lastName, setLastName] = useState(LastName);
  const [emailAddress, setEmailAddress] = useState(EmailAddress);
  const [billingRole, setBillingRole] = useState(BillingRole);
  const [role, setRole] = useState(Role ? Role : PT);
  const [active, setActive] = useState(Active ? isActive(Active) : true);
  const [partTime, setPartTime] = useState(PartTime || false);
  const [emailError, setEmailError] = useState(false);
  const [uniqueEmail, setUniqueEmail] = useState(true);
  const [validating, setValidating] = useState(false);

  const incomplete = !firstName || !lastName || !emailAddress || !billingRole;
  const notEdited =
    FirstName === firstName &&
    LastName === lastName &&
    EmailAddress === emailAddress &&
    BillingRole === billingRole &&
    Role === role &&
    isActive(Active) === active &&
    PartTime === partTime;

  const preferences = currentUser.Preferences;
  const notEditedPreferences =
    (preferences?.Messaging || 'enabled') === messaging &&
    (preferences?.Location || null) === location &&
    (preferences?.RTMStatus || 'disabled') === rtmStatus &&
    (preferences?.NotifyBillableCodes || 'enabled') === notifyBillableCodes &&
    (preferences?.PromptLocationPopup || 'disabled') === promptLocationPopup &&
    (preferences?.PainScaleThreshold || null) === painScaleThreshold &&
    (preferences?.PainScaleNotification || 'enabled') === painScaleNotification;

  const updateRole = (value) => {
    /**
     * Flip role
     */
    if (value === 'pt') {
      setRole('ga');
    } else {
      setRole('pt');
    }
  };

  const submitEmail = (e) => {
    const value = e.target.value.trim().toLowerCase();

    setEmailAddress(value);
    setEmailError(!isEmail(value));
  };

  const validateEmail = async (e) => {
    const value = e.target.value.trim().toLowerCase();
    const input = encodeURIComponent(value);
    const url = `${config.root}/users/external?input=${input}`;

    if (!value || emailError) {
      setUniqueEmail(true);
      return;
    }

    try {
      setValidating(true);

      const response = await fetch(url);
      const data = await response.json();

      setUniqueEmail(data.sub === value);
    } catch (error) {
      setUniqueEmail(false);
    } finally {
      setValidating(false);
    }
  };

  const submit = async () => {
    const { initialValues } = props;
    const { GroupId } = props.visibleProfile;

    const form = {
      Sub: initialValues.Sub,
      FirstName: firstName,
      LastName: lastName,
      EmailAddress: emailAddress,
      BillingRole: billingRole,
      Role: role,
      Active: active,
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      billingRole: billingRole,
      role: role,
      active: active,
      groupId: GroupId,
      GroupId: GroupId,
      PartTime: partTime,
      isEmailChanged: initialValues.EmailAddress !== emailAddress
    };

    await onSubmit(form).then(async () => {
      await services.preferences.updatePreferences({
        sub: currentUser.Sub,
        preferences: {
          Messaging: messaging,
          Location: location,
          RTMStatus: rtmStatus,
          NotifyBillableCodes: notifyBillableCodes,
          PromptLocationPopup: promptLocationPopup,
          PainScaleThreshold: painScaleThreshold,
          PainScaleNotification: painScaleNotification
        }
      });
    });
  };

  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}

      <div className="ant-upload-text">Upload Profile Image</div>
    </div>
  );

  return (
    <div
      style={{
        margin: '0 16em'
      }}
    >
      <Form name="user-form" layout="vertical">
        {cropping && (
          <ProfileImage
            show={cropping}
            image={file}
            onHide={() => {
              setCropping(false);
            }}
            onSubmit={(val) => {
              updateImage(val);
            }}
            closeButton={() => {
              setCropping(false);
            }}
            onExit={() => {
              setCropping(false);
            }}
          />
        )}

        {currentUser && currentUser.EmailAddress && (
          <Form.Item
            name="imageSelector"
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              customRequest={(params) => {
                handleImage(params);
              }}
            >
              {imgUrl ? (
                <img
                  src={imgUrl}
                  alt="avatar"
                  style={{
                    width: '100%'
                  }}
                  onError={() => {
                    setImgUrl(null);
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        )}

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="firstName" label="First Name">
              <Input
                placeholder="First Name"
                defaultValue={FirstName}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value.trim());
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="lastName" label="Last Name">
              <Input
                placeholder="Last Name"
                defaultValue={LastName}
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value.trim());
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="emailAddress"
              label="Email Address"
              validateStatus={
                emailError || !uniqueEmail
                  ? 'error'
                  : emailAddress
                  ? 'success'
                  : null
              }
              help={!uniqueEmail ? 'Email must be unique' : null}
            >
              <Input
                placeholder="Email Address"
                defaultValue={EmailAddress}
                value={emailAddress}
                onChange={submitEmail}
                onBlur={validateEmail}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="billingRole" label="Billing Role">
              <Select
                placeholder="Billing Role"
                value={getBillingRole(billingRole)}
                defaultValue={getBillingRole(billingRole)}
                onChange={(value) => {
                  setBillingRole(value);
                }}
              >
                <Option value={'PT'}>Provider</Option>
                <Option value={'PTA'}>Provider Assistant</Option>
                <Option value={'TECH'}>Technician</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={40}>
          <Col>
            <Form.Item name="parttime">
              <Space>
                <Switch
                  checked={partTime}
                  onChange={(val) => {
                    setPartTime(val);
                  }}
                />

                <Typography.Text>Part-Time</Typography.Text>
              </Space>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item name="administrator" valuePropName="checked">
              <Space>
                <Switch
                  defaultChecked={checkAdmin(role)}
                  checked={checkAdmin(role)}
                  onClick={() => {
                    updateRole(role);
                  }}
                />

                <Typography.Text>Administrator</Typography.Text>
              </Space>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item name="active" valuePropName="checked">
              <Space>
                <Switch
                  defaultChecked={isActive(active)}
                  checked={isActive(active)}
                  onClick={() => {
                    setActive(!active);
                  }}
                />

                <Typography.Text>Active</Typography.Text>
              </Space>
            </Form.Item>
          </Col>
        </Row>

        <Divider
          plain
          orientation="left"
          orientationMargin={0}
          style={{
            marginTop: 8
          }}
        >
          Preferences
        </Divider>

        <Row
          gutter={24}
          style={{
            marginBottom: 60
          }}
        >
          <Col span={12}>
            <Space size="middle" direction="vertical">
              <Space>
                <Switch
                  checked={messaging === 'enabled'}
                  onChange={(checked) => {
                    setMessaging(checked ? 'enabled' : 'disabled');
                  }}
                />

                <Typography.Text>Enable Messaging</Typography.Text>
              </Space>

              <Space>
                <Switch
                  checked={rtmStatus === 'enabled'}
                  onChange={(checked) => {
                    setRtmStatus(checked ? 'enabled' : 'disabled');
                  }}
                />

                <Typography.Text>Default RTM Status</Typography.Text>
              </Space>

              <Space>
                <Switch
                  checked={notifyBillableCodes === 'enabled'}
                  onChange={(checked) => {
                    setNotifyBillableCodes(checked ? 'enabled' : 'disabled');
                  }}
                />

                <Typography.Text>
                  Enable Billable Codes Notification
                </Typography.Text>
              </Space>

              <Space>
                <Switch
                  checked={promptLocationPopup === 'enabled'}
                  onChange={(checked) => {
                    setPromptLocationPopup(checked ? 'enabled' : 'disabled');
                  }}
                />

                <Typography.Text>Display Location & RTM Pop-Up</Typography.Text>
              </Space>
            </Space>
          </Col>

          <Col span={12}>
            <Space
              direction="vertical"
              size="middle"
              style={{
                width: '100%'
              }}
            >
              <Space
                size={0}
                direction="vertical"
                style={{
                  width: '100%'
                }}
              >
                <Typography.Text>Default Location</Typography.Text>
                <Select
                  showSearch
                  style={{
                    width: '100%'
                  }}
                  value={location || ''}
                  onChange={(value) => {
                    setLocation(value);
                  }}
                >
                  {[...groups.clinics]
                    .sort((a, b) => a.ClinicName - b.ClinicName)
                    .map((item, i) => (
                      <Fragment key={i}>
                        <Select.Option value={item.Id}>
                          {item.ClinicName}
                        </Select.Option>
                      </Fragment>
                    ))}
                </Select>
              </Space>

              <Space size={2} direction="vertical">
                <Typography.Text>Daily Outcome Score Threshold</Typography.Text>

                <Space size="large">
                  <Space>
                    <input
                      type="number"
                      min="1"
                      max="5"
                      style={{
                        width: 42,
                        borderRadius: 4,
                        border: '1px solid #d9d9d9'
                      }}
                      value={painScaleThreshold || ''}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (value > 5) {
                          value = 5;
                        } else if (value <= 0) {
                          value = '';
                        }

                        setPainScaleThreshold(value || null);
                      }}
                    />

                    <Typography.Text>Threshold</Typography.Text>
                  </Space>

                  <Space>
                    <Switch
                      checked={painScaleNotification === 'enabled'}
                      onChange={(checked) => {
                        setPainScaleNotification(
                          checked ? 'enabled' : 'disabled'
                        );
                      }}
                    />

                    <Typography.Text>Notification</Typography.Text>
                  </Space>
                </Space>
              </Space>
            </Space>
          </Col>
        </Row>

        <Space>
          <Form.Item name="submit">
            {!active ? (
              <Popconfirm
                title={
                  <Typography.Text>
                    <strong>Deactivate Account?</strong>
                    <br />
                    Deactivating this provider will archive all conversations
                    this provider has had with any patients.
                    <br />
                    This cannot be undone. Do you want to continue?
                  </Typography.Text>
                }
                placement="topRight"
                okText="Deactivate Account"
                cancelText="Cancel"
                onConfirm={() => {
                  submit();
                }}
                okButtonProps={{
                  danger: true
                }}
              >
                <Button
                  type="primary"
                  disabled={
                    incomplete ||
                    validating ||
                    submitting ||
                    emailError ||
                    !uniqueEmail ||
                    (notEdited && notEditedPreferences)
                  }
                >
                  {!submitting ? 'Save Changes' : <Spin />}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                type="primary"
                disabled={
                  incomplete ||
                  validating ||
                  submitting ||
                  emailError ||
                  !uniqueEmail ||
                  (notEdited && notEditedPreferences)
                }
                onClick={() => {
                  submit();
                }}
              >
                {!submitting ? 'Save Changes' : <Spin />}
              </Button>
            )}
          </Form.Item>

          <Form.Item name="cancel">
            <Link to="/users">
              <Button type="default">Cancel</Button>
            </Link>
          </Form.Item>
        </Space>
      </Form>

      <div
        ref={formRef}
        style={{
          display: 'none'
        }}
      />
    </div>
  );
}
function mapStateToProps(state) {
  return {
    currentUser: state.users.currentUser,
    visibleProfile: state.visibleProfile
  };
}

export default connect(mapStateToProps)(UserForm);
