import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Row, Col, Button, FormGroup } from 'react-bootstrap';
import { Form, Select } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import MaskedInput from 'react-maskedinput';
import FormInput from './Input';
import USStates from './USStates';
import { required } from './validators/generic';
import {
  contactEmailValidator,
  groupNameValidator,
  addressValidator,
  cityValidator,
  contactNameValidator,
  zipValidator,
  phoneValidator,
  asyncValidate
} from './validators/groups';
import { zipCodeNormalizer } from './inputNormalizers';

class GroupForm extends Component {
  constructor(props) {
    super(props);

    const { initialValues } = this.props;
    this.state = {
      isPracticePromotions: this.strToBool(initialValues.IsPracticePromotions)
    };
    this.checkPP = this.checkPP.bind(this);
    this.uncheckPP = this.uncheckPP.bind(this);
  }

  boolToString(value, key = '') {
    if (!value) {
      let boolStr = 'false';
      if (value === undefined) {
        switch (key) {
          case 'EnableAppointments':
          case 'EnableTelehealth':
            boolStr = 'true';
            break;

          default:
            break;
        }
      }

      return boolStr;
    }

    return value.toString();
  }

  strToBool(val) {
    if (typeof val === 'string') {
      if (val === 'true') {
        return true;
      } else {
        return false;
      }
    }

    return val;
  }

  uncheckPP() {
    this.setState({ isPracticePromotions: false });
  }

  checkPP() {
    this.setState({ isPracticePromotions: true });
  }

  render() {
    const { handleSubmit, submitting, initialValues, group } = this.props;
    const { isPracticePromotions } = this.state;

    initialValues.active = this.boolToString(initialValues.active);

    initialValues.IsPracticePromotions = this.boolToString(
      initialValues.IsPracticePromotions
    );

    initialValues.EnableRTM = this.boolToString(initialValues.EnableRTM);
    initialValues.EnableRTMConsent = this.boolToString(
      initialValues.EnableRTMConsent
    );
    initialValues.EnablePTU = this.boolToString(initialValues.EnablePTU);
    initialValues.EnablePrompt = this.boolToString(initialValues.EnablePrompt);
    initialValues.EnableRaintree = this.boolToString(
      initialValues.EnableRaintree
    );
    initialValues.EnableMultipleRx = this.boolToString(
      initialValues.EnableMultipleRx
    );
    initialValues.EnablePhoneNumber = this.boolToString(
      initialValues.EnablePhoneNumber
    );
    initialValues.EnableAppointments = this.boolToString(
      initialValues.EnableAppointments,
      'EnableAppointments'
    );
    initialValues.EnableTelehealth = this.boolToString(
      initialValues.EnableTelehealth,
      'EnableTelehealth'
    );

    let urlLength = 0;
    if (initialValues && Array.isArray(initialValues.Integrations)) {
      urlLength = initialValues.Integrations.length;
    }

    return (
      <div>
        <Form layout="vertical">
          <Row>
            <Col md={8}>
              <Field
                name="name"
                label="Group Name"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, groupNameValidator]}
              />
            </Col>
            <Col md={4}>
              <Field
                name="groupPhone"
                label="Group Phone"
                isRequired
                component={FormInput}
                componentClass={MaskedInput}
                validate={[required, phoneValidator]}
                mask="111-111-1111"
                placeholder="123-456-7890"
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Field
                name="address"
                label="Address"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, addressValidator]}
              />
            </Col>
            <Col md={3}>
              <Field
                name="city"
                label="City"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, cityValidator]}
              />
            </Col>
            <Col md={3}>
              <Field
                name="state"
                label="State"
                isRequired
                component={FormInput}
                type="select"
              >
                {Object.keys(USStates).map((abbrev) => (
                  <Select.Option value={abbrev} key={abbrev}>
                    {USStates[abbrev]}
                  </Select.Option>
                ))}
              </Field>
            </Col>
            <Col md={2}>
              <Field
                name="zip"
                label="Zip"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, zipValidator]}
                normalize={zipCodeNormalizer}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Field
                name="contactName"
                label="Contact Name"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, contactNameValidator]}
              />
            </Col>
            <Col md={6}>
              <Field
                name="contactPhone"
                label="Contact Phone"
                isRequired
                component={FormInput}
                componentClass={MaskedInput}
                validate={[required, phoneValidator]}
                mask="111-111-1111"
                placeholder="123-456-7890"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Field
                name="contactEmail"
                label="Group Email"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, contactEmailValidator]}
                placeholder="email@domain.com"
              />
            </Col>
            <Col md={4}>
              <Field
                name="contactEmail"
                label="Contact Email"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, contactEmailValidator]}
                placeholder="email@domain.com"
              />
            </Col>
            <Col md={4}>
              <Field
                name="GroupEmailDisplay"
                label="Display Name for Emails"
                component={FormInput}
                type="text"
                placeholder="Example PT"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name="iOSUrl"
                label="iOS Appstore Url"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
            <Col md={6}>
              <Field
                name="AndroidUrl"
                label="Play Store Url"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name="FacebookUrl"
                label="Facebook Review Page"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
            <Col md={6}>
              <Field
                name="GoogleUrl"
                label="Google Review Page"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name={
                  urlLength > 0 && urlLength === 1
                    ? initialValues.Integrations[0].CompanyName &&
                      initialValues.Integrations[0].CompanyName.includes(
                        'CardConnect'
                      )
                      ? 'Integrations[1].Url'
                      : 'Integrations[0].Url'
                    : 'Integrations[0].Url'
                }
                label="Appointments URL (BetterPT)"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
            <Col md={6}>
              <Field
                name={
                  urlLength > 0 && urlLength === 1
                    ? initialValues.Integrations[0].CompanyName &&
                      initialValues.Integrations[0].CompanyName.includes(
                        'CardConnect'
                      )
                      ? 'Integrations[0].Url'
                      : 'Integrations[1].Url'
                    : 'Integrations[1].Url'
                }
                label="Bill Pay URL (GoCardConnect)"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
          </Row>

          <Row>
            <Col md={isPracticePromotions ? 4 : 6}>
              <Field
                name={'PrintEmail'}
                label="PDF Printout Email"
                component={FormInput}
                type="select"
                placeholder="Select email to display on PDF printout"
                validate={[required]}
              >
                <Select.Option value={'provider'}>Provider</Select.Option>
                <Select.Option value={'clinic'}>Clinic</Select.Option>
              </Field>
            </Col>

            <Col md={isPracticePromotions ? 4 : 6}>
              <Field
                name="ExpoProjectName"
                label="Expo Project Name"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>

            {isPracticePromotions && (
              <Col md={4}>
                <Field
                  name="BlogUrl"
                  label="PP Blog Url"
                  component={FormInput}
                  type="text"
                  placeholder=""
                />
              </Col>
            )}
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    name="active"
                    component="input"
                    type="radio"
                    value={'true'}
                  />
                  Active
                </label>
                <label className="radio-inline">
                  <Field
                    name="active"
                    component="input"
                    type="radio"
                    value={'false'}
                  />
                  Inactive
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    onClick={this.uncheckPP}
                    name="IsPracticePromotions"
                    component="input"
                    type="radio"
                    value={'false'}
                  />{' '}
                  Standard Group
                </label>
                <label className="radio-inline">
                  <Field
                    onClick={this.checkPP}
                    name="IsPracticePromotions"
                    component="input"
                    type="radio"
                    value={'true'}
                  />{' '}
                  Practice Promotions Group
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    name="EnableRTM"
                    component="input"
                    type="radio"
                    value={'true'}
                  />
                  Enable RTM
                </label>
                <label className="radio-inline">
                  <Field
                    name="EnableRTM"
                    component="input"
                    type="radio"
                    value={'false'}
                  />
                  Disable RTM
                </label>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    disabled={group?.values?.EnableRTM === 'false'}
                    name="EnableRTMConsent"
                    component="input"
                    type="radio"
                    value={'true'}
                  />
                  Enable RTM Consent
                </label>
                <label className="radio-inline">
                  <Field
                    disabled={group?.values?.EnableRTM === 'false'}
                    name="EnableRTMConsent"
                    component="input"
                    type="radio"
                    value={'false'}
                  />
                  Disable RTM Consent
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    name="EnablePTU"
                    component="input"
                    type="radio"
                    value={'true'}
                  />
                  Enable Care Plan
                </label>
                <label className="radio-inline">
                  <Field
                    name="EnablePTU"
                    component="input"
                    type="radio"
                    value={'false'}
                  />
                  Disable Care Plan
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    name="EnablePrompt"
                    component="input"
                    type="radio"
                    value={'true'}
                  />
                  Enable Prompt
                </label>
                <label className="radio-inline">
                  <Field
                    name="EnablePrompt"
                    component="input"
                    type="radio"
                    value={'false'}
                  />
                  Disable Prompt
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    name="EnableRaintree"
                    component="input"
                    type="radio"
                    value={'true'}
                  />
                  Enable Raintree
                </label>
                <label className="radio-inline">
                  <Field
                    name="EnableRaintree"
                    component="input"
                    type="radio"
                    value={'false'}
                  />
                  Disable Raintree
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    name="EnableMultipleRx"
                    component="input"
                    type="radio"
                    value={'true'}
                  />
                  Enable Multiple Prescriptions
                </label>
                <label className="radio-inline">
                  <Field
                    name="EnableMultipleRx"
                    component="input"
                    type="radio"
                    value={'false'}
                  />
                  Disable Multiple Prescriptions
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    name="EnablePhoneNumber"
                    component="input"
                    type="radio"
                    value={'true'}
                  />
                  Enable Phone Number
                </label>
                <label className="radio-inline">
                  <Field
                    name="EnablePhoneNumber"
                    component="input"
                    type="radio"
                    value={'false'}
                  />
                  Disable Phone Number
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    name="EnableAppointments"
                    component="input"
                    type="radio"
                    value={'true'}
                  />
                  Enable Appointments
                </label>
                <label className="radio-inline">
                  <Field
                    name="EnableAppointments"
                    component="input"
                    type="radio"
                    value={'false'}
                  />
                  Disable Appointments
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="radio-inline">
                  <Field
                    component="input"
                    type="radio"
                    name="EnableTelehealth"
                    value={'true'}
                  />
                  Enable Telehealth
                </label>
                <label className="radio-inline">
                  <Field
                    component="input"
                    type="radio"
                    name="EnableTelehealth"
                    value={'false'}
                  />
                  Disable Telehealth
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup className="ptw-form-actions">
                <div className="btn-toolbar pull-right">
                  <Link to="/groups">
                    <Button disabled={submitting} bsStyle="link">
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    bsStyle="primary"
                    disabled={false}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    group: state.form.group
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'group',
    asyncValidate,
    asyncBlurFields: ['name']
  })(withRouter(GroupForm))
);
