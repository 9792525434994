import React from 'react';
import { getEmbeddedUrl } from '../videoUrlHelpers';
import { Modal, Typography, Button, Row, Col, Space } from 'antd';
import { titleCase } from '../../groups/stringHelpers';
import { MinusCircleOutlined, StarOutlined } from '@ant-design/icons';
import NoResults from '../../../components/NoResults';

export default function ExerciseDetailModal({
  currentExercise,
  currentExercise: {
    star,
    video,
    exerciseName,
    sets,
    reps,
    hold,
    instructions
  },
  closeModal,
  favorites,
  addFavorite,
  deleteFavorite
}) {
  const embeddedUrl = currentExercise ? getEmbeddedUrl(video) : undefined;

  const handleAddToFavorites = () => {
    addFavorite(currentExercise);
  };

  return (
    <Modal
      visible
      onCancel={closeModal}
      footer={null}
      className="preview-exercise-modal"
      width={700}
    >
      {embeddedUrl ? (
        <div className="preview-iframe-wrapper">
          <iframe
            title="Exercise Video"
            src={embeddedUrl}
            width="100%"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      ) : (
        <NoResults content="No video embedded on this exercise." />
      )}
      <div className="preview-modal-body">
        <div className="tab-header">
          <Typography.Title level={4}>
            {exerciseName && titleCase(exerciseName)}{' '}
          </Typography.Title>
          {favorites !== null && (
            <div style={{ width: 220 }}>
              <div>
                {star ? (
                  <Button
                    style={{ float: 'right' }}
                    type="default"
                    className="ptw-btn"
                    size="large"
                    onClick={() => {
                      deleteFavorite(currentExercise);
                    }}
                    icon={<MinusCircleOutlined />}
                  >
                    Remove from Favorites
                  </Button>
                ) : (
                  <Button
                    style={{ float: 'right' }}
                    type="primary"
                    className="ptw-btn btn-primary"
                    size="large"
                    onClick={handleAddToFavorites}
                    icon={<StarOutlined />}
                  >
                    Add to Favorites
                  </Button>
                )}
              </div>
              <Typography.Paragraph
                italic
                type="secondary"
                style={{
                  float: 'right',
                  textAlign: 'left',
                  fontSize: 11,
                  display: 'block'
                }}
              >
                Note: The adding of exercise to favorites is limited to 100.
              </Typography.Paragraph>
            </div>
          )}
        </div>
        <Space size={24} direction="vertical" className="preview-modal-space">
          <Row gutter={[8, 8]}>
            {sets && (
              <Col lg={8} md={8} sm={24} xs={24}>
                <Typography.Title level={5} className="text-center">
                  {sets} Sets
                </Typography.Title>
              </Col>
            )}
            {reps && (
              <Col lg={8} md={8} sm={24} xs={24}>
                <Typography.Title level={5} className="text-center">
                  {reps} Reps
                </Typography.Title>
              </Col>
            )}
            {hold && (
              <Col lg={8} md={8} sm={24} xs={24}>
                <Typography.Title level={5} className="text-center">
                  {hold} Hold
                </Typography.Title>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Typography.Text>{instructions}</Typography.Text>
            </Col>
          </Row>
        </Space>
      </div>
    </Modal>
  );
}
