import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Popconfirm, Tooltip, notification } from 'antd';
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

import BillingDocumentModal from './../BillingDocumentModal';
import services from './../../../../../services';

const BillingCard = ({
  date,
  status,
  bill_id,
  bill_number,
  bill_date,
  billed_by,
  currentPeriod,
  getBillingDocument,
  billingDocument,
  refreshBillingList,
  patient,
  visibleProfile
}) => {
  const [openBillingDocument, setOpenBillingDocument] = useState(false);
  const [fromPopup, setFromPopup] = useState(false);

  const openBillingDocumentModal = () => {
    setOpenBillingDocument(true);
    getBillingDocument();

    if (status === 'marked') {
      setFromPopup(true);
    }
  };

  const copyToClipboard = async () => {
    navigator.clipboard.writeText(bill_id);

    if (status === 'marked') {
      notification.info({
        message: 'Loading...',
        description: 'Document is being generated. Please wait.'
      });

      await getBillingDocument().then(() => {
        refreshBillingList(true);

        notification.success({
          message: 'Success!',
          description: 'Copied to clipboard.'
        });
      });
    } else {
      notification.success({
        message: 'Success!',
        description: 'Copied to clipboard.'
      });
    }
  };

  const handleUnmarkCode = async () => {
    try {
      const { Sub, GroupId } = patient;
      await services.rtm.unmarkBilledCode(bill_id, Sub, GroupId).then(() => {
        refreshBillingList(true);

        notification.success({
          message: 'Success!',
          description: 'Billed code successfully unbilled.'
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while unbilling the code.'
      });
    }
  };

  return (
    <Fragment>
      <BillingDocumentModal
        openBillingDocument={openBillingDocument}
        setOpenBillingDocument={setOpenBillingDocument}
        billingDocumentDetails={billingDocument}
        date={date}
        bill_number={bill_number}
        fromPopup={fromPopup}
        refreshBillingList={(updated) => refreshBillingList(updated)}
      />

      <div className="patient-detail-tab-billing-card-container">
        <div className="patient-detail-tab-billing-card-header">
          <div className="patient-detail-tab-billing-card-date-container">
            <span className="patient-detail-tab-billing-card-time">
              {' '}
              {date}{' '}
            </span>
            {status === 'marked' && (
              <div className="patient-detail-tab-billing-card-status-container marked-card">
                <div className="patient-detail-tab-billing-card-status-dot marked-dot" />
                <span> Mark as Billed</span>
              </div>
            )}
            {status === 'billed' && (
              <div className="patient-detail-tab-billing-card-status-container billed-card">
                <div className="patient-detail-tab-billing-card-status-dot billed-dot" />
                <span> Billed</span>

                {bill_date && (
                  <Tooltip
                    overlayInnerStyle={{ width: 'max-content' }}
                    title={`${new Date(bill_date).toLocaleDateString('en-US', {
                      dateStyle: 'long'
                    })}${billed_by ? ` by ${billed_by}` : ''}`}
                  >
                    <div style={{ marginLeft: 8 }}>
                      <InfoCircleOutlined />
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {status === 'billed' && (
              <Popconfirm
                placement="topRight"
                okText="Yes"
                cancelText="No"
                onConfirm={handleUnmarkCode}
                zIndex={9999}
                title={
                  <p>
                    You are about to change this code’s status from billed
                    <br />
                    to unbilled. You can re-bill this code in the future.
                    <br />
                    Do you want to continue?
                  </p>
                }
                align={{
                  offset: [17, 0]
                }}
              >
                <Tooltip title="Reset Billing Status">
                  <div
                    style={{
                      cursor: 'pointer',
                      marginRight: 10
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUndo}
                      style={{
                        color: '#808080',
                        fontSize: 19,
                        paddingTop: 3
                      }}
                    />
                  </div>
                </Tooltip>
              </Popconfirm>
            )}

            {!!visibleProfile?.GroupInfo?.EnablePrompt && (
              <Tooltip title="Get Code to Export in Prompt">
                <img
                  alt="prompt-logo"
                  src="https://ptw-mobile-images.s3.amazonaws.com/prompt/Brand+Mark+Red.png"
                  onClick={copyToClipboard}
                  style={{
                    cursor: 'pointer',
                    width: 'auto',
                    height: '1.5em'
                  }}
                />
              </Tooltip>
            )}

            <div
              className="patient-detail-tab-billing-card-body-copy-icon"
              onClick={openBillingDocumentModal}
            >
              <div
                className={`patient-detail-tab-billing-card-body-item ${
                  !currentPeriod ? '' : 'current-period'
                }`}
              >
                <span className="patient-detail-tab-billing-card-body-item-text">
                  {bill_number}
                </span>
                <CopyOutlined />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  visibleProfile: state.visibleProfile
});

export default withRouter(connect(mapStateToProps)(BillingCard));
