import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  Typography,
  Button,
  Input,
  Space,
  Breadcrumb,
  Popover,
  Tooltip,
  Dropdown,
  Menu,
  Checkbox,
  notification,
} from 'antd';
import {
  FilterOutlined,
  InfoCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import {
  clearExportPrescription,
  clearTemplateData,
  setSelectedExercises,
} from '../actions/prescription';

import services from '../../../services';
import states from '../../../states';

import ExerciseTemplateCreation from './ExerciseTemplateCreation';
import ExerciseTemplateGrid from './ExerciseTemplateGrid';
import LoadingPage from '../../../components/LoadingPage';
import NoResults from '../../../components/NoResults';

const popoverContent = (
  <Space direction="vertical">
    <div className="popover-title-container">
      {' '}
      <Typography.Text strong>Templates</Typography.Text>
      <a
        className={'pull-right btn-link'}
        href={'https://vimeo.com/363131034/0276db8d8c'}
        target={'_blank'}
      >
        See Tutorial Video
      </a>
    </div>
    <Typography.Text>
      Templates allow you to save exercise programs for quick and easy reuse in
      <br />
      the future. After importing a Template into a patient's prescription, you
      <br />
      are still able to make custom changes. Templates are shared between all
      <br />
      providers in your group.
    </Typography.Text>
  </Space>
);

const filterTypeToText = type => {
  switch (type) {
    case 'GROUP_TEMPLATES':
      return 'Group Templates';
    case 'MY_TEMPLATES':
      return 'My Templates';
    default:
      return '';
  }
};

function ExerciseTemplates(props) {
  const {
    dispatch,
    visibleProfile,
    exportPrescription,
    selectedExercises,
    importedTemplate,
  } = props;

  const [templateState, setTemplateState] = useRecoilState(states.templates);

  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [listView, setListView] = useState(!!!exportPrescription);
  const [isEditingTemplate, setIsEditingTemplate] = useState(false);
  const [search, setSearch] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState(['MY_TEMPLATES']);
  const [templateData, setTemplateData] = useState(
    exportPrescription || importedTemplate
  );

  useEffect(() => {
    if (!!exportPrescription) {
      dispatch(setSelectedExercises(exportPrescription.exercises));
      dispatch(clearExportPrescription());
    }
  }, []);

  useEffect(() => {
    setTemplates(sortList(templateState.list));
  }, [templateState.list]);

  const getTemplates = async () => {
    try {
      setLoading(true);

      const { GroupId } = visibleProfile;
      const response = await services.templates.getList(GroupId);

      if (response.status === 200) {
        setTemplateState(prevState => ({
          ...prevState,
          list: response.data,
        }));
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while fetching templates.',
      });
    } finally {
      setLoading(false);
    }
  };

  const sortList = list => {
    return [...list].sort((a, b) => {
      const nameA = a.Name.toLowerCase();
      const nameB = b.Name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  };

  const selectFilter = type => {
    if (filter.includes(type)) {
      setFilter(prevFilter => prevFilter.filter(item => item !== type));
    } else {
      setFilter(prevFilter => [...prevFilter, type]);
    }
  };

  const searchTemplate = list => {
    const templateList = [...list];

    if (!search) {
      return templateList;
    }

    return templateList.filter(
      item =>
        item.Author.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        item.Name.toLowerCase().indexOf(search.toLowerCase()) >= 0
    );
  };

  const filterTemplates = list => {
    let templateList = [...list];

    if (filter.includes('GROUP_TEMPLATES')) {
      templateList = templateList.filter(item => !!item.GroupTemplate);
    }

    if (filter.includes('MY_TEMPLATES')) {
      templateList = templateList.filter(
        item => item.Author === visibleProfile.EmailAddress
      );
    }

    return sortList(templateList);
  };

  const handleSetListView = () => {
    setListView(true);
  };

  let templateList = searchTemplate(templates);
  templateList = filterTemplates(templateList);

  if (listView) {
    return (
      <React.Fragment>
        <div className="tab-header">
          <Typography.Title level={2}>
            Templates{' '}
            <Popover
              content={popoverContent}
              trigger="hover"
              placement="right"
              arrowPointAtCenter
            >
              <InfoCircleFilled className="popover-icon" />
            </Popover>
          </Typography.Title>
          <div className="tab-header-controls">
            <Button
              type="primary"
              className="btn-primary ptw-btn"
              onClick={() => {
                dispatch(clearTemplateData());
                setTemplateData([]);
                setListView(false);
                setIsEditingTemplate(false);
              }}
            >
              <PlusOutlined /> Add Template
            </Button>
            <Input
              autoFocus
              size="middle"
              placeholder="Search template or author"
              value={search}
              prefix={<SearchOutlined />}
              onChange={e => setSearch(e.target.value)}
            />
            <Tooltip title="Filter Templates">
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="group-templates" onClick={() => null}>
                      <Checkbox
                        onChange={() => selectFilter('GROUP_TEMPLATES')}
                        checked={filter.includes('GROUP_TEMPLATES')}
                      >
                        Group Templates
                      </Checkbox>
                    </Menu.Item>
                    <Menu.Item key="my-templates" onClick={() => null}>
                      <Checkbox
                        onChange={() => selectFilter('MY_TEMPLATES')}
                        checked={filter.includes('MY_TEMPLATES')}
                      >
                        My Templates
                      </Checkbox>
                    </Menu.Item>
                  </Menu>
                }
                onOpenChange={e => setFilterOpen(e)}
                open={filterOpen}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button
                  type={filter.length < 1 ? 'default' : 'primary'}
                  className="btn-primary"
                  shape="circle"
                >
                  <FilterOutlined style={{ fontSize: 16 }} />
                </Button>
              </Dropdown>
            </Tooltip>
          </div>
        </div>
        {!!filter.length && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Text style={{ fontSize: 16 }} level={5}>
              Filtered by -{' '}
              <Typography.Link onClick={() => setFilterOpen(!filterOpen)}>
                {filter.map(item => filterTypeToText(item)).join(', ')}{' '}
              </Typography.Link>
            </Typography.Text>
          </div>
        )}
        {search && (
          <div style={{ marginBottom: 20 }}>
            <Typography.Text>
              Search results for:{' '}
              <Typography.Text strong>{search}</Typography.Text>
            </Typography.Text>
          </div>
        )}
        {templateList.length === 0 && search.length !== 0 && (
          <NoResults content="Sorry, no results found." />
        )}
        {loading ? (
          <LoadingPage
            type="list"
            content="Loading templates, please wait..."
          />
        ) : (
          <ExerciseTemplateGrid
            templates={templateList}
            handleCardClick={template => {
              setIsEditingTemplate(true);
              dispatch(setSelectedExercises(template.Exercises));
              setTemplateData(template);
              setListView(false);
            }}
          />
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Breadcrumb separator="/">
        <Breadcrumb.Item
          href=""
          onClick={e => {
            e.preventDefault();
            handleSetListView();
          }}
        >
          Templates
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          {isEditingTemplate ? 'Edit Template' : 'Add Template'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <ExerciseTemplateCreation
        dispatch={dispatch}
        visibleProfile={visibleProfile}
        isEditingTemplate={isEditingTemplate}
        selectedExercises={selectedExercises}
        templateData={templateData}
        setTemplateData={setTemplateData}
        refetchList={getTemplates}
        returnToIndex={handleSetListView}
      />
    </React.Fragment>
  );
}

export default ExerciseTemplates;
