import '../styles.css';
import {
  Button,
  Checkbox,
  Input,
  notification,
  Select,
  Space,
  Switch,
  Typography
} from 'antd';
import { Fragment, useEffect, useState } from 'react';

import services from '../../../../../services';

const FormInput = ({ label, value, onChange }) => (
  <div className="item">
    <Typography.Text>{label}</Typography.Text>

    <Input
      readOnly={onChange === null}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  </div>
);

const FormSelect = ({ options, label, value, onChange }) => (
  <div className="item">
    <Typography.Text>{label}</Typography.Text>

    <Select className="dropdown" value={value} onChange={val => onChange(val)}>
      {options.map((item, i) => (
        <Fragment key={i}>
          <Select.Option value={item.key}>{item.value}</Select.Option>
        </Fragment>
      ))}
    </Select>
  </div>
);

const FormSwitch = ({ label, value, onChange }) => (
  <div className="item">
    <Space size="small">
      <Switch checked={value} onChange={val => onChange(val)} />

      <Typography.Text>{label}</Typography.Text>
    </Space>
  </div>
);

const UserForm = ({ profile, group, clinics, getProfile }) => {
  const [profileCopy, setProfileCopy] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (profile) {
      setProfileCopy(profile);
    }
  }, [profile]);

  const handleUpdateProfile = async () => {
    try {
      setSaving(true);

      await services.admin.updateProfile({
        oldProfile: profile,
        newProfile: profileCopy
      });

      notification.success({
        message: 'Update Success',
        description: 'User profile updated successfully',
        duration: 3
      });
    } catch (error) {
      notification.error({
        message: 'Analytics Failed',
        description: 'An error occurred while updating user profile',
        duration: 3
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    profileCopy && (
      <div className="su-utilities">
        <div className="row">
          <div className="column">
            <FormInput
              label="GROUP ID"
              value={profileCopy.GroupId}
              onChange={null}
            />
          </div>

          <div className="column">
            <div className="item">
              <Checkbox
                className="checkbox"
                style={{ marginTop: '1.1em' }}
                disabled={profile.GroupId === group.id}
                checked={profileCopy.GroupId === group.id}
                onChange={e =>
                  setProfileCopy({
                    ...profileCopy,
                    GroupId: e.target.checked ? group.id : profile.GroupId
                  })
                }
              >
                ASSIGN TO GROUP
              </Checkbox>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="column">
            <FormInput
              label="EMAIL ADDRESS"
              value={profileCopy.EmailAddress}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  EmailAddress: val
                })
              }
            />
          </div>

          <div className="column">
            <FormInput
              label="PHONE NUMBER"
              value={profileCopy.PhoneNumber}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  PhoneNumber: val
                })
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <FormSelect
              options={[
                { key: 'sa', value: 'Super Admin' },
                { key: 'ga', value: 'Group Admin' },
                { key: 'pt', value: 'Physical Therapist' },
                { key: 'p', value: 'Patient' }
              ]}
              label="ROLE"
              value={profileCopy.Role}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  Role: val
                })
              }
            />
          </div>

          <div className="column">
            <FormSelect
              options={clinics.map(item => ({
                key: item.Id,
                value: item.ClinicName
              }))}
              label="CLINICS"
              value={profileCopy.Clinic}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  Clinic: val
                })
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <FormInput
              label="FIRST NAME"
              value={profileCopy.FirstName}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  FirstName: val
                })
              }
            />
          </div>

          <div className="column">
            <FormInput
              label="LAST NAME"
              value={profileCopy.LastName}
              onChange={val =>
                setProfileCopy({
                  ...profileCopy,
                  LastName: val
                })
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <div className="row switches">
              <FormSwitch
                label="ACTIVE"
                value={!!profileCopy.Active}
                onChange={val =>
                  setProfileCopy({
                    ...profileCopy,
                    Active: val
                  })
                }
              />

              <FormSwitch
                label="DISCHARGE"
                value={!!profileCopy.Discharge}
                onChange={val =>
                  setProfileCopy({
                    ...profileCopy,
                    Discharge: val
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className="buttons">
          <div className="button">
            <Button onClick={() => setProfileCopy(profile)}>CANCEL</Button>
          </div>

          <div className="button">
            <Button
              type="primary"
              loading={saving}
              disabled={JSON.stringify(profile) === JSON.stringify(profileCopy)}
              onClick={async () => {
                await handleUpdateProfile();
                await getProfile(profileCopy.EmailAddress);
              }}
            >
              SAVE CHANGES
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default UserForm;
