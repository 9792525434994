import React, { useEffect } from 'react';
import { Space, Typography, Button, List, Tooltip, Popconfirm } from 'antd';
import {
  SnippetsOutlined,
  DeleteOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import SelectionItem from './SelectionItem';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  paddingLeft: isDragging ? 12 : 0,
  background: isDragging ? '#f9f9f9' : '#fff',
  boxShadow: isDragging ? '0px 2px 5px rgba(0, 0, 0, 0.25)' : 'none',
  ...draggableStyle
});

const getListStyle = () => ({
  width: '100%'
});

const SelectionPanel = ({
  selectedExercises,
  handleOpenExerciseModal,
  handleAddTemplateModal,
  handleRemoveExerciseItem,
  handleRemoveAllExerciseItem,
  onDragEnd
}) => {
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = () => {
    const selectionPanel = document.querySelector('.ptw-pres-exercise-list');
    const scrollTop = window.scrollY;

    if (selectionPanel) {
      if (scrollTop >= 300) {
        selectionPanel.classList.add('is-sticky');
      } else {
        selectionPanel.classList.remove('is-sticky');
      }
    }
  };

  return (
    <React.Fragment>
      <div className="ml-2 ptw-pres-exercise-list">
        <div className="mt-1">
          <Space
            direction="horizontal"
            className="pull-right"
            style={{ marginTop: -6 }}
          >
            <Tooltip title="Add Exercise">
              <Button
                shape="circle"
                type="link"
                size="large"
                icon={<PlusOutlined style={{ fontSize: 24 }} />}
                onClick={handleOpenExerciseModal}
              />
            </Tooltip>
            <Tooltip title="Add Template">
              <Button
                shape="circle"
                type="link"
                size="large"
                icon={<SnippetsOutlined style={{ fontSize: 24 }} />}
                onClick={handleAddTemplateModal}
              />
            </Tooltip>
            <Popconfirm
              placement="topRight"
              title="Are you sure to delete all prescriptions exercises?"
              onConfirm={handleRemoveAllExerciseItem}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Clear All">
                <Button
                  shape="circle"
                  type="link"
                  size="large"
                  icon={<DeleteOutlined style={{ fontSize: 24 }} />}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
          <Typography.Title level={5}>
            Selected Exercises (
            {Array.isArray(selectedExercises) ? selectedExercises.length : 0})
          </Typography.Title>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <List
                  className="mt-2"
                  dataSource={selectedExercises || []}
                  renderItem={(exercise, i) => (
                    <Draggable
                      key={exercise.id}
                      draggableId={exercise.id}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <SelectionItem
                            key={i}
                            exercise={exercise}
                            handleRemoveExerciseItem={handleRemoveExerciseItem}
                          />
                        </div>
                      )}
                    </Draggable>
                  )}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="mt-4" />
      </div>
    </React.Fragment>
  );
};

export default SelectionPanel;
