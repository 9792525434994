import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  Space,
  Button,
  Typography,
  Form,
  DatePicker,
  Select,
  Row,
  Col,
  Empty
} from 'antd';
import {
  ArrowLeftOutlined,
  ExportOutlined,
  FilterOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { downloadFile } from '../../../utils/csv.utils';

import BarChart from '../../../components/BarChart';
import services from '../../../services';
import states from '../../../states';
import setNotification from '../../../utils/setNotification.utils';
import * as reportsUtils from '../../../utils/reports.utils';

const { Option } = Select;
const formatDate = date => moment(date).format('DD-MMM-YY');

const ExpandCard = ({ type, handleClose }) => {
  const title =
    type === 'PATIENTS'
      ? 'Patients Added'
      : type === 'HEPS'
      ? 'Home Exercise Programs Assigned'
      : 'Care Plans Assigned';

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [chartTitle, setChartTitle] = useState('');
  const [report, setReport] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [disableSelect, setDisableSelect] = useState(false);
  const [filter, setFilter] = useState({
    dateRange: null,
    providers: [],
    locations: []
  });

  const groups = useRecoilValue(states.groups);
  const [form] = Form.useForm();

  useEffect(() => {
    if (type) {
      initChart();
    }
  }, [filter, type]);

  const initChart = async () => {
    try {
      if (error) {
        console.log('asdasd', form.getFieldValue('providers').length === 10);
        setError(false);
      }

      setLoading(true);

      const reportResponse = await fetchReports();
      const generatedReport = reportsUtils.generateUsageReport(reportResponse);

      setReport(generatedReport);

      let startDate;
      let endDate;

      if (filter.dateRange !== null) {
        startDate = moment(filter.dateRange[0]).format('MMM DD, YYYY');
        endDate = moment(filter.dateRange[1]).format('MMM DD, YYYY');
      }

      setChartTitle(
        `${title} ${!filter.dateRange ? '(Last 30 days)' : ''} Report ${
          filter.dateRange !== null ? `for ${startDate} - ${endDate}` : ''
        }`
      );
    } catch (error) {
      console.log('initChart', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchReports = async () => {
    try {
      let query = reportsUtils.generateDateQuery();
      const providers =
        filter.providers.length > 0
          ? filter.providers
          : groups.providers.filter(p => !!p.Active).map(p => p.Sub);

      if (filter.dateRange) {
        query = reportsUtils.generateDateQuery(
          moment(filter.dateRange[0]).format() || null,
          moment(filter.dateRange[1]).format() || null
        );
      }

      const response = await services.reports.getUsageReports(
        type === 'HEPS'
          ? 'HEP'
          : type === 'CAREPLANS'
          ? 'CAREPLAN'
          : 'PATIENTS',
        query,
        providers,
        filter.locations.length > 0 ? filter.locations : []
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log('fetchReports', type, error);
      throw error;
    }
  };

  const handleFilter = values => {
    setFilter({
      dateRange: [values.dateRange[0].format(), values.dateRange[1].format()],
      locations: Array.isArray(values.locations) ? [...values.locations] : [],
      providers: Array.isArray(values.providers) ? [...values.providers] : []
    });
  };

  const handleClear = () => {
    form.resetFields();
    setFilter({
      dateRange: null,
      providers: [],
      locations: []
    });
  };

  const handleExport = () => {
    try {
      const reports = [];
      for (let i = 0; i < report.labels.length; i++) {
        const values = {};

        for (let j = 0; j < report.datasets.length; j++) {
          const dataset = report.datasets[j];
          values[dataset.label] = dataset.data[i];
        }

        reports.push({
          '': report.labels[i],
          ...values
        });
      }

      const query = reportsUtils.generateDateQuery(
        filter.dateRange ? filter.dateRange[0] : null,
        filter.dateRange ? filter.dateRange[1] : null
      );
      const filename = `${title}_${formatDate(query.start)}_TO_${formatDate(
        query.end
      )}`;

      downloadFile(reports, filename);
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while trying to download file.'
      );
    }
  };

  const validateSelectProviders = () => {
    setDisableSelect(form.getFieldValue('providers').length === 10);
  };

  return (
    <Fragment>
      <Card
        title={
          <Space>
            <Button
              shape="circle"
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={handleClose}
            />
            <Typography.Text>{title}</Typography.Text>
          </Space>
        }
        extra={
          <Space>
            <Button
              type="default"
              icon={<FilterOutlined />}
              onClick={() => setShowFilter(!showFilter)}
            >
              Filter
            </Button>
            <Button
              type="primary"
              icon={<ExportOutlined />}
              disabled={loading || error || !report}
              onClick={handleExport}
            >
              Export
            </Button>
          </Space>
        }
      >
        {showFilter && (
          <Card style={{ background: '#f7f7f7', marginBottom: 20 }}>
            <Form
              className="filter-chart-form"
              form={form}
              onFinish={handleFilter}
            >
              <Row gutter={[16, 16]} style={{ flex: 1 }}>
                <Col span={8}>
                  <Form.Item
                    label="Date Range"
                    name="dateRange"
                    rules={[
                      {
                        required: true,
                        message: 'Please select date range'
                      }
                    ]}
                  >
                    <DatePicker.RangePicker
                      disabledDate={current =>
                        (current && current < moment().subtract(1, 'year')) ||
                        current > moment().endOf('day')
                      }
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Provider/s"
                    name="providers"
                    rules={[
                      {
                        type: 'array',
                        max: 10,
                        message: 'You can only select up to 10 providers'
                      }
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      mode="multiple"
                      placeholder="Select provider/s"
                      maxTagCount={5}
                      maxTagTextLength={15}
                      onChange={validateSelectProviders}
                      filterOption={(input, option) => {
                        const searchInput = input.toLowerCase();
                        const isString = typeof option.children === 'string';
                        const isObject = typeof option.children === 'object';

                        if (isString) {
                          return option.children
                            .toLowerCase()
                            .includes(searchInput);
                        }

                        if (isObject) {
                          return option.children.props.children
                            .toLowerCase()
                            .includes(searchInput);
                        }
                      }}
                    >
                      {reportsUtils
                        .sortProvidersByStatus(groups.providers)
                        .map(item => {
                          const selected = form.getFieldValue('providers');
                          const included = Array.isArray(selected)
                            ? !selected.includes(item.Sub)
                            : false;
                          const disabled = disableSelect && included;

                          return (
                            <Option value={item.Sub} disabled={disabled}>
                              {!item.Active ? (
                                <em
                                  style={{
                                    color: disabled ? '#bfbfbf' : 'grey'
                                  }}
                                >{`${item.FirstName} ${item.LastName}`}</em>
                              ) : (
                                `${item.FirstName} ${item.LastName}`
                              )}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Location/s"
                    name="locations"
                    rules={[
                      {
                        type: 'array',
                        max: 20,
                        message: 'You can only select up to 20 locations'
                      }
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      mode="multiple"
                      placeholder="Select location/s"
                      maxTagCount={5}
                      maxTagTextLength={15}
                      filterOption={(input, option) => {
                        if (option && option.label) {
                          const label = String(option.label).toLowerCase();
                          if (label.includes(String(input).toLowerCase())) {
                            return option;
                          }
                        }
                      }}
                      options={groups.clinics.map(c => ({
                        label: c.ClinicName,
                        value: c.Id
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Apply Filter
                  </Button>
                  <Button
                    htmlType="button"
                    type="default"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Card>
        )}

        {error ? (
          <Row justify="center" align="center">
            <Space size={12} direction="vertical">
              <Empty description="An error occured while loading reports. Please try again." />
              <Row justify="center" align="center">
                <Button>Reload Reports</Button>
              </Row>
            </Space>
          </Row>
        ) : (
          <BarChart chartTitle={chartTitle} loading={loading} data={report} />
        )}
      </Card>
    </Fragment>
  );
};

export default ExpandCard;
