import React from 'react';
import imagePlaceholder from '../../img/image-placeholder.png';
import { Card } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { Checkmark } from 'react-ionicons';

import V from 'voca';
import ReactGA from 'react-ga';

function PatientExerciseCard(props) {
  const {
    exercise,
    exercise: { star, isSelected, exerciseName, groupId, id },
    onTitleClick,
    onCardClick,
  } = props;

  return (
    <Card
      className="ptw-exercise-card-item"
      hoverable
      cover={
        <div
          className="ptw-card-image-wrapper"
          onClick={() => {
            if (!!onCardClick) {
              onCardClick(exercise);
            }
          }}
        >
          {star && <StarFilled className="ptw-card-favorites-icon" />}
          {isSelected && (
            <React.Fragment>
              <Checkmark className="ptw-card-select-icon" />
              <div className="ptw-card-image-mask" />
            </React.Fragment>
          )}
          <img
            crossOrigin="anonymous"
            alt="exercise"
            src={`https://d2p805pqn3eul9.cloudfront.net/${groupId}/${id}.jpg`}
            onError={(e) => {
              ReactGA.event({
                category: 'ImageError',
                action: `${groupId}/${id}`,
              });
              const proxy = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${groupId}/${id}.jpg`;
              if (e.target.src === proxy) {
                e.target.src = imagePlaceholder;
              } else {
                e.target.src = proxy;
              }
            }}
          />
        </div>
      }
    >
      <div
        className={`${
          isSelected && 'exercise-selected'
        } exercise-label-container ant-card-body`}
        onClick={() => {
          if (!!onTitleClick) {
            onTitleClick(exercise);
          }
        }}
      >
        <Card.Meta
          className="text-center"
          title={
            <React.Fragment>
              <span className="exercise-name">{V.titleCase(exerciseName)}</span>
              <span className="exercise-preview">Preview</span>
            </React.Fragment>
          }
        />
      </div>
    </Card>
  );
}

export default PatientExerciseCard;
