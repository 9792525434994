import { setRecoil } from 'recoil-nexus';
import config from '../../../config';
import states from '../../../states';

const setLoading = (value) => {
  setRecoil(states.patients, (prevState) => ({
    ...prevState,
    loading: value
  }));
};

const asyncValidate = (values, dispatch, { initialValues }, key) =>
  new Promise((resolve, reject) => {
    if (!key) {
      resolve();
    }

    const value = values[key].trim();
    const label = key[0] + key.slice(1, 5) + ' ' + key.slice(5).toLowerCase();
    const message = label + ' must be unique.';

    if (!value || value === initialValues[key]) {
      resolve();
    } else {
      let input = value.trim().toLowerCase();
      if (key === 'PhoneNumber') {
        const phoneNumber = input.replace(/[()\s\-]/g, '');
        if (phoneNumber.length < 11 && !phoneNumber.startsWith('+1')) {
          input = '+1' + phoneNumber;
        } else if (phoneNumber.length < 12 && !phoneNumber.startsWith('+')) {
          input = '+' + phoneNumber;
        }
      }

      const query = encodeURIComponent(input);
      const url = `${config.root}/users/external?input=${query}`;

      setLoading(true);
      fetch(url).then((response) => {
        response.json().then((data) => {
          setLoading(false);

          if (data.sub === input) {
            resolve();
          } else {
            reject({
              [key]: message
            });
          }
        });
      });
    }
  });

export default asyncValidate;
