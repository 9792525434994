import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col, Select, Typography } from 'antd';
import { orderBy } from 'lodash';
import {
  firstNameValidator,
  lastNameValidator,
  requiredValidator,
} from './validators/patients.js';
import asyncValidate from '../../utils/validation.utils.js';
import FormInput from '../groups/Input';

const orderClinics = clinics => {
  try {
    const sorted = orderBy(clinics, ['ClinicName'], ['asc']);
    return sorted;
  } catch (error) {
    return [];
  }
};

const PatientForm = props => {
  const {
    handleSubmit,
    groupClinics: dropDownClinics,
    initialValues,
    visibleProfile,
  } = props;

  const orderedClinics = orderClinics(dropDownClinics);

  const [hasClinics, setHasClinics] = useState(true);
  const [enableRTM, setEnableRTM] = useState(false);
  const [clinicList, setClinicList] = useState(orderedClinics);
  const [searchVal, setSearchVal] = useState('');
  const [inputType, setInputType] = useState('text');

  const rtmEnabled = !!visibleProfile?.GroupInfo.EnableRTM;
  const phoneEnabled = !!visibleProfile?.GroupInfo.EnablePhoneNumber;

  useEffect(() => {
    if (Array.isArray(dropDownClinics) && dropDownClinics.length > 0) {
      setHasClinics(true);
    } else {
      setHasClinics(false);
    }
  }, []);

  useEffect(() => {
    if (searchVal === '') {
      setClinicList(orderedClinics);
    } else {
      const filteredClinics = dropDownClinics.filter(clinic =>
        clinic.ClinicName.toLowerCase().includes(searchVal.toLowerCase())
      );
      setClinicList(orderClinics(filteredClinics));
    }
  }, [searchVal]);

  const handleKeyPress = e => {
    if (phoneEnabled) {
      if (e.key === 'Backspace' || (e.ctrlKey && e.key === 'a')) {
        setInputType('text');
        return;
      }

      const value = e.target.value;
      const areNumbers =
        value.length > 0
          ? value
              .trim()
              .replace(/[()\s\-]/g, '')
              .split('')
              .every(c => !isNaN(c))
          : false;

      if (areNumbers) {
        setInputType('tel');
      } else {
        setInputType('text');
      }
    }
  };

  const handleToggleRTM = () => {
    setEnableRTM(!enableRTM);
  };

  return (
    <Form className="ptw-form" layout="vertical" onFinish={handleSubmit}>
      <Row gutter={[16, 0]}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Field
            name="firstName"
            label="First Name"
            placeholder="Enter first name"
            isRequired
            component={FormInput}
            type="text"
            validate={[requiredValidator, firstNameValidator]}
          />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <Field
            name="lastName"
            label="Last Name"
            placeholder="Enter last name"
            isRequired
            component={FormInput}
            type="text"
            validate={[requiredValidator, lastNameValidator]}
          />
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          <Field
            inputType={inputType}
            name={phoneEnabled ? 'contact' : 'emailAddress'}
            label={`Email ${phoneEnabled ? 'or Phone Number' : ''}`}
            placeholder={`Enter email ${phoneEnabled ? 'or phone number' : ''}`}
            isRequired
            component={FormInput}
            validate={[requiredValidator]}
            onKeyDown={handleKeyPress}
          />
        </Col>

        {hasClinics && (
          <Col lg={12} md={12} sm={24} xs={24}>
            <Field
              name="location"
              label="Location"
              placeholder="Select location"
              component={FormInput}
              type="select"
              isRequired
              onSearch={val => setSearchVal(val)}
            >
              {clinicList.map((clinic, index) => {
                const { Id, ClinicName } = clinic;

                if (index === 0) {
                  return (
                    <Select.Option selected key={Id} value={Id}>
                      {ClinicName}
                    </Select.Option>
                  );
                } else {
                  return (
                    <Select.Option key={Id} value={Id}>
                      {ClinicName}
                    </Select.Option>
                  );
                }
              })}
            </Field>
          </Col>
        )}

        {!!rtmEnabled && (
          <Col lg={24} md={24} sm={24} xs={24}>
            <Field
              label="Enable RTM"
              name="enableRTM"
              type="switch"
              component={FormInput}
              defaultChecked={initialValues.enableRTM || false}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={handleToggleRTM}
            />
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default reduxForm({
  form: 'patient',
  asyncValidate,
  asyncBlurFields: ['contact', 'emailAddress'],
})(withRouter(PatientForm));
