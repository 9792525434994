import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  Button,
  Col,
  Empty,
  Row,
  Skeleton,
  Space,
  Spin,
  Table,
  Typography
} from 'antd';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { getNetPromoterScore } from '../actions/patients';
import './styles.css';

import moment from 'moment';
import setNotification from '../../../utils/setNotification.utils';
import services from '../../../services';
import states from '../../../states';

const NetPromoterScore = ({ currentPatient, dispatch }) => {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [npsSent, setNpsSent] = useState(false);
  const [npsRecords, setNpsRecords] = useState([]);
  const [nextSurveyDate, setNextSurveyDate] = useState(null);

  const [npsState, setNpsState] = useRecoilState(states.nps);

  const tableHeader = (title, index, width) => ({
    title,
    dataIndex: index,
    key: index,
    width,
    className: 'nps-tbl-cell',
    ellipsis: index === 'comment' ? false : true,
    ...(index === 'comment' && {
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{
            expandable: true,
            symbol: 'read more'
          }}
        >
          {text}
        </Typography.Paragraph>
      )
    })
  });

  useEffect(() => {
    if (currentPatient) {
      getSurveys();
    }
  }, [currentPatient]);

  useEffect(() => {
    if (npsState.survey.refetch && npsRecords.length) {
      getSurveys();
    }
  }, [npsState.survey.refetch]);

  useEffect(() => {
    if (npsSent) {
      setTimeout(() => {
        setNpsSent(false);
      }, 5000);
    }
  }, [npsSent]);

  const getSurveys = async () => {
    try {
      setLoading(true);

      const { Sub } = currentPatient;
      const nps = await dispatch(getNetPromoterScore(Sub));
      if (nps) {
        const data = nps.value;

        setNextSurveyDate(data.nextSurvey);
        setNpsRecords(
          data.records.map((item, i) => ({
            key: i,
            date: moment(Number(item.Date)).format('MMMM DD, YYYY'),
            score: item.Score + '/10',
            comment: item.Comment,
            provider: item.Providers.map((prov) => prov.FullName).join(', '),
            location: item.Location ? item.Location.ClinicName : null
          }))
        );

        setNpsState((prevState) => ({
          ...prevState,
          survey: {
            refetch: false
          }
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const sendSurvey = async () => {
    const notif = {
      type: 'success',
      message: 'Success!',
      description: 'NPS survey successfully sent.'
    };

    try {
      setSending(true);

      const { GroupId, Sub, EmailAddress, PhoneNumber } = currentPatient;
      await services.nps.sendSurvey({
        groupId: GroupId,
        sub: Sub,
        email: EmailAddress,
        phone: PhoneNumber
      });

      setNpsSent(true);
    } catch (error) {
      console.log(error);

      notif.type = 'error';
      notif.message = 'Send Failed!';
      notif.description = 'An error occurred while sending NPS survey.';
    } finally {
      setSending(false);
      setNotification(notif.type, notif.message, notif.description);
    }
  };

  return (
    <div className="ptw-collapse-body">
      <Row gutter={[16, 16]}>
        <Col lg={24} md={24} sm={24} xs={24}>
          {loading ? (
            <Skeleton active size="small" />
          ) : (
            <>
              <Space
                direction="horizontal"
                style={{ marginBottom: !npsRecords.length ? 0 : 8 }}
              >
                <div>
                  Next NPS:{' '}
                  {nextSurveyDate
                    ? moment(Number(nextSurveyDate)).format('MMMM DD, YYYY')
                    : 'None Scheduled'}
                </div>

                <Button
                  type="primary"
                  onClick={sendSurvey}
                  disabled={sending || npsSent}
                  icon={npsSent ? <CheckOutlined /> : null}
                >
                  {sending ? (
                    <div style={{ margin: '0 1.8em' }}>
                      <Spin indicator={<LoadingOutlined />} />
                    </div>
                  ) : npsSent ? (
                    'SUCCESS'
                  ) : (
                    'SEND NPS'
                  )}
                </Button>
              </Space>

              {!npsRecords.length > 0 ? (
                <Empty description="No NPS Found" />
              ) : (
                <Table
                  columns={[
                    tableHeader('Date', 'date', '12%'),
                    tableHeader('Score', 'score', '8%'),
                    tableHeader('Comment', 'comment', '35%'),
                    tableHeader('Provider', 'provider', '25%'),
                    tableHeader('Location', 'location', '20%')
                  ]}
                  dataSource={npsRecords}
                  pagination={false}
                  scroll={{ y: 1600 }}
                  // style={{ whiteSpace: 'pre' }}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NetPromoterScore;
