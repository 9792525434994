import React, { Fragment } from 'react';
import {} from 'react-router-dom';
import { Skeleton, Col, Row, Typography, Card, Popover, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import NoFavorites from './../NoFavorites';
import ExerciseItem from '../../../components/ExerciseItem';

const writeInContent = fromTemplate => {
  const target = fromTemplate ? 'template' : "patient's prescription";

  return (
    <span>
      A Write-In Exercise will only apply to this {target}. The Exercise will
      <br />
      <strong>not</strong> be added to the Exercise Library.
    </span>
  );
};

const ExerciseListDisplay = ({
  loading,
  currentView,
  favoritesSelected,
  favorites,
  exercisesList,
  handleSelectExercise,
  handleExerciseDetailsModal,
}) => {
  if (loading) {
    return <Skeleton loading={loading} shape="square" active />;
  }

  return (
    <React.Fragment>
      {favoritesSelected &&
      (!favorites.list ||
        (Array.isArray(favorites.list) && favorites.list.length === 0)) ? (
        <NoFavorites />
      ) : (
        <Fragment>
          {Array.isArray(exercisesList) && exercisesList.length > 0 ? (
            <Row gutter={[8, 8]}>
              {exercisesList.map((exercise, i) => (
                <Col
                  lg={currentView === 'card' ? 6 : 12}
                  md={currentView === 'card' ? 6 : 12}
                  key={i}
                >
                  <ExerciseItem
                    currentView={currentView}
                    exercise={exercise}
                    handleExerciseDetailsModal={handleExerciseDetailsModal}
                    handleSelectExercise={handleSelectExercise}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <Empty description="No exercises found." />
          )}
        </Fragment>
      )}
    </React.Fragment>
  );
};

export default ExerciseListDisplay;
