import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Row, Button, Breadcrumb, Table } from 'react-bootstrap';
import { Dropdown, Menu, Checkbox } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import Loading from '../../components/Loading';
import {
  isLoading,
  doneLoading,
  getAdmins,
  updateCurrentUser
} from './actions/users';
import { usersValuesSelector } from './selectors';
import SearchBox from '../../components/SearchBox';
import { Link } from 'react-router-dom';

const getBillingRole = role => {
  if (!role) return '';

  switch (role.toLowerCase()) {
    case 'pt':
      return 'Provider';

    case 'pta':
      return 'Provider Assistant';

    case 'tech':
      return 'Technician';

    default:
      return role;
  }
};

const isPartTime = value => (value ? 'YES' : 'NO');

class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: null,
      searchTerm: '',
      filterDropDownOpen: false,
      selectedRoleFilter: [],
      selectedUserStatusFilter: [],
      selectedEmplStatusFilter: []
    };

    this.fields = {
      lastName: 'Last Name',
      firstName: 'First Name',
      emailAddress: 'Email',
      Role: 'Role',
      BillingRole: 'BillingRole'
    };

    this.goToFormPage = this.goToFormPage.bind(this);
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, visibleProfile } = this.props;
    dispatch(isLoading());

    const queries = [];

    queries.push(dispatch(getAdmins(visibleProfile.GroupId)));

    Promise.all(queries).then(() => dispatch(doneLoading()));
  }

  filterUsers = users => {
    let { searchTerm } = this.state;

    if (!searchTerm) {
      return users;
    }
    const filteredUsers = [];

    if (!searchTerm) {
      return;
    }

    searchTerm = String(searchTerm).toLowerCase();

    for (let index = 0; index < users.length; index++) {
      const item = users[index];

      if (!item.FirstName || !item.LastName) {
        continue;
      }

      const userName = item.FirstName
        ? String(
            item.FirstName.toLowerCase() + ' ' + item.LastName.toLowerCase()
          )
        : String(
            item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase()
          );

      const email = item.EmailAddress
        ? String(item.EmailAddress)
        : String(item.emailAddress);

      if (userName.includes(searchTerm) || email.includes(searchTerm)) {
        filteredUsers.push(item);
      }
    }
    return filteredUsers;
  };

  unique = array => {
    const users = [];
    const singles = [];

    for (let i = 0; i < array.length; i++) {
      const user = array[i].emailAddress;

      const lambda = element => element === user;

      if (users.findIndex(lambda) === -1) {
        singles.push(array[i]);
        users.push(user);
      }
    }

    return singles;
  };

  handleFilterDropDown = flag => {
    this.setState({
      filterDropDownOpen: flag
    });
  };

  handleRoleFilter = e => {
    const { selectedRoleFilter } = this.state;

    if (!selectedRoleFilter.includes(e.target.value)) {
      this.setState({
        selectedRoleFilter: [...selectedRoleFilter, e.target.value]
      });
    } else {
      this.setState({
        selectedRoleFilter: selectedRoleFilter.filter(
          item => item !== e.target.value
        )
      });
    }
  };

  handleUserStatusFilter = e => {
    const { selectedUserStatusFilter } = this.state;

    const boolToString = e.target.value === true ? 'true' : 'false';

    if (!selectedUserStatusFilter.includes(e.target.value)) {
      this.setState({
        selectedUserStatusFilter: [
          ...selectedUserStatusFilter,
          e.target.value,
          boolToString
        ]
      });
    } else {
      this.setState({
        selectedUserStatusFilter: selectedUserStatusFilter.filter(
          item => item !== e.target.value && item !== boolToString
        )
      });
    }
  };

  handleEmplStatusFilter = e => {
    const { selectedEmplStatusFilter } = this.state;
    if (!selectedEmplStatusFilter.includes(e.target.value)) {
      this.setState({
        selectedEmplStatusFilter: [...selectedEmplStatusFilter, e.target.value]
      });
    } else {
      this.setState({
        selectedEmplStatusFilter: selectedEmplStatusFilter.filter(
          item => item !== e.target.value
        )
      });
    }
  };

  filterByRole = users => {
    const { selectedRoleFilter } = this.state;

    if (selectedRoleFilter.length !== 0) {
      const selectedRoleFilterLowered = selectedRoleFilter.map(item =>
        item.toLowerCase()
      );

      return users.filter(
        user =>
          user.BillingRole &&
          selectedRoleFilterLowered.includes(user.BillingRole.toLowerCase())
      );
    } else {
      return users;
    }
  };

  filterByUserStatus = users => {
    const { selectedUserStatusFilter } = this.state;

    if (selectedUserStatusFilter.length !== 0) {
      return users.filter(user =>
        selectedUserStatusFilter.includes(user.active)
      );
    } else {
      return users;
    }
  };

  filterByEmplStatus = users => {
    const { selectedEmplStatusFilter } = this.state;

    if (selectedEmplStatusFilter.length) {
      return users.filter(user => {
        if (user.partTime && selectedEmplStatusFilter.includes(user.partTime)) {
          return true;
        } else if (!user.partTime && selectedEmplStatusFilter.includes(false)) {
          return true;
        }
      });
    } else {
      return users;
    }
  };

  removeEmptyObj = users => {
    return users.filter(user => Object.keys(user).length !== 0);
  };

  render() {
    const {
      goToFormPage,
      handleSearchTermChange,
      props: { admins, fetched, isLoading, isSearching },
      state: { filterDropDownOpen }
    } = this;

    let allUsers = Array.isArray(admins) ? admins : [];

    allUsers = this.filterUsers(allUsers);
    allUsers = this.filterByRole(allUsers);
    allUsers = this.filterByUserStatus(allUsers);
    allUsers = this.filterByEmplStatus(allUsers);
    allUsers = this.removeEmptyObj(allUsers);

    let content;

    if (fetched) {
      if (allUsers.length === 0) {
        if (isSearching) {
          content = (
            <Row>
              <Col md={4} mdOffset={4} className="ptw-action-block">
                No results found
              </Col>
            </Row>
          );
        } else {
          content = (
            <Row>
              <Col md={4} mdOffset={4} className="ptw-action-block">
                <Button
                  className="btn-primary btn-block"
                  onClick={goToFormPage}
                >
                  Add User
                </Button>
              </Col>
            </Row>
          );
        }
      } else {
        content = (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email Address</th>
                <th>Billing Role</th>
                <th>Part-Time</th>
              </tr>
            </thead>
            <tbody>
              {allUsers.map((user, i) => (
                <tr
                  key={i}
                  onClick={() => {
                    goToFormPage(user);
                  }}
                  className={
                    user.active === 'false' || user.active === false
                      ? 'disabledEntity'
                      : ''
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.emailAddress}</td>
                  <td>{getBillingRole(user.BillingRole)}</td>
                  <td>{isPartTime(user.partTime || false)}</td>
                </tr>
              ))}
            </tbody>
            {Array.isArray(allUsers) && (
              <tfoot>
                <tr>
                  <td colSpan={4} />
                  <td>
                    <strong>Total Count: {allUsers.length}</strong>
                  </td>
                </tr>
              </tfoot>
            )}
          </Table>
        );
      }
    }

    const menu = (
      <Menu>
        <Menu.ItemGroup title="Billing Role">
          <Menu.Item>
            <Checkbox value="PT" onChange={this.handleRoleFilter}>
              Provider
            </Checkbox>
          </Menu.Item>
          <Menu.Item>
            <Checkbox value="PTA" onChange={this.handleRoleFilter}>
              Provider Assistant
            </Checkbox>
          </Menu.Item>
          <Menu.Item>
            <Checkbox value="TECH" onChange={this.handleRoleFilter}>
              Technician
            </Checkbox>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="User Status">
          <Menu.Item>
            <Checkbox value={true} onChange={this.handleUserStatusFilter}>
              Active
            </Checkbox>
          </Menu.Item>
          <Menu.Item>
            <Checkbox value={false} onChange={this.handleUserStatusFilter}>
              Inactive
            </Checkbox>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Employment Status">
          <Menu.Item>
            <Checkbox value={false} onChange={this.handleEmplStatusFilter}>
              Full-Time
            </Checkbox>
          </Menu.Item>
          <Menu.Item>
            <Checkbox value={true} onChange={this.handleEmplStatusFilter}>
              Part-Time
            </Checkbox>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );

    return (
      <div style={{ padding: '15px' }}>
        <Row>
          <Col md={12}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={'/administrator'}>Admin</Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Providers</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row style={{ marginTop: '40px' }}>
          <Col xs={12} md={6}>
            <Button
              onClick={this.goToNewUserForm}
              className="btn btn-primary pull-right"
            >
              <i className="fa fa-plus" aria-hidden="true" /> Add New Provider
            </Button>
          </Col>
          <Col xs={12} md={5}>
            <SearchBox onChange={handleSearchTermChange} placeholder="Name" />
          </Col>
          <Col xs={12} md={1}>
            <Dropdown
              onVisibleChange={this.handleFilterDropDown}
              visible={filterDropDownOpen}
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                className="btn outline-primary pull-right"
                onClick={e => e.preventDefault()}
              >
                Filter by <CaretDownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <Loading isLoading={isLoading}>{content}</Loading>
      </div>
    );
  }

  goToNewUserForm = () => {
    this.props.dispatch(updateCurrentUser({}));
    this.props.history.push(`/users/new`);
  };

  goToFormPage(user) {
    user.emailAddress && this.props.dispatch(updateCurrentUser(user));
    this.props.history.push(
      `/users/${user.emailAddress ? user.emailAddress : 'new'}`
    );
  }

  handleSearchTermChange(event) {
    const searchTerm = event.target.value;

    this.setState({ searchTerm: searchTerm });
  }
}

function mapStateToProps(state) {
  return {
    visibleProfile: state.visibleProfile,
    admins: state.users.admins,
    users: usersValuesSelector(state),
    fetched: state.users.fetched,
    isLoading: state.users.isLoading,
    isSearching: state.users.isSearching
  };
}

export default connect(mapStateToProps)(withRouter(UsersPage));
