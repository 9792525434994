import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router';
import { useRecoilState } from 'recoil';
import {
  Button,
  Collapse,
  Image,
  Modal,
  notification,
  Skeleton,
  Typography,
} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import {
  clearCurrentMonitoredPatient,
  exitCurrentMonitoredPatient,
  setCurrentMonitoredPatient,
  startLoadingMonitoredPatient,
} from '../../features/monitor-timer/actions';
import {
  changeWeek,
  getCurrentWeekDates,
  getPatientAnalyticsData,
  postCarePlan,
  resetPatient,
  setCurrentWeek,
  updateCurrentUser,
} from '../../features/patients/actions/patients';

import CarePlanForm from '../../components/CarePlanForm';
import LoadingPage from '../../components/LoadingPage';
import AdherenceLog from '../../features/multiple-prescriptions/components/AdherenceLog';
import CarePlan from '../../features/patients/CarePlan';
import NetPromoterScore from '../../features/patients/NetPromoterScore';
import PTUAdherenceLog from '../../features/patients/PTUAdherenceLog';
import PatientPageInfo from '../../features/patients/PatientPageInfo';
import PrescriptionDetails from '../../features/multiple-prescriptions/components/PrescriptionDetails';
import PrescriptionList from '../../features/patients/PrescriptionList';
import EditPrescription from '../EditPrescription';

import PTULogo from '../../img/PT.png';
import services from '../../services';
import states from '../../states';
import { delay } from '../../features/patients/RTMDashboard/components/PatientDetailDrawer/mixins';
import { getQueryEnd } from '../../utils/dateQuery.utils';

const PanelHeader = ({ title }) => (
  <Typography.Text strong>{title}</Typography.Text>
);

const PatientProfile = ({
  dispatch,
  history,
  match,
  visibleProfile,
  week,
  patient,
  currentMonitoredPatient,
  isMonitoring,
}) => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [showPTUModal, setShowPTUModal] = useState(false);
  const [ptuData, setPTUData] = useState(null);
  const [savingPTU, setSavingPTU] = useState(false);
  const [hasPendingBill, setHasPendingBill] = useState(false);

  const [params, setParams] = useRecoilState(states.params);
  const [prescription, setPrescription] = useRecoilState(states.prescription);
  const { list, form } = prescription;

  useEffect(() => {
    dispatch(changeWeek(0));
    dispatch(setCurrentWeek(getCurrentWeekDates(week)));

    getPatientDetails();

    return () => {
      if (params.caseId) {
        setParams({
          ...params,
          caseId: '',
        });
      }

      dispatch(resetPatient);
    };
  }, []);

  useEffect(() => {
    setPrescription({
      ...prescription,
      list: [],
      form: null,
      action: '',
    });
  }, []);

  useEffect(() => {
    if (!loading && Object.keys(profile).length) {
      const { Prescriptions } = profile;
      const { GroupInfo } = visibleProfile;

      if (Array.isArray(Prescriptions) && Prescriptions.length === 1) {
        const item = Prescriptions[0];
        const exercises = item.Prescription.exercises;
        const hasPrescription = !!exercises.length;

        if (!hasPrescription) {
          const ptuEnabled = !!GroupInfo.EnablePTU;
          const pathname = ptuEnabled ? 'care-plan' : 'prescription';

          history.push(match.url + '/' + pathname);
        }
      }
    }
  }, [loading]);

  const getPatientDetails = async () => {
    try {
      setLoading(true);

      const { params } = match;
      const { id } = params;
      const { GroupInfo, GroupId } = visibleProfile;

      const requests = [services.patients.getPatientDetails('sub', id)];
      if (!!GroupInfo.EnableRTM) {
        requests.push(
          dispatch(getPatientAnalyticsData(GroupInfo.Name, id, GroupId))
        );
      }

      const response = await Promise.all(requests);
      const { data } = response[0];

      if (data && data.message && data.message === 'User not found') {
        notification.error({
          message: 'Error!',
          description: 'User not found.',
          duration: 3,
        });

        history.replace('/patients');
        history.push('/');

        return;
      }

      if (!!response[1] && !!data.EnableRTM) {
        const { value } = response[1];
        const { EnrollStart, BillingEnd } = value;

        const queryEnd = getQueryEnd(BillingEnd);
        const res = await services.rtm.getBillableCodes(GroupId, id, {
          start: EnrollStart,
          end: queryEnd,
        });

        const hasPending = res.data.some(b => b.Status === 'marked');
        setHasPendingBill(hasPending);
      }

      setProfile(data);
      dispatch(updateCurrentUser(data));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setExitActivity = async (activity = null) => {
    if (patient && patient.EnableRTM) {
      await dispatch(startLoadingMonitoredPatient());

      if (currentMonitoredPatient && isMonitoring) {
        await dispatch(exitCurrentMonitoredPatient());
      }

      await delay(1200);

      if (activity) {
        const { GroupInfo, EmailAddress } = visibleProfile;
        const payload = {
          patient,
          activity,
          groupName: GroupInfo.Name,
          provider: EmailAddress,
        };

        await dispatch(setCurrentMonitoredPatient(payload));
      }
    }
  };

  const clearMonitoredPatient = async () => {
    if (patient && patient.EnableRTM) {
      if (currentMonitoredPatient && isMonitoring) {
        await dispatch(exitCurrentMonitoredPatient());
      }
      await dispatch(clearCurrentMonitoredPatient());
    }
  };

  const handleAddPrescription = () => {
    setPrescription({
      ...prescription,
      action: 'ADD',
      form: {
        active: true,
        name: '',
        prescription: {
          completedExercises: 0,
          completedRoutines: 0,
          exercises: [],
          frequency: 1,
          perdiem: 'day',
          perseptem: null,
          instructions: 'None',
          streakCounter: 0,
          updated: null,
        },
      },
    });

    handleChangeRoute('prescription');
  };

  const handleEditPrescription = () => {
    setPrescription({
      ...prescription,
      action: 'EDIT',
    });

    handleChangeRoute('prescription');
  };

  const handleChangeRoute = pathname => {
    history.push(match.url + '/' + pathname);
  };

  const handleSavePTUData = async () => {
    try {
      const { GroupInfo } = visibleProfile;
      if (ptuData) {
        setSavingPTU(true);
        await dispatch(postCarePlan(GroupInfo.Name, ptuData));

        notification.success({
          message: 'Success!',
          description: 'Care plan successfully added.',
          duration: 3,
        });

        setPTUData(null);
        await getPatientDetails();
      }
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while adding care plan.',
        duration: 3,
      });
    } finally {
      setSavingPTU(false);
      setShowPTUModal(false);
    }
  };

  if (loading) {
    return (
      <div className="ptw-main-body">
        <LoadingPage
          type="list"
          content="Loading patient details, please wait..."
        />
      </div>
    );
  }

  return (
    Object.keys(profile).length > 0 && (
      <Fragment>
        <PatientPageInfo
          dispatch={dispatch}
          history={history}
          patient={profile}
          setExitActivity={setExitActivity}
          clearMonitoredPatient={clearMonitoredPatient}
          hasPendingBill={hasPendingBill}
        />

        <div className="patient-profile-container">
          <Route
            path={match.url + '/care-plan'}
            render={props => (
              <CarePlan
                {...props}
                dispatch={dispatch}
                goToRxPage={() => handleChangeRoute('prescription')}
              />
            )}
          />

          <Route
            path={match.url + '/prescription'}
            render={props => (
              <EditPrescription
                {...props}
                dispatch={dispatch}
                setExitActivity={setExitActivity}
              />
            )}
          />

          <Route
            exact
            path={match.url}
            render={() => (
              <Collapse
                className="patient-profile-collapse"
                bordered={false}
                defaultActiveKey={[
                  'nps',
                  'ptu',
                  'adherence',
                  'singleRx',
                  'multipleRx',
                ]}
                expandIcon={({ active }) =>
                  active ? <MinusOutlined /> : <PlusOutlined />
                }
              >
                {!!visibleProfile.GroupInfo.EnablePTU && (
                  <Fragment>
                    <Collapse.Panel
                      key="nps"
                      header={<PanelHeader title="Net Promoter Score" />}
                    >
                      <NetPromoterScore
                        currentPatient={profile}
                        dispatch={dispatch}
                      />
                    </Collapse.Panel>

                    <Collapse.Panel
                      key="ptu"
                      header={
                        <Image
                          preview={false}
                          src={PTULogo}
                          style={{ height: 35 }}
                        />
                      }
                    >
                      <PTUAdherenceLog
                        currentPatient={profile}
                        dispatch={dispatch}
                        onEditClick={() => handleChangeRoute('care-plan')}
                        openCarePlan={() => setShowPTUModal(!showPTUModal)}
                      />
                    </Collapse.Panel>
                  </Fragment>
                )}

                {list.length > 1 ? (
                  <Collapse.Panel
                    key="multipleRx"
                    header={<PanelHeader title="Prescriptions" />}
                  >
                    <PrescriptionList
                      patient={profile}
                      onAddClick={handleAddPrescription}
                      onEditClick={handleEditPrescription}
                    />
                  </Collapse.Panel>
                ) : (
                  <Fragment>
                    <Collapse.Panel
                      key="adherence"
                      header={<PanelHeader title="Adherence Log" />}
                    >
                      <div className="ptw-main-body">
                        {!form ? (
                          <Skeleton active size="small" />
                        ) : (
                          <AdherenceLog history={history} />
                        )}
                      </div>
                    </Collapse.Panel>

                    <Collapse.Panel
                      key="singleRx"
                      header={<PanelHeader title="Prescription" />}
                    >
                      <div className="ptw-main-body" style={{ paddingTop: 22 }}>
                        {!form ? (
                          <Skeleton active size="small" />
                        ) : (
                          <Fragment>
                            {!!visibleProfile?.GroupInfo?.EnableMultipleRx &&
                              !!form?.prescription.exercises.length && (
                                <div className="tab-header">
                                  <div />
                                  <Button
                                    type="primary"
                                    className="btn-primary ptw-btn"
                                    size="large"
                                    onClick={handleAddPrescription}
                                  >
                                    <PlusOutlined /> Add Prescription
                                  </Button>
                                </div>
                              )}

                            <div>
                              <PrescriptionDetails
                                dispatch={dispatch}
                                prescriptionItem={form}
                                onEditClick={handleEditPrescription}
                              />
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </Collapse.Panel>
                  </Fragment>
                )}
              </Collapse>
            )}
          />
        </div>

        {showPTUModal && (
          <Modal
            title="Add Care Plan"
            okText="Save"
            width={1200}
            visible={showPTUModal}
            confirmLoading={savingPTU}
            onOk={handleSavePTUData}
            onCancel={() => setShowPTUModal(!showPTUModal)}
            okButtonProps={{
              disabled:
                !ptuData || Object.values(ptuData).some(data => data === null),
            }}
          >
            <CarePlanForm
              dispatch={dispatch}
              sendData={data => setPTUData(data)}
            />
          </Modal>
        )}
      </Fragment>
    )
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
  week: state.patients.weekChange,
  patient: state.patients.currentPatient,
  currentMonitoredPatient: state.monitorTimer.currentMonitoredPatient,
  isMonitoring: state.monitorTimer.isMonitoring,
});

export default withRouter(connect(mapStateToProps)(PatientProfile));
