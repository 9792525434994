import apiFetch from '../utils/fetch.utils';

export const getBase64 = async (url) => {
  try {
    const uri = `image-proxy?url=${encodeURIComponent(url)}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
