import React, { useState, useEffect } from 'react';
import { Col, Input, Row, Select, Typography, Form } from 'antd';
import ReactGA from 'react-ga';

import imagePlaceholder from '../../../img/image-placeholder.png';

const TemplateFieldData = ({
  exerciseFrequency,
  exercise,
  onChange,
  initialValues
}) => {
  const {
    sets,
    reps,
    hold,
    instructions,
    exerciseName,
    id,
    groupId,
    frequency: dbFrequency,
    perdiem: dbPerdiem
  } = exercise;
  const [eventFrom, setEventFrom] = useState(null);
  const [frequency, setFrequency] = useState(dbFrequency ? dbFrequency : '1');
  const [perdiem, setPerdiem] = useState(dbPerdiem ? dbPerdiem : 'day');

  useEffect(() => {
    if (exerciseFrequency && !dbFrequency && !dbPerdiem) {
      setFrequency(initialValues.Frequency);
      setPerdiem(initialValues.Perdiem);
      onChange(id, 'frequency', initialValues.Frequency);
      onChange(id, 'perdiem', initialValues.Perdiem);
    } else {
      onChange(id, 'frequency', null);
      onChange(id, 'perdiem', null);
    }
  }, [exerciseFrequency]);

  useEffect(() => {
    if (eventFrom === 'frequency') {
      setFrequency(dbFrequency);
    } else if (eventFrom === 'perdiem') {
      setPerdiem(dbPerdiem);
    }
  }, [dbFrequency, dbPerdiem]);

  const handleSet = (val) => {
    //eslint-disable-next-line
    let formattedVal = val.replace(/[^0-9\-]/g, '');
    onChange(id, 'sets', formattedVal);
  };

  const handleRep = (val) => {
    //eslint-disable-next-line
    let formattedVal = val.replace(/[^0-9\-]/g, '');
    onChange(id, 'reps', formattedVal);
  };

  return (
    <div className="customize-exercise-item">
      <Row gutter={[16, 16]} style={{ marginTop: -5, marginBottom: -5 }}>
        <Col lg={1} md={1} />
        <Col lg={23} md={23}>
          <Typography.Title level={5}>{exerciseName}</Typography.Title>
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col lg={6} md={6} className="center-content">
          <img
            style={{
              width: '100%',
              aspectRatio: 16 / 10
            }}
            className="exercise-thumbnail"
            alt="exercise"
            src={
              exercise.imgBase64 ||
              exercise.image ||
              exercise.imageUrl ||
              `https://d2p805pqn3eul9.cloudfront.net/${groupId}/${id}.jpg`
            }
            onError={(e) => {
              ReactGA.event({
                category: 'ImageError',
                action: `${groupId}/${id}`
              });
              const proxy = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${groupId}/${id}.jpg`;
              if (e.target.src === proxy) {
                e.target.src = imagePlaceholder;
              } else {
                e.target.src = proxy;
              }
            }}
          />
        </Col>
        <Col lg={18} md={18}>
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              <Col lg={12} md={12}>
                <Typography.Text>Parameters</Typography.Text>
                <Row
                  gutter={[8, 8]}
                  className="mt-1"
                  style={{ marginBottom: -8 }}
                >
                  <Col lg={8} md={8}>
                    <Form.Item name="sets">
                      <Input
                        size="large"
                        placeholder="Enter sets"
                        defaultValue={
                          /*eslint-disable-next-line*/
                          Number.isInteger(parseInt(sets + '')) ? sets : 1
                        }
                        value={sets}
                        onChange={(e) => handleSet(e.target.value)}
                        type="text"
                        name="sets"
                        suffix="sets"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8}>
                    <Form.Item name="reps">
                      <Input
                        size="large"
                        placeholder="Enter reps"
                        defaultValue={
                          /*eslint-disable-next-line*/
                          Number.isInteger(parseInt(reps + '')) ? reps : 1
                        }
                        value={reps}
                        onChange={(e) => handleRep(e.target.value)}
                        type="text"
                        name="reps"
                        suffix="reps"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8}>
                    <Form.Item name="hold">
                      <Input
                        size="large"
                        placeholder="Enter hold"
                        value={hold}
                        defaultValue={hold ? hold : ' '}
                        onChange={(e) => onChange(id, 'hold', e.target.value)}
                        type="text"
                        name="hold"
                        suffix="hold"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {exerciseFrequency && (
                  <React.Fragment>
                    <Typography.Text>Prescription Frequency</Typography.Text>
                    <div className="mt-1">
                      <Select
                        size="large"
                        onChange={(val) => {
                          setEventFrom('frequency');
                          onChange(id, 'frequency', val);
                        }}
                        value={frequency}
                        defaultValue={dbFrequency ? dbFrequency : '1'}
                        virtual={false}
                        name="frequency"
                        style={{ minWidth: 120 }}
                      >
                        <Select.Option value={1}>1x</Select.Option>
                        <Select.Option value={2}>2x</Select.Option>
                        <Select.Option value={3}>3x</Select.Option>
                        <Select.Option value={4}>4x</Select.Option>
                        <Select.Option value={5}>5x</Select.Option>
                        <Select.Option value={6}>6x</Select.Option>
                        <Select.Option value={7}>7x</Select.Option>
                        <Select.Option value={8}>8x</Select.Option>
                        <Select.Option value={9}>9x</Select.Option>
                        <Select.Option value={10}>10x</Select.Option>
                      </Select>
                      <span className="ml-2 mr-2">Every</span>
                      <Select
                        size="large"
                        onChange={(val) => {
                          setEventFrom('perdiem');
                          onChange(id, 'perdiem', val);
                        }}
                        value={perdiem}
                        virtual={false}
                        name="perdiem"
                        style={{ minWidth: 120 }}
                      >
                        <Select.Option value={'day'}>Day</Select.Option>
                        <Select.Option value={'week'}>Week</Select.Option>
                        <Select.Option value={'month'}>Month</Select.Option>
                      </Select>
                    </div>
                  </React.Fragment>
                )}
              </Col>

              <Col lg={12} md={12}>
                <Form.Item
                  name="instructions"
                  label="Instructions"
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (!value.length) {
                          onChange(id, 'formError', true);
                          return Promise.reject(
                            'Please input your instructions.'
                          );
                        }

                        if (value.length > 1000) {
                          onChange(id, 'formError', true);
                          return Promise.reject(
                            'Max length is 1000 characters.'
                          );
                        }

                        onChange(id, 'formError', false);
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Input.TextArea
                    defaultValue={instructions}
                    value={instructions ? instructions : ' '}
                    onInput={(e) =>
                      onChange(id, 'instructions', e.target.value)
                    }
                    size="large"
                    placeholder="Enter instructions"
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default TemplateFieldData;
