import React, { useEffect, useState } from 'react';
import { Row, Col, Pagination } from 'antd';
import ExerciseTemplateCard from './ExerciseTemplateCard';

const ExerciseTemplateGrid = ({ templates, handleCardClick }) => {
  const [pagination, setPagination] = useState({
    patientsPerPageSize: 24,
    patientsTotalPage: 0,
    patientsCurrentPage: 1,
    minIndex: 0,
    maxIndex: 0,
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      minIndex: 0,
      maxIndex: pagination.patientsPerPageSize,
      patientsCurrentPage: pagination.patientsCurrentPage,
    });
  }, [templates.length]);

  const handlePagination = page => {
    setPagination({
      ...pagination,
      patientsCurrentPage: page,
      minIndex: (page - 1) * pagination.patientsPerPageSize,
      maxIndex: page * pagination.patientsPerPageSize,
    });
  };

  return (
    templates && (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {Array.isArray(templates) &&
            templates
              .slice(pagination.minIndex, pagination.maxIndex)
              .map((item, i) => {
                return (
                  <Col key={i} lg={6} md={12} sm={12} xs={24}>
                    <ExerciseTemplateCard
                      template={item}
                      handleCardClick={() => handleCardClick(item, i)}
                    />
                  </Col>
                );
              })}
        </Row>
        <div className="pagination-container align-right">
          <Pagination
            pageSize={pagination.patientsPerPageSize}
            current={pagination.patientsCurrentPage}
            total={templates.length}
            onChange={handlePagination}
            showSizeChanger={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            style={{ bottom: '0px' }}
          />
        </div>
      </React.Fragment>
    )
  );
};

export default ExerciseTemplateGrid;
