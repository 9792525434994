/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Button, Input, List, Modal, Typography } from 'antd';
import { SearchOutlined, SendOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import v from 'voca';

import states from '../../../states';
import services from '../../../services';

const isIncluded = (list, key, value) => list.some(item => item[key] === value);

const NewBoardModal = ({
  visibleProfile,
  open,
  loading,
  sub,
  onClose,
  onAddNewBoard
}) => {
  const messages = useRecoilValue(states.messages);
  const patients = useRecoilValue(states.patients);

  const [otherPatients, setOtherPatients] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (messages.list) {
      setOtherPatients(
        patients.list.filter(
          p => !messages.list.some(item => item.Owner === p.sub)
        )
      );
    }
  }, [messages.list]);

  const updateFilter = useCallback(
    debounce((updatedFilter, otherPatients, messages, services, visibleProfile,setFilteredList) => {
      if (updatedFilter) {
        const filteredList = otherPatients.filter(
          (item) =>
            item.firstName?.startsWith(updatedFilter) ||
            item.lastName?.startsWith(updatedFilter) ||
            item.emailAddress?.startsWith(updatedFilter)
        );

        const { GroupId } = visibleProfile;
        services.patients.getPatients(GroupId, updatedFilter).then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            const data = res.data[i];
            const inMessages = isIncluded(messages.list, "Owner", data.Sub);
            const inPatients = isIncluded(otherPatients, "Sub", data.Sub);

            if (!inMessages && !inPatients) {
              filteredList.push(data);
            }
          }
        });

        setFilteredList(filteredList);
      }
    }, 500),
    []
  );

  useEffect(() => {
    updateFilter(filter, otherPatients, messages, services, visibleProfile,setFilteredList);
  }, [filter]);

  return (
    <Modal
      closable
      footer={null}
      visible={open}
      bodyStyle={{ height: 512, padding: 0 }}
      title={
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          Add Converstation
        </Typography.Title>
      }
      onCancel={() => {
        setFilter('');
        setFilteredList([]);

        onClose();
      }}
    >
      <div className="new-msg-modal-input">
        <Input
          placeholder="Search patients.."
          value={filter}
          onChange={e => setFilter(e.target.value)}
          prefix={<SearchOutlined />}
        />
      </div>

      <div className="new-msg-modal-content">
        <List
          dataSource={filter ? filteredList : otherPatients}
          renderItem={(item, i) => {
            const fname = item.firstName || item.FirstName;
            const lname = item.lastName || item.LastName;
            const email = item.emailAddress || item.EmailAddress;
            const phone = item.phoneNumber;

            return (
              <List.Item key={i}>
                <List.Item.Meta
                  title={`${v.capitalize(fname.trim())} ${v.capitalize(
                    lname.trim()
                  )}`}
                  description={email || phone}
                />

                <Button
                  type="link"
                  size="large"
                  icon={<SendOutlined />}
                  loading={loading && sub === item.sub}
                  disabled={loading && sub !== item.sub}
                  onClick={() => onAddNewBoard(item)}
                />
              </List.Item>
            );
          }}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile
});

export default withRouter(connect(mapStateToProps)(NewBoardModal));
