import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Dropdown, Menu, Button, notification } from 'antd';
import {
  CaretDownOutlined,
  GlobalOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import _ from 'lodash';

import { ImageSelector } from './file-uploader';
import { uploadImageDirect } from './file-uploader/actions';
import { updateCornerPhoto } from '../features/users/actions/users';
import { imageValidator } from '../features/groups/validators/groupExercises';
import { actions as idleActions } from '../features/idle-monitor';

import defaultLogo from '../img/default-logo.png';
import ProfileImage from '../features/patients/Modals/ProfileImage';
import DispatchLogoutItem from './DispatchLogoutItem';
import services from '../services';

class DropdownNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgError: false,
      cropping: false,
      dropdownPT: [
        {
          title: 'Patients',
          url: '/patients#patientsTab',
          icon: <UserAddOutlined style={{ fontSize: 18 }} />
        },
        {
          title: 'My Profile',
          url: '/profile',
          icon: <UserOutlined style={{ fontSize: 18 }} />
        }
      ],
      dropdownGA: [
        {
          title: 'Administrator',
          url: '/administrator',
          icon: <UserSwitchOutlined style={{ fontSize: 18 }} />
        },
        {
          title: 'Patients',
          url: '/patients#patientsTab',
          icon: <UserAddOutlined style={{ fontSize: 18 }} />
        },
        {
          title: 'Profile',
          url: '/profile',
          icon: <UserOutlined style={{ fontSize: 18 }} />
        }
      ]
    };
  }

  componentDidMount() {
    this.props.dispatch(idleActions.start());
  }

  renderDropdown() {
    const {
      visibleProfile: { Role }
    } = this.props;
    const { dropdownPT, dropdownGA } = this.state;

    return (
      <Menu className="profile-dropdown">
        {Role === 'pt' &&
          dropdownPT.map((item, i) => (
            <Menu.Item key={i} icon={item.icon}>
              <Link to={item.url}>
                <Typography.Text>{item.title}</Typography.Text>
              </Link>
            </Menu.Item>
          ))}

        {Role === 'ga' &&
          dropdownGA.map((item, i) => (
            <Menu.Item key={i} icon={item.icon}>
              <Link to={item.url}>
                <Typography.Text>{item.title}</Typography.Text>
              </Link>
            </Menu.Item>
          ))}

        <Menu.Item
          icon={
            <QuestionCircleOutlined
              style={{
                fontSize: 18
              }}
            />
          }
        >
          <a
            target="_blank"
            href="https://ptwired.zendesk.com/hc/en-us"
            rel="noopener noreferrer"
          >
            <Typography.Text>Help Center</Typography.Text>
          </a>
        </Menu.Item>

        {/*<Menu.Item*/}
        {/*  icon={*/}
        {/*    <GlobalOutlined*/}
        {/*      style={{*/}
        {/*        fontSize: 18*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*>*/}
        {/*  <a onClick={this.handleOpenLeanworlds}>*/}
        {/*    <Typography.Text>PT Wired Bootcamp</Typography.Text>*/}
        {/*  </a>*/}
        {/*</Menu.Item>*/}

        <Menu.Divider />

        <Menu.Item
          icon={
            <LogoutOutlined
              style={{
                fontSize: 18
              }}
            />
          }
        >
          <DispatchLogoutItem />
        </Menu.Item>
      </Menu>
    );
  }

  handleOpenLeanworlds = async () => {
    const {
      visibleProfile: { EmailAddress, GroupId }
    } = this.props;

    try {
      notification.info({
        duration: 2,
        description: 'Redirecting to PT Wired Bootcamp..'
      });

      await services.leanworlds.getLink(EmailAddress, GroupId).then((res) => {
        if (res.status === 200) {
          window.open(res.data.url, '_blank');
        } else {
          throw new Error(res.data);
        }
      });
    } catch (error) {
      notification.error({
        message: 'Redirect Failed',
        description: 'An error occurred while trying to open PT Wired Bootcamp.'
      });
    }
  };

  setImgError = (bool) => {
    this.setState({ imgError: bool });
  };

  imgClick = () => {
    const { UserInfoComponent } = this;
    UserInfoComponent.click();
  };

  setContext = (item) => {
    this.UserInfoComponent = item;
  };

  getProfileImage = () => {
    const {
      props: {
        visibleProfile: { GroupId, Sub, imgUrl }
      }
    } = this;
    return imgUrl
      ? imgUrl
      : `https://ptwired-exercise-images-prod.s3.amazonaws.com/${GroupId}/${Sub}.jpg`;
  };

  hideCrop = () => {
    this.setState({ cropping: false });
  };

  handleImageCrop = (file, base64) => {
    const {
      props: {
        dispatch,
        visibleProfile: { GroupId, Sub }
      }
    } = this;

    this.setState({ img: base64, cropping: false });
    this.file = null;

    dispatch(
      uploadImageDirect(`${GroupId}/${Sub}.jpg`, file),
      dispatch(updateCornerPhoto(base64))
    );
  };

  handleFileSelected = (x, errors) => {
    const isValid = _.isEmpty(errors);

    if (isValid) {
      this.file = x;
      this.setState({ cropping: true });
    } else {
      console.log('errors', errors);
    }
  };

  handleFileRemoved = (x) => {
    this.removeFile = x;
  };

  onExit = () => {
    this.setState({ img: null });
  };

  closeButton = () => {
    this.setState({ cropping: false });
  };

  render() {
    const {
      hideCrop,
      file,
      handleImageCrop,
      closeButton,
      handleFileSelected,
      handleFileRemoved,
      onExit,
      props: {
        visibleProfile: { Role, imgUrl, FirstName, LastName }
      },
      state: { cropping }
    } = this;

    const img = this.getProfileImage();

    return (
      <React.Fragment>
        {cropping && (
          <ProfileImage
            show={cropping}
            onHide={hideCrop}
            image={file}
            onSubmit={handleImageCrop}
            closeButton={closeButton}
            onExit={onExit}
          />
        )}

        <div className="nav-container">
          {Role === 'pt' || Role === 'ga' ? (
            <Link to="/patients">
              <div className="nav-logo-container">
                <img src={defaultLogo} alt="logo" className="img-responsive" />
              </div>
            </Link>
          ) : (
            <div className="nav-logo-container">
              <img src={defaultLogo} alt="logo" className="img-responsive" />
            </div>
          )}

          <div className="profile-dropdown-container">
            {Role !== 'sa' && (
              <ImageSelector
                ref={(selector) => {
                  this.selector = selector;
                }}
                isUser={true}
                selectorType={'NAV_PROFILE_PHOTO'}
                src={imgUrl ? imgUrl : img}
                imageValidator={imageValidator}
                onFileSelected={handleFileSelected}
                onFileRemoved={handleFileRemoved}
                height={60}
                width={60}
              />
            )}

            <Dropdown
              overlay={this.renderDropdown()}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button type="text" className="profile-dropdown-btn">
                {FirstName} {LastName} <CaretDownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLocation: state.routerReducer.location.pathname,
  visibleProfile: state.visibleProfile,
  groupName: state.publicPage.name,
  design: state.publicPage.design
});

export default withRouter(connect(mapStateToProps)(DropdownNavigation));
