import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isDirty, isValid, isSubmitting, isPristine, change } from 'redux-form';
import { Breadcrumb } from 'react-bootstrap';
import { FULFILLED } from 'redux-promise-middleware';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { success } from 'react-notification-system-redux';
import { FormTypes } from '../groups/actions/groups';
import { usersItemsSelector } from './selectors';
import Loading from '../../components/Loading';
import { Redirect } from 'react-router-dom';
import {
  getUser,
  postUser,
  updateUser,
  isLoading,
  doneLoading,
  setUserFormType,
  updateCurrentUser,
  defaultUser
} from './actions/users';

import { getClinics } from '../groups/actions/clinics';
import UserForm from './UserForm';
import { titleCase } from '../groups/stringHelpers';
import { manualPasswordReset } from '../groups/group-admin/actions';
import { uploadImageDirect } from '../../components/file-uploader/actions';
import { message } from 'antd';
class UserFormPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupClinics: [],
      profile: null,
      submitting: false,
      redirect: false
    };
    this.passwordMessage =
      "The user's existing password will no longer allow them to log in and they will be sent an email with instructions for creating a new one. Proceed?";

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.goToUsersList = this.goToUsersList.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(updateCurrentUser(null));
  }

  componentDidMount() {
    const { dispatch, match, group } = this.props;
    dispatch(getClinics(group.name, group.id));
    dispatch(isLoading());

    if (match.params.id !== 'new') {
      dispatch(getUser(group.name, match.params.id)).then(({ action }) => {
        if (action.type.includes(FULFILLED)) {
          this.setState({ profile: action.payload });
          dispatch(setUserFormType(FormTypes.EDIT));
          dispatch(updateCurrentUser(action.payload));
        } else {
        }
        dispatch(doneLoading());
      });
    } else {
      dispatch(setUserFormType(FormTypes.NEW));
      dispatch(updateCurrentUser(defaultUser));
      this.setState({ profile: defaultUser });
      dispatch(doneLoading());
    }
  }

  handleFileSelected = (x, errors) => {
    const isValid = _.isEmpty(errors);

    this.setState({ validExerciseImage: isValid });
    if (isValid) {
      this.props.dispatch(change('user', 'image', x.name));
      this.file = x;
    }
  };

  handleFileRemoved = x => {
    this.setState({ validExerciseImage: true });
    this.props.dispatch(change('user', 'image', null));
    this.removeFile = x;
  };

  handleChangePassword(admin) {
    const { dispatch } = this.props;

    if (window.confirm(this.passwordMessage)) {
      dispatch(manualPasswordReset(admin.emailAddress)).then(data => {
        if (data.value.message === 'Success') {
          alert("User's password was reset");
        } else {
          alert('There was an unknown problem');
        }
      });
    }
  }

  handleSubmit(values) {
    const {
      goToUsersList,
      props: { dispatch, formType }
    } = this;

    let submitAction;
    this.setState({ submitting: true });

    let activityEmail = true;

    const serverPayload = { ...values };

    if (formType === FormTypes.NEW) {
      submitAction = postUser(serverPayload.GroupId, serverPayload);
    } else {
      dispatch(change('user', 'active', values.active));
      submitAction = updateUser(
        serverPayload.GroupId,
        serverPayload,
        activityEmail
      );
    }

    return dispatch(submitAction)
      .then(({ action }) => {
        if (action.payload.message === 'Success') {
          dispatch(updateCurrentUser(values));

          if (formType === FormTypes.NEW) {
            this.props.dispatch(
              success({
                title: 'User created!',
                message: 'An activation email has been sent to the user.',
                position: 'tr'
              })
            );
          } else {
            this.props.dispatch(
              success({ title: 'Changes saved!', position: 'tr' })
            );
          }
          this.setState({ submitting: false });
          goToUsersList();
        } else {
          this.setState({ submitting: false });
          message.warn(
            'Error Saving. Please look over the form data. If the issue persists, please contact customer service.'
          );
        }
      })
      .catch(err => {
        message.warn(
          'Error Saving. Please look over the form data. If the issue persists, please contact customer service.'
        );
      });
  }

  goToUsersList() {
    this.setState({ redirect: true });
  }

  render() {
    const {
      handleSubmit,
      state: { profile, submitting, redirect },
      props: { formType, currentUser, isLoading, group, dispatch, clinics }
    } = this;

    Object.keys(currentUser).forEach(val => {
      if (val === 'firstName' || val === 'lastName') {
        if (!currentUser[val]) {
          return;
        }
        currentUser[val] = titleCase(currentUser[val]);
      }
    });

    if (profile && profile.Active === true) {
      profile.Active = 'true';
    } else if (profile && profile.Active === false) {
      profile.Active = 'false';
    }

    return (
      <Loading isLoading={isLoading}>
        {redirect && <Redirect to={'/users'} />}
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={'/administrator'}>Admin</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={'/users'}>Providers</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {formType === FormTypes.NEW ? 'Add' : 'Edit'}
          </Breadcrumb.Item>
        </Breadcrumb>
        {profile && (
          <UserForm
            onChangePassword={this.handleChangePassword}
            onSubmit={handleSubmit}
            initialValues={profile}
            group={group}
            formType={formType}
            groupClinics={clinics}
            onFileSelected={this.handleFileSelected}
            onFileRemoved={this.handleFileRemoved}
            submitting={submitting}
            visibleProfile={this.props.visibleProfile}
            uploadImage={async file => {
              await dispatch(
                uploadImageDirect(
                  `${currentUser.GroupId}/${currentUser.Sub}.jpg`,
                  file
                )
              );
            }}
          />
        )}
      </Loading>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    visibleProfile: state.visibleProfile,
    userFromRoute: usersItemsSelector(state)[ownProps.match.params.id],
    currentUser: state.users.currentUser,
    formType: state.users.formType,
    isLoading: state.users.isLoading,
    isFormValid: isValid('user')(state),
    isFormDirty: isDirty('user')(state),
    isFormSubmitting: isSubmitting('user')(state),
    isFormPristine: isPristine('user')(state),
    modalOpen: state.users.modalOpen,
    clinics: state.clinics.items
  };
}

export default connect(mapStateToProps)(withRouter(UserFormPage));
