import React, { Component } from 'react';
import V from 'voca';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';
import SimpleSlider from '../../../patients/Slider';
import '../../../../styles/atomic.css';
import {
  clearCurrentMonitoredPatient,
  exitCurrentMonitoredPatient,
  setCurrentMonitoredPatient
} from '../../../monitor-timer/actions';
import { delay } from '../../../patients/RTMDashboard/components/PatientDetailDrawer/mixins';
import { getRecoil } from 'recoil-nexus';
import states from '../../../../states';
import services from '../../../../services';

const getTime = (date) => new Date(date).getTime();
const formatDate = (date) => moment(date).format('MM/DD/YYYY');

class AdherenceLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepsCount: [],
      painScale: []
    };
  }

  async componentDidMount() {
    const {
      patient,
      visibleProfile: { GroupInfo }
    } = this.props;

    if (patient) {
      await this.getPoints();

      if (!!GroupInfo.EnableRTM && !!patient.EnableRTM) {
        await this.setMonitoring();
      }
    }
  }

  async componentWillUnmount() {
    await this.exitMonitoring();
  }

  async componentDidUpdate(prevProps) {
    const {
      dispatch,
      patient,
      visibleProfile: {
        EmailAddress,
        GroupInfo: { Name, EnableRTM }
      },
      currentWeek,
      currTab
    } = this.props;

    if (prevProps.currTab !== currTab) {
      if (currTab !== 'adherenceLog') {
        await this.exitMonitoring();
      } else {
        await this.setMonitoring();
      }
    }

    const prevWeek = JSON.stringify(prevProps.currentWeek);
    const currWeek = JSON.stringify(currentWeek);

    if (prevWeek !== currWeek) {
      await this.getPoints();
    }

    // CHECKS IF PATIENT OBJECT UPDATES
    // FOR SETTING AND CLEARING CURRENT MONITORED PATIENT
    // DEPENDS IF ENABLE RTM IS TRUE OR FALSE
    if (JSON.stringify(prevProps.patient) !== JSON.stringify(patient)) {
      if (EnableRTM && patient && patient.EnableRTM) {
        dispatch(
          setCurrentMonitoredPatient({
            patient,
            activity: 'View Adherence Log',
            groupName: Name,
            provider: EmailAddress
          })
        );
      } else {
        dispatch(clearCurrentMonitoredPatient());
      }
    }
  }

  setMonitoring = async () => {
    const {
      dispatch,
      patient,
      visibleProfile: {
        EmailAddress,
        GroupInfo: { Name }
      },
      currentMonitoredPatient,
      isMonitoring
    } = this.props;

    if (currentMonitoredPatient && isMonitoring) {
      await dispatch(exitCurrentMonitoredPatient());
      await delay(1500);
    }

    await dispatch(
      setCurrentMonitoredPatient({
        patient,
        activity: 'View Adherence Log',
        groupName: Name,
        provider: EmailAddress
      })
    );
  };

  exitMonitoring = async () => {
    const {
      dispatch,
      patient,
      visibleProfile: {
        GroupInfo: { EnableRTM }
      },
      currentMonitoredPatient
    } = this.props;

    if (EnableRTM && patient && patient.EnableRTM) {
      if (currentMonitoredPatient) {
        await dispatch(exitCurrentMonitoredPatient());
      }
    }
  };

  getPoints = async () => {
    const { patient, currentWeek, visibleProfile } = this.props;

    try {
      const hours = 23 * 60 * 60 * 1000;
      const minutes = 59 * 60 * 1000;
      const seconds = 59 * 1000;

      const query = {
        start: getTime(currentWeek[0].date),
        end: getTime(currentWeek[6].date) + hours + minutes + seconds
      };

      const { GroupId } = visibleProfile;
      const response = await services.rtm.getDatapoints(
        GroupId,
        patient.Sub,
        query
      );

      if (response.status === 200) {
        const { patientDataPoints, painScale } = response.data;

        if (patientDataPoints.length > 0) {
          const steps = [];
          patientDataPoints.forEach((item) => {
            const { Datapoints } = item;
            Datapoints.forEach((dp) => {
              if (dp.Event === 'STEPS') {
                steps.push({
                  date: dp.Time,
                  value: dp.Count
                });
              }
            });
          });

          this.setState({
            stepsCount: steps,
            painScale
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  renderRxTracker = (rx) => {
    if (!rx) {
      return null;
    }

    const { exercises } = rx;
    if (!exercises) {
      return null;
    }

    return exercises.map((exercise, i) => (
      <tr key={i} className="ant-table-row">
        <td
          className={`ant-table-cell fWeight ${exercise.deleted && 'grayed'}`}
        >
          {V.titleCase(exercise.exerciseName)}
        </td>
        {this.props.currentWeek.map((day) => {
          let completionDates;

          if (
            exercise.completionDates &&
            Array.isArray(exercise.completionDates)
          ) {
            completionDates = exercise.completionDates.map((cDate) =>
              String(moment(cDate).format('MM/DD/YYYY'))
            );
          } else {
            completionDates = exercise.completionDates;
          }

          return completionDates &&
            Array.isArray(exercise.completionDates) &&
            _.indexOf(completionDates, String(day.date)) >= 0 ? (
            <td key={day.date} className="ant-table-cell cell-height-50">
              <i className="fa fa-check-circle fa-2x" aria-hidden="true" />
            </td>
          ) : (
            <td key={day.date} className="ant-table-cell cell-height-50" />
          );
        })}
      </tr>
    ));
  };

  render() {
    const { currentWeek } = this.props;
    const { stepsCount, painScale } = this.state;

    const prescription = getRecoil(states.prescription);
    const { form } = prescription;

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col lg={16} md={16} sm={24} xs={24} />
          <Col lg={8} md={8} sm={24} xs={24}>
            <SimpleSlider />
          </Col>
        </Row>
        <div className="ant-table ant-table-bordered  ant-table-ping-right ant-table-fixed-header ant-table-fixed-column ant-table-scroll-horizontal ant-table-has-fix-left">
          <div className="ant-table-container">
            <table style={{ tableLayout: 'auto' }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">{''}</th>
                  {currentWeek.map((e, i) => (
                    <th
                      key={i}
                      className={`ant-table-cell ${
                        moment().format('MM/DD/YYYY') < e.date ? 'grayed' : ''
                      }`}
                    >
                      {e.dayS}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {this.renderRxTracker(form.prescription || [])}

                <tr className="ant-table-row">
                  <td className="ant-table-cell fWeight">
                    {V.titleCase('Steps Taken')}
                  </td>
                  {currentWeek.map((day, i) => {
                    const step = stepsCount.find(
                      (item) => formatDate(item.date) === day.date
                    );

                    return (
                      <td key={i}>
                        <strong>{step?.value || ''}</strong>
                      </td>
                    );
                  })}
                </tr>

                <tr className="ant-table-row">
                  <td className="ant-table-cell fWeight">
                    {V.titleCase('Daily Outcome Score')}
                  </td>

                  {currentWeek.map((day, i) => {
                    const scale = painScale.find(
                      (item) => formatDate(item.Date) === day.date
                    );

                    return (
                      <td key={i}>
                        <strong>{scale?.Value || ''}</strong>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentWeek: state.patients.currentWeek,
    week: state.patients.weekChange,
    visibleProfile: state.visibleProfile,
    patient: state.patients.currentPatient,
    currentMonitoredPatient: state.monitorTimer.currentMonitoredPatient,
    isMonitoring: state.monitorTimer.isMonitoring
  };
}

export default connect(mapStateToProps)(withRouter(AdherenceLog));
