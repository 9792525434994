import React, { Component } from 'react';
import {
  isDirty,
  isValid,
  isPristine,
  registerField,
  change,
  reset
} from 'redux-form';
import {
  Breadcrumb,
  Button,
  Input,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import voca from 'voca';
import {
  closeGroupExercisesFormModal as closeFormModal,
  getGroupExercises,
  setGroupExerciseFormType,
  defaultGroupExercise,
  postGroupExercise,
  updateGroupExercise,
  deleteGroupExercise
} from '../actions/groupExercises';
import {
  uploadImageDirect,
  removeExerciseImage
} from '../../../components/file-uploader/actions';
import { doneLoading, isLoading } from '../actions/groupExercisesLoadingState';
import { FormTypes } from '../actions/groups';
import { titleCase } from '../stringHelpers';
import { success } from 'react-notification-system-redux';
import { updateCurrentExercise } from '../../exercise-library/actions/exercisesLibrary';
import DispatchLinkItem from '../../../components/DispatchLinkItem';
import LoadingPage from '../../../components/LoadingPage';
import ClinicExerciseForm from './ClinicExerciseForm';

class GroupExercisesPage extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      loading: false,
      pending: null,
      validExerciseImage: true,
      isSubmitting: false,
      searchTerm: null,
      formModalOpen: false,
      selectedExercise: null,
      method: null,
      disable: true,
      img: null,
      cropping: false,
      deleteLoading: false
    };

    this.leaveConfirmMessage =
      'You currently have unsaved changes. Are you sure you want to discard your changes?';
    this.disableConfirmMessage =
      'Are you sure you want to inactivate this Exercise?';

    this.openEditFormModal = this.openEditFormModal.bind(this);
    this.openNewFormModal = this.openNewFormModal.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handleFileRemoved = this.handleFileRemoved.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      visibleProfile: { GroupId }
    } = this.props;
    dispatch(isLoading);
    dispatch(getGroupExercises(GroupId)).then(
      () => dispatch(doneLoading),
      () => dispatch(doneLoading)
    );
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(updateCurrentExercise({}));
  }

  handleCloseForm() {
    const {
      leaveConfirmMessage,
      props: { isFormDirty, dispatch }
    } = this;
    if (isFormDirty) {
      if (window.confirm(leaveConfirmMessage)) {
        dispatch(closeFormModal);
      }
    } else {
      dispatch(closeFormModal);
    }
  }

  handleSearchInputChange(event) {
    const searchTerm = event.target.value;
    this.setState({ searchTerm: searchTerm });
  }

  handleSubmit(values) {
    const {
      dispatch,
      visibleProfile: { GroupId }
    } = this.props;
    const {
      selectedExercise: { Id },
      method
    } = this.state;
    this.setState({ disable: true });
    values.exerciseName = null;
    values.active = null;
    values.Active = true;
    values.image = null;

    this.setState({ loading: true, isSubmitting: true });

    _.each(values, (val, key) => {
      if (typeof val === 'string') values[key] = val.trim();
    });

    let submitAction;
    const file = this.file;

    if (method === 'POST') {
      values.imageUrl = null;
      submitAction = postGroupExercise(GroupId, {
        ...values,
        GroupId: GroupId
      });
    } else {
      submitAction = updateGroupExercise(GroupId, {
        ...values,
        Id: Id,
        GroupId: GroupId
      });
    }
    /**
     * Update Data in DB
     */
    return dispatch(submitAction)
      .then(({ action }) => {
        const { id, message } = action.payload;
        /**
         * Successful Update
         */
        if (id || message === 'Success') {
          /**
           * Removing Image
           */
          if (this.removeFile) {
            this.file = null;
            return dispatch(removeExerciseImage(GroupId, id)).then(() => {
              dispatch(reset('exercise'));
              dispatch(closeFormModal);
              this.setState({
                loading: false,
                isSubmitting: false,
                formModalOpen: false,
                img: null
              });
              dispatch(
                success({
                  title: 'Success!',
                  message: 'Exercise Add/Edit completed!',
                  position: 'tr'
                })
              );
              return dispatch(getGroupExercises(GroupId));
            });
            /**
             * Image to update
             */
          } else if (file) {
            return dispatch(uploadImageDirect(`${GroupId}/${id}.jpg`, file))
              .then((x) => {
                this.file = null;
                dispatch(reset('exercise'));
                dispatch(closeFormModal);
                this.setState({
                  loading: false,
                  isSubmitting: false,
                  formModalOpen: false,
                  img: null
                });
                dispatch(
                  success({
                    title: 'Success!',
                    message: 'Exercise Add/Edit completed!',
                    position: 'tr'
                  })
                );
                return dispatch(getGroupExercises(GroupId));
              })
              .catch((err) => {
                console.log(err);
                alert('Error Uploading Image');
              });
          }
          dispatch(reset('exercise'));
          this.setState({
            loading: false,
            isSubmitting: false,
            formModalOpen: false
          });
          this.props.dispatch(
            success({
              title: 'Success!',
              message: 'Exercise Add/Edit completed!',
              position: 'tr'
            })
          );
          return dispatch(getGroupExercises(GroupId));
          /**
           * Successful Add
           */
        } else {
          if (file) {
            return dispatch(uploadImageDirect(`${GroupId}/${id}.jpg`, file))
              .then((x) => {
                this.file = null;
                dispatch(reset('exercise'));
                dispatch(closeFormModal);
                this.setState({
                  loading: false,
                  isSubmitting: false,
                  formModalOpen: false,
                  img: null
                });
                this.props.dispatch(
                  success({
                    title: 'Success!',
                    message: 'Exercise Add/Edit completed!',
                    position: 'tr'
                  })
                );
                return dispatch(getGroupExercises(GroupId));
              })
              .catch((err) => {
                console.log(err);
                alert('Error Uploading Image');
              });
          } else {
            dispatch(reset('exercise'));
            this.setState({
              loading: false,
              isSubmitting: false,
              formModalOpen: false
            });
            this.props.dispatch(
              success({
                title: 'Success!',
                message: 'Exercise Add/Edit completed!',
                position: 'tr'
              })
            );
            return dispatch(getGroupExercises(GroupId));
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Error Uploading Exercise');
      });
  }

  openNewFormModal() {
    const { dispatch } = this.props;
    dispatch(setGroupExerciseFormType(FormTypes.NEW));
    this.setState({
      selectedExercise: defaultGroupExercise,
      formModalOpen: true,
      method: 'POST'
    });
    this.setState({ validExerciseImage: true });
    dispatch(registerField('exercise', 'image', 'Field'));
  }

  openEditFormModal(exercise) {
    const { dispatch } = this.props;

    dispatch(setGroupExerciseFormType(FormTypes.EDIT));
    this.setState({
      selectedExercise: exercise,
      formModalOpen: true,
      method: 'PUT'
    });
    this.setState({ validExerciseImage: true });
    dispatch(registerField('exercise', 'image', 'Field'));
  }

  handleDismount = () => {
    this.file = null;
    this.props.dispatch(change('exercise', 'image', null));
  };

  handleFileSelected(x, errors) {
    const isValid = _.isEmpty(errors);

    this.setState({ validExerciseImage: isValid });
    if (isValid) {
      this.props.dispatch(change('exercise', 'image', x.name));
      this.file = x;
      this.setState({ cropping: true });
    }
  }

  handleFileRemoved(x) {
    this.setState({ validExerciseImage: true });
    this.props.dispatch(change('exercise', 'image', null));
    this.removeFile = x;
  }

  filterExercises = (groupExercises) => {
    if (!groupExercises) {
      return;
    }

    const { searchTerm } = this.state;

    if (!searchTerm) {
      return groupExercises;
    }

    const filteredExercises = [];

    for (let i = 0; i < groupExercises.length; i++) {
      const exercise = groupExercises[i];

      const { ExerciseName, Tag } = exercise;

      let push = false;

      if (typeof ExerciseName === 'string') {
        if (
          ExerciseName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        ) {
          push = true;
        }
      }

      if (typeof Tag === 'string') {
        if (Tag.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
          push = true;
        }
      }

      if (push === true) {
        filteredExercises.push(exercise);
      }
    }
    return filteredExercises;
  };

  handleImageCrop = (file, base64) => {
    this.setState({ img: base64, cropping: false });
    this.file = file;
  };

  hideCrop = () => {
    this.setState({ cropping: false, img: null });
    this.file = null;
  };

  onExit = () => {
    this.setState({ cropping: false, img: null });
    this.file = null;
  };

  deleteAction = async (exerciseId) => {
    const {
      dispatch,
      visibleProfile: { GroupId }
    } = this.props;

    this.setState({
      deleteLoading: true
    });

    await dispatch(deleteGroupExercise(GroupId, exerciseId)).then(
      async (res) => {
        if (res.value === 'Success') {
          await dispatch(getGroupExercises(GroupId));
          dispatch(
            success({
              title: 'Exercise has been deleted!',
              position: 'tr'
            })
          );
        }
      }
    );

    this.setState({
      deleteLoading: false
    });
  };

  render() {
    const {
      openEditFormModal,
      openNewFormModal,
      deleteAction,
      props: { groupExercises, currentGroupExercise, isLoading },
      state: { formModalOpen, selectedExercise, searchTerm, deleteLoading }
    } = this;

    let content;

    Object.keys(currentGroupExercise).forEach((val) => {
      if (val === 'ExerciseName' || val === 'Tag') {
        currentGroupExercise[val] = titleCase(currentGroupExercise[val]);
      }
      if (val === 'Instructions') {
        currentGroupExercise[val] = voca.capitalize(currentGroupExercise[val]);
      }
    });

    const filteredExercises = this.filterExercises(groupExercises);

    content = (
      <Table
        columns={[
          {
            title: 'Exercise Name',
            dataIndex: 'ExerciseName',
            key: 'ExerciseName',
            render: (name) => titleCase(name)
          },
          {
            title: 'Tag',
            dataIndex: 'Tag',
            key: 'Tag',
            render: (item) => {
              if (!item || !item.replace(/\s/g, '').length) {
                return <Typography.Text type="secondary">None</Typography.Text>;
              }

              return (
                <Tag color="green" key={item}>
                  {item}
                </Tag>
              );
            }
          },
          {
            title: 'Date Created',
            dataIndex: 'Created',
            key: 'Created',
            render: (date) => new Date(date).toLocaleString()
          },
          {
            title: 'Actions',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (_, item) => (
              <Space size="middle">
                <Tooltip title="Edit Exercise">
                  <Button
                    icon={<EditOutlined />}
                    size="large"
                    shape="circle"
                    onClick={() => openEditFormModal(item)}
                    disabled={deleteLoading}
                  />
                </Tooltip>
                <Popconfirm
                  title="Are you sure? Deleting exercises cannot be undone."
                  onConfirm={() => deleteAction(item.Id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Delete Exercise">
                    <Button
                      icon={<DeleteOutlined />}
                      size="large"
                      shape="circle"
                      disabled={deleteLoading}
                    />
                  </Tooltip>
                </Popconfirm>
              </Space>
            )
          }
        ]}
        dataSource={Array.isArray(filteredExercises) && [...filteredExercises]}
      />
    );

    return (
      <React.Fragment>
        <ClinicExerciseForm
          values={selectedExercise}
          isOpen={formModalOpen}
          onCancel={() => {
            this.setState({
              formModalOpen: false
            });
          }}
        />

        <div className="tab-header">
          <Breadcrumb>
            <DispatchLinkItem title="Admin&nbsp;" url="/administrator" />
            <Breadcrumb.Item active>Clinic Exercise Library</Breadcrumb.Item>
          </Breadcrumb>
          <div className="tab-header-controls">
            <Button
              type="primary"
              className="btn-primary ptw-btn"
              onClick={openNewFormModal}
            >
              <PlusOutlined /> Add Exercise
            </Button>
            <Input
              size="middle"
              placeholder="Search exercise name or tag"
              prefix={<SearchOutlined />}
              onChange={this.handleSearchInputChange}
              value={searchTerm && searchTerm}
            />
          </div>
        </div>
        {isLoading ? (
          <LoadingPage
            type="list"
            content="Loading exercises, please wait..."
          />
        ) : (
          content
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    visibleProfile: state.visibleProfile,
    groupExercises: state.groupExercises.items,
    isFetching: state.groupExercises.isFetching,
    fetched: state.groupExercises.fetched,
    formType: state.groupExercises.formType,
    currentGroupExercise: state.groupExercises.currentGroupExercise,
    isFormValid: isValid('exercise')(state),
    isFormDirty: isDirty('exercise')(state),
    isFormPristine: isPristine('exercise')(state),
    isLoading: state.groupExercises.isLoading,
    isSearching: state.groupExercises.isSearching,
    formParamsValid: state.form.exercise && state.form.exercise.validParams
  };
}

export default connect(mapStateToProps)(GroupExercisesPage);
