import React, { useEffect, useState } from 'react';
import { Button, Space, Typography } from 'antd';
import { useRecoilValue } from 'recoil';
import { compare } from 'compare-versions';
import { CloseOutlined } from '@ant-design/icons';

import Linkify from 'linkify-react';
import version from '../version';
import states from '../../states';
import services from '../../services';

const STORAGE_KEY = 'ptw-release';

const getVersionNumber = version => {
  let versionStr = version;

  let keywords = {
    v1: 'Version',
    v2: 'Version ',
    v3: 'Version:',
    v4: 'Version: ',
    v5: 'v',
    colon: ':',
  };
  let pattern = new RegExp(Object.values(keywords).join('|'), 'g');

  versionStr = versionStr.replace(pattern, '').trim();
  return versionStr;
};

const AnnouncementPopup = () => {
  const [visible, setVisible] = useState(false);
  const [announcement, setAnnouncement] = useState(null);

  const admin = useRecoilValue(states.admin);

  useEffect(() => {
    checkUpdate();
  }, []);

  useEffect(() => {
    if (admin.announcement) {
      setVisible(true);
      setAnnouncement(admin.announcement);
    }
  }, [admin.announcement]);

  const checkUpdate = async () => {
    try {
      const response = await services.admin.getAnnouncement().then((res) => {
        return [...(res?.data?.announcements || [])][0];
      });

      if (response) {
        const releaseVersion = getVersionNumber(response.Version);
        const localStore = localStorage.getItem(STORAGE_KEY);

        if (compare(releaseVersion, version, '>=')) {
          let display = false;
          if (localStore) {
            display = compare(releaseVersion, localStore, '>');
          } else {
            display = true;
          }

          setVisible(display);
          setAnnouncement(response);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleClosePopup = () => {
    const value = getVersionNumber(announcement.Version);

    localStorage.setItem(STORAGE_KEY, value);
    setVisible(false);
  };

  const handleReloadPage = () => {
    const value = getVersionNumber(announcement.Version);

    localStorage.setItem(STORAGE_KEY, value);
    window.location.reload();
  };

  if (!visible || !announcement) {
    return null;
  }

  return (
    <div className="broadcast-popup">
      <div>
        <div className="broadcast-popup__header">
          <Button
            shape="circle"
            type="text"
            icon={
              <CloseOutlined
                style={{
                  fontSize: 20,
                }}
              />
            }
            onClick={handleClosePopup}
          />
        </div>
      </div>

      <div className="broadcast-popup__content">
        <Space direction="vertical" size={12}>
          {announcement?.Image && (
            <div className="broadcast-popup__img-wrapper">
              <img
                src={announcement?.Image}
                className="broadcast-popup__img"
                alt="Broadcast Popup"
              />
            </div>
          )}

          <Space
            size={0}
            direction="vertical"
            className="broadcast-popup__message"
          >
            <Typography.Title
              level={5}
              style={{
                marginBottom: 0,
              }}
            >
              {announcement?.Title}
            </Typography.Title>

            <Typography.Text
              style={{
                whiteSpace: 'break-spaces',
              }}
            >
              <Linkify
                options={{
                  attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  },
                }}
              >
                {announcement?.Message}
              </Linkify>
            </Typography.Text>
          </Space>

          <Space
            style={{
              display: 'flex',
              justifyContent: 'stretch',
              alignItems: 'stretch',
            }}
            direction="vertical"
          >
            {announcement?.ReleaseNotes && (
              <Button
                size="large"
                type="primary"
                target="_blank"
                className="ptw-btn btn-primary full-width"
                href={announcement?.ReleaseNotes}
              >
                Release Notes
              </Button>
            )}

            <Button
              size="large"
              type="primary"
              className="ptw-btn btn-primary full-width"
              onClick={handleReloadPage}
            >
              Update Now to {announcement?.Version}
            </Button>
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default AnnouncementPopup;
