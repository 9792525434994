import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Dropdown,
  Menu,
  Checkbox,
  Tooltip,
  Button,
  Input,
  Typography,
} from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValue } from 'recoil';

import states from '../../../states';
import ExerciseTemplateGrid from '../../../features/patients/ExerciseTemplates/ExerciseTemplateGrid';

const filterTypeToText = type => {
  switch (type) {
    case 'GROUP_TEMPLATES':
      return 'Group Templates';
    case 'MY_TEMPLATES':
      return 'My Templates';
    default:
      return '';
  }
};

const AddTemplateModal = ({ addTemplateToSelectionPanel, visibleProfile }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState(['MY_TEMPLATES']);

  const [modals, setModals] = useRecoilState(states.modal);
  const templates = useRecoilValue(states.templates);

  const handleAddTemplateModal = () => {
    setModals(prevState => ({
      ...prevState,
      addTemplate: !prevState.addTemplate,
    }));
  };

  /**
   * Filter Templates
   */
  const selectFilter = type => {
    if (filter.includes(type)) {
      setFilter(prevFilter => prevFilter.filter(item => item !== type));
    } else {
      setFilter(prevFilter => [...prevFilter, type]);
    }
  };

  const handleSearch = e => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const sortTemplate = templates => {
    return templates
      .map(item => item)
      .sort((a, b) => (a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1));
  };

  const filterTemplate = cleanedTemplateList => {
    let filteredTemplateList = cleanedTemplateList;

    if (filter.includes('GROUP_TEMPLATES')) {
      filteredTemplateList = filteredTemplateList.filter(
        item => item.GroupTemplate
      );
    }

    if (filter.includes('MY_TEMPLATES')) {
      const { EmailAddress } = visibleProfile;

      filteredTemplateList = filteredTemplateList.filter(
        item => item.Author === EmailAddress
      );
    }

    return filteredTemplateList;
  };

  const searchTemplate = templates =>
    templates.filter(item => {
      const term = searchTerm.toLowerCase();
      const author = item.Author.toLowerCase();
      const name = item.Name.toLowerCase();

      if (!term) {
        return true;
      }

      return author.includes(term) || name.includes(term);
    });

  let cleanedTemplateList = searchTemplate(templates.list);
  cleanedTemplateList = filterTemplate(cleanedTemplateList);
  cleanedTemplateList = sortTemplate(cleanedTemplateList);

  return (
    <Modal
      title="Add Template to Prescription"
      open={modals.addTemplate}
      onCancel={handleAddTemplateModal}
      footer={null}
      width={1200}
    >
      <div className="tab-header">
        <div />
        <div className="tab-header-controls">
          <Input
            autoFocus
            size="middle"
            placeholder="Search template or author"
            value={searchTerm}
            prefix={<SearchOutlined />}
            onChange={handleSearch}
          />
          <Tooltip title="Filter Templates">
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => null}>
                    <Checkbox
                      onChange={() => selectFilter('GROUP_TEMPLATES')}
                      checked={filter.includes('GROUP_TEMPLATES')}
                    >
                      Group Templates
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item onClick={() => null}>
                    <Checkbox
                      onChange={() => selectFilter('MY_TEMPLATES')}
                      checked={filter.includes('MY_TEMPLATES')}
                    >
                      My Templates
                    </Checkbox>
                  </Menu.Item>
                </Menu>
              }
              onOpenChange={e => setFilterOpen(e)}
              open={filterOpen}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                type={filter.length < 1 ? 'default' : 'primary'}
                shape="circle"
              >
                <FilterOutlined style={{ fontSize: 16 }} />
              </Button>
            </Dropdown>
          </Tooltip>
        </div>
      </div>
      {!!filter.length && (
        <div style={{ marginBottom: 20 }}>
          <Typography.Text style={{ fontSize: 16 }} level={5}>
            Filtered by -{' '}
            <Typography.Link onClick={() => setFilterOpen(!filterOpen)}>
              {filter.map(item => filterTypeToText(item)).join(', ')}{' '}
            </Typography.Link>
          </Typography.Text>
        </div>
      )}
      <ExerciseTemplateGrid
        templates={cleanedTemplateList}
        handleCardClick={addTemplateToSelectionPanel}
      />
    </Modal>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
});

export default connect(mapStateToProps)(AddTemplateModal);
