import React, { Fragment } from 'react';
import { Card, Button, Table, Skeleton, Typography, Tag, Row } from 'antd';
import { ExpandOutlined, RightOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import * as reportsUtils from '../../../utils/reports.utils';
import states from '../../../states';
import moment from 'moment';
import BarChart from '../../../components/BarChart';
import StackedBarChart from '../../../components/StackedBarChart';

const PreviewCard = ({ type, handleExpand, loading }) => {
  const reports = useRecoilValue(states.reports);

  const title =
    type === 'BILLED'
      ? 'RTM Billed Codes'
      : type === 'BILLERS'
      ? 'RTM Codes Billed by Providers'
      : type === 'CODES'
      ? 'RTM Codes Billed per Patient'
      : type === 'PATIENTS'
      ? 'RTM Patients'
      : 'RTM Service Time by Provider';

  return (
    <Card
      title={title}
      extra={[
        <Button type="primary" onClick={handleExpand} disabled={loading}>
          Expand <ExpandOutlined />
        </Button>
      ]}
      style={{ height: '100%' }}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <Fragment>
          {type === 'BILLED' && (
            <PreviewBilled loading={loading} data={reports.rtm.billed} />
          )}
          {type === 'BILLERS' && (
            <PreviewBillers loading={loading} data={reports.rtm.billers} />
          )}
          {type === 'CODES' && (
            <PreviewCodes loading={loading} data={reports.rtm.codes} />
          )}
          {type === 'PATIENTS' && (
            <PreviewPatients loading={loading} data={reports.rtm.patients} />
          )}
          {type === 'SERVICE_LOGS' && (
            <PreviewServiceLogs
              data={reports.rtm.serviceLogs}
              loading={loading}
            />
          )}
          <Row style={{ marginTop: 24 }} align="center" justify="center">
            <Button type="primary" onClick={handleExpand} disabled={loading}>
              Load more <RightOutlined />
            </Button>
          </Row>
        </Fragment>
      )}
    </Card>
  );
};

const PreviewBilled = ({ data }) => {
  if (!data) return null;

  const labels = ['Last 30 days'];
  const datasets =
    Array.isArray(data) &&
    data
      .map(item => ({
        label: item.Code,
        data: labels.map(() => item.TotalCount),
        fill: false,
        axis: 'y'
      }))
      .sort((b, a) => a.data[0] - b.data[0]);

  return (
    <Fragment>
      <BarChart
        chartTitle="RTM Billed Codes (Last 30 days)"
        data={{ labels, datasets }}
      />
    </Fragment>
  );
};

const PreviewBillers = ({ data }) => {
  if (!data) return null;

  const report = reportsUtils.generateRtmBillers(data);

  return (
    <Fragment>
      <StackedBarChart
        chartTitle="RTM Codes Billed by Providers (Last 30 days)"
        data={report}
      />
    </Fragment>
  );
};

const PreviewCodes = ({ data }) => {
  if (!data) return null;

  const dataSource =
    Array.isArray(data) && data.length > 0
      ? data.slice(0, 20).map((item, index) => {
          return {
            ...item,
            key: index,
            Date: moment(item.Date).format('MMMM DD, YYYY'),
            BillDate:
              item.BillDate === 'unbilled'
                ? 'unbilled'
                : moment(item.BillDate).format('MMMM DD, YYYY'),
            BilledBy:
              item.BilledBy === 'unbilled'
                ? 'unbilled'
                : reportsUtils
                    .formatRtmProviders(item.BilledBy)
                    .map(b => `${b.FirstName} ${b.LastName}`)
                    .join(', ')
          };
        })
      : [];

  return (
    <Table
      columns={[
        {
          title: 'RTM Code',
          dataIndex: 'Code',
          key: 'Code'
        },
        {
          title: 'Patient',
          dataIndex: 'PatientName',
          key: 'PatientName',
          render: value => (
            <Typography.Text style={{ textTransform: 'capitalize' }}>
              {value}
            </Typography.Text>
          )
        },
        {
          title: 'Ready for Billing Date',
          dataIndex: 'Date',
          key: 'Date'
        },
        {
          title: 'Billed Date',
          dataIndex: 'BillDate',
          key: 'BillDate'
        },
        {
          title: 'Billed By',
          dataIndex: 'BilledBy',
          key: 'BilledBy'
        }
      ]}
      pagination={false}
      dataSource={dataSource}
    />
  );
};

const PreviewPatients = ({ data }) => {
  if (!data) return null;

  const dataSource =
    Array.isArray(data) && data.length > 0
      ? data.slice(0, 20).map((item, index) => {
          const providers = reportsUtils.formatRtmProviders(item.Providers);

          return {
            ...item,
            key: index,
            EnrollStart: moment(item.EnrollStart).format('MMMM DD, YYYY'),
            BillingDates: `${moment(item.BillingStart).format(
              'MMM DD, YYYY'
            )} - ${moment(item.BillingEnd).format('MMM DD, YYYY')}`,
            Providers: providers
              .map(provider => `${provider.FirstName} ${provider.LastName}`)
              .join(', ')
          };
        })
      : [];

  return (
    <Table
      columns={[
        {
          title: 'RTM Status',
          dataIndex: 'Status',
          key: 'Status',
          render: value => (
            <Tag className="rtm-status-tag" color={value.toLowerCase()}>
              {reportsUtils.getEngagementStatus(value)}
            </Tag>
          )
        },
        {
          title: 'Patient',
          dataIndex: 'PatientName',
          key: 'PatientName',
          render: value => (
            <Typography.Text style={{ textTransform: 'capitalize' }}>
              {value}
            </Typography.Text>
          )
        },
        {
          title: 'Email Address',
          dataIndex: 'EmailAddress',
          key: 'EmailAddress'
        },
        {
          title: 'Phone Number',
          dataIndex: 'PhoneNumber',
          key: 'PhoneNumber'
        },
        {
          title: 'Enrollment Date',
          dataIndex: 'EnrollStart',
          key: 'EnrollStart'
        },
        {
          title: 'Billing Period',
          dataIndex: 'BillingDates',
          key: 'BillingDates'
        },
        {
          title: 'Assigned Providers',
          dataIndex: 'Providers',
          key: 'Providers'
        }
      ]}
      pagination={false}
      dataSource={dataSource}
    />
  );
};

const PreviewServiceLogs = ({ data }) => {
  if (!data) return null;

  const groups = useRecoilValue(states.groups);
  const dataSource =
    Array.isArray(data) && data.length > 0
      ? data
          .slice(0, 20)
          .sort((a, b) => b.TotalDuration - a.TotalDuration)
          .map((log, index) => ({
            ...log,
            key: index,
            TotalDuration: `${log.TotalDuration} hours`
          }))
      : [];

  return (
    <Table
      columns={[
        {
          title: 'Provider',
          dataIndex: 'ProviderName',
          key: 'ProviderName',
          render: value => (
            <Typography.Text style={{ textTransform: 'capitalize' }}>
              {value}
            </Typography.Text>
          )
        },
        {
          title: 'Total RTM Time',
          dataIndex: 'TotalDuration',
          key: 'TotalDuration'
        },
        {
          title: 'Interactive Communication',
          dataIndex: 'TotalLiveCall',
          key: 'TotalLiveCall'
        },
        {
          title: 'Location',
          dataIndex: 'Location',
          key: 'Location',
          render: value =>
            groups.clinics.find(c => c.Id === value)?.ClinicName || value
        }
      ]}
      pagination={false}
      dataSource={dataSource}
    />
  );
};

export default PreviewCard;
