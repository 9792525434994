import apiFetch from '../utils/fetch.utils';
import states from '../states';
import { getRecoil } from 'recoil-nexus';

export const getBoards = async (role, sub) => {
  try {
    const uri = `messages?&type=all&role=${role}&sub=${encodeURIComponent(
      sub
    )}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addNewBoard = async (owner, therapist) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id } = groupsState.user;

    const uri = 'messages';
    const method = 'POST';
    const payload = {
      body: {
        groupId: id,
        owner: owner.sub,
        fname: owner.fname,
        lname: owner.lname,
        therapist: therapist.sub,
        ptFname: therapist.fname,
        ptLname: therapist.lname
      }
    };

    return apiFetch(uri, method, payload);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
