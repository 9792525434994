import React, { Component } from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getGroups, viewingGroups, searchGroups } from './actions/groups';
import {
  isLoading,
  doneLoading,
  isSearching,
  doneSearching,
} from './actions/groupsLoadingState';
import { Link } from 'react-router-dom';
import PTWTable from './PTWTable';
import SearchBox from '../../components/SearchBox';
import Loading from '../../components/Loading';
import { ClickableRow } from './ClickableRow';
import DropdownNavigation from '../../components/DropdownNavigation';

class Groups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: null,
    };

    this.onGroupRowClick = this.onGroupRowClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onGroupRowClick(group) {
    return () => {
      this.props.history.push(`/groups/${group.name}`);
    };
  }

  onChange(event) {
    const searchTerm = event.target.value;
    const { dispatch } = this.props;

    if (this.state.pending) {
      clearTimeout(this.state.pending);
    }

    this.setState({
      pending: setTimeout(() => {
        if (searchTerm) {
          dispatch(isSearching());
          dispatch(isLoading());
          dispatch(searchGroups(searchTerm)).then(
            () => dispatch(doneLoading()),
            () => dispatch(doneLoading())
          );
        } else {
          dispatch(doneSearching());
          dispatch(isLoading());
          dispatch(getGroups()).then(
            () => dispatch(doneLoading()),
            () => dispatch(doneLoading())
          );
        }
      }, 200),
    });
  }

  componentDidMount() {
    this.props.dispatch(isLoading());
    this.props.dispatch(viewingGroups());
    this.props
      .dispatch(getGroups())
      .then((x) => {
        setTimeout(() => {
          this.initializing = false;
          this.props.dispatch(doneLoading());
        }, 500);
      })
      .catch((x) => {
        this.props.dispatch(doneLoading());
      });
  }

  render() {
    const groupFields = {
      name: 'Group Name',
      groupPhone: 'Group Phone',
      contactName: 'Contact Name',
      contactPhone: 'Contact Phone',
    };

    let content;
    const { fetched, groups, isLoadingGrid, isSearching } = this.props;

    if (fetched) {
      if (groups.length === 0) {
        if (isSearching) {
          content = (
            <Row>
              <Col md={4} mdOffset={4} className="ptw-action-block">
                No results found
              </Col>
            </Row>
          );
        }
      } else {
        content = (
          <PTWTable
            items={groups}
            sortByDefault="name"
            fields={groupFields}
            onRowClick={this.onGroupRowClick}
            rowComponent={ClickableRow}
          />
        );
      }
    }

    return (
      <React.Fragment>
        <DropdownNavigation />

        <div className="ptw-main-body">
          <Link to="/administrator">
            <Button
              type="link"
              className="btn-link dashboard-back-btn"
              size="large"
            >
              <ArrowLeftOutlined /> Back
            </Button>
          </Link>
          <Row>
            <Col xs={12} md={6}>
              <Breadcrumb>
                <Breadcrumb.Item active>Groups</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col xs={12} md={2}>
              <Link to="/groups/new">
                <Button
                  type="primary"
                  className="ptw-btn btn-primary pull-right"
                >
                  <i className="fa fa-plus fa-fw" aria-hidden="true" /> Add
                  Group
                </Button>
              </Link>
            </Col>
            <Col xs={12} md={4}>
              <SearchBox onChange={this.onChange} placeholder="Group Name" />
            </Col>
          </Row>
          <Loading isLoading={isLoadingGrid}>{content}</Loading>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    groups: Object.values(state.groups.items),
    isLoadingGrid: state.groups.isLoading,
    isSearching: state.groups.isSearching,
    fetched: state.groups.fetched,
  };
}

export default connect(mapStateToProps)(withRouter(Groups));
