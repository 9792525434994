import update from 'immutability-helper';
import mapModel from '../../groups/mapper';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';

import {
  SELECT_PRESCRIPTION_EXERCISE,
  DESELECT_PRESCRIPTION_EXERCISE,
  CLEAR_PRESCRIPTION_EXERCISES,
  RESET_PRESCRIPTION_PROCESS,
  MOVE_PRESCRIPTION_EXERCISE,
  NEXT_PRESCRIPTION_FORM_STEP,
  PREV_PRESCRIPTION_FORM_STEP,
  OPEN_PRESCRIPTION_EXERCISE_MODAL,
  CLOSE_PRESCRIPTION_EXERCISE_MODAL,
  SEARCH_EXERCISES,
  RESET_SEARCH_RESULTS,
  PrescriptionStepsOrder,
  REMOVE_PRESCRIPTION_EXERCISE,
  UNDO_REMOVE_PRESCRIPTION_EXERCISE,
  REMOVE_ALL_PRESCRIPTION_EXERCISES,
  CLEAR_REMOVED_PRESCRIPTION_EXERCISES,
  FRESH_TEMPLATE_FORM,
  CREATE_TEMPLATE,
  RETRIEVE_TEMPLATES,
  APPEND_TEMPLATE_TO_PRESCRIPTION,
  CLEAR_TEMPLATE_DATA,
  LOAD_SELECTED_EXERCISES,
  EDIT_TEMPLATE_SUBMIT,
  EXPORT_PRESCRIPTION_TO_TEMPLATE,
  CLEAR_EXPORT_PRESCRIPTION,
  SET_SELECTED_EXERCISES
} from '../actions/prescription';

import {
  SEARCH_PATIENTS,
  CHANGE_WEEK,
  CURRENT_WEEK,
  CLOSE_MODAL,
  OPEN_MODAL,
  PRINT_OPEN_MODAL,
  POST_PATIENT,
  UPDATE_CURRENT_USER,
  IS_LOADING,
  DONE_LOADING,
  UPDATE_PATIENT,
  GET_PATIENT,
  OPEN_ADD_CUSTOM_EXERCISE_MODAL,
  CLOSE_ADD_CUSTOM_EXERCISE_MODAL,
  UPDATE_PATIENT_SUBMISSION_TYPE,
  patientSubmissionTypes,
  EDIT_PATIENT_PROFILE,
  DELETE_PATIENT,
  NEW_PATIENT_EMAIL,
  DISCHARGE_PATIENT,
  SET_VISIBLE_NEW_MESSAGES,
  RESET_PATIENT,
  GET_ANALYTICS_DATA,
  GET_PATIENT_DATAPOINTS,
  GET_CLINIC_MONITOR_LOGS,
  GET_RTM_BILLING,
  START_LOADING_TIMER,
  STOP_LOADING_TIMER,
  SET_ASSIGNED_PROVIDERS,
  GET_PATIENT_ANALYTICS_DATA,
  SET_PATIENT_ANALYTICS_DATA,
  CHECK_UNREAD_MESSAGES,
  UPDATE_TIMER_LOG_QUERY,
  GET_ALL_PROGRAMS,
  SET_CARE_PLAN_PROPERTIES
} from '../actions/patients';

const defaultState = {
  patients: [],
  weekChange: 0,
  currentWeek: [],
  modalOpen: false,
  printModalOpen: false,
  isSubmitting: false,
  submitted: false,
  isFetching: false,
  fetched: false,
  isLoading: false,
  currentPatient: null,
  currentStep: 0,
  isSearching: false,
  doneSearching: false,
  searchResults: [],
  selectedExercises: [],
  removedExercises: [],
  exerciseDetailModalOpen: false,
  addCustomExerciseModalOpen: false,
  currentModalExerciseDetail: {},
  lastPatientSubmissionType: patientSubmissionTypes.SAVE,
  templates: [],
  templateData: { frequency: null, instructions: null },
  loadingAnalyticsData: false,
  analyticsData: [],
  analyticsDataError: null,
  loadingDataPoints: false,
  dataPoints: null,
  dataPointsError: null,
  loadingClinigLogs: false,
  clinicLogs: null,
  clinicLogsError: null,
  loadingBilling: false,
  rtmBilling: null,
  rtmBillingError: null,
  loadingTimer: false,
  patientAnalytics: null,
  unreadMessages: true,
  timerLogQuery: null,
  carePlanPrograms: [],
  carePlan: [],
  carePlanProperties: null
};

export const patientsReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case `${RESET_PATIENT}`:
      return {
        ...state,
        currentPatient: null
      };

    case `${EXPORT_PRESCRIPTION_TO_TEMPLATE}`:
      return {
        ...state,
        exportPrescription: payload
      };

    case `${CLEAR_EXPORT_PRESCRIPTION}`:
      return {
        ...state,
        exportPrescription: null
      };

    case `${SET_VISIBLE_NEW_MESSAGES}`:
      return {
        ...state,
        messageCount: payload.count
      };

    case `${NEW_PATIENT_EMAIL}_${PENDING}`:
      return {
        ...state
      };

    case `${NEW_PATIENT_EMAIL}_${FULFILLED}`:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        isSearching: false,
        templates: payload
      };
    case `${DISCHARGE_PATIENT}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false,
        isSearching: true
      };

    case `${DISCHARGE_PATIENT}_${FULFILLED}`:
      const currentPatient = state.currentPatient;
      currentPatient.discharge = !currentPatient.discharge;

      return {
        ...state,
        isFetching: false,
        fetched: true,
        isSearching: false,
        currentPatient: currentPatient
      };

    case `${DELETE_PATIENT}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false,
        isSearching: true
      };

    case `${DELETE_PATIENT}_${FULFILLED}`:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        isSearching: false,
        templates: payload
      };

    case LOAD_SELECTED_EXERCISES:
      return { ...state, selectedExercises: payload };

    case CLEAR_TEMPLATE_DATA:
      return {
        ...state,
        templateData: { frequency: null, instructions: null },
        selectedExercises: []
      };

    case APPEND_TEMPLATE_TO_PRESCRIPTION:
      let exList = payload.Exercises || payload.exercises;
      let exercises;

      try {
        const templateIds = exList.map(({ id }) => id);
        const updatedList = [];

        for (let i = 0; i < state.selectedExercises.length; i++) {
          const ex = state.selectedExercises[i];

          let sentinel = false;
          for (let j = 0; j < templateIds.length; j++) {
            const id = templateIds[j];
            if (id === ex.id) {
              sentinel = true;
            }
          }

          if (sentinel === false) {
            updatedList.push(ex);
          }
        }

        exercises = [];

        exercises.push(...updatedList);
        exercises.push(...exList);
      } catch (err) {
        console.log(err);
        return {
          ...state
        };
      }

      return {
        ...state,
        selectedExercises: exercises,
        templateData: {
          frequency: payload.Frequency,
          instructions: payload.Instructions,
          perdiem: payload.perdiem,
          perseptem: payload.perseptem
        }
      };

    case `${RETRIEVE_TEMPLATES}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false,
        isSearching: true
      };

    case `${RETRIEVE_TEMPLATES}_${FULFILLED}`:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        isSearching: false,
        templates: payload
      };

    case `${EDIT_TEMPLATE_SUBMIT}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      };

    case `${EDIT_TEMPLATE_SUBMIT}_${FULFILLED}`:
      return { ...state, isFetching: false, fetched: true };

    case `${CREATE_TEMPLATE}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      };

    case `${CREATE_TEMPLATE}_${FULFILLED}`:
      return { ...state, isFetching: false, fetched: true };

    case FRESH_TEMPLATE_FORM:
      return { ...state, selectedExercises: [] };

    case SET_SELECTED_EXERCISES:
      return {
        ...state,
        selectedExercises: [...payload]
      };

    case SELECT_PRESCRIPTION_EXERCISE:
      let sentinel = false;

      for (let i = 0; i < state.selectedExercises.length; i++) {
        const ex = state.selectedExercises[i];
        if (payload.exercise.id === ex.id) {
          sentinel = true;
        }
      }

      if (sentinel) {
        return { ...state };
      }

      return {
        ...state,
        selectedExercises: [{ ...payload.exercise }, ...state.selectedExercises]
      };

    case MOVE_PRESCRIPTION_EXERCISE:
      const updatedList = [...state.selectedExercises];
      const item = updatedList.splice(payload.source.index, 1)[0];
      updatedList.splice(payload.destination.index, 0, item);

      return {
        ...state,
        selectedExercises: updatedList
      };

    case DESELECT_PRESCRIPTION_EXERCISE:
      return update(state, {
        selectedExercises: {
          $splice: [[payload.index, 1]]
        }
      });

    case REMOVE_PRESCRIPTION_EXERCISE:
      return {
        ...state,
        removedExercises: [...state.removedExercises, { ...payload.exercise }]
      };

    case UNDO_REMOVE_PRESCRIPTION_EXERCISE:
      return update(state, {
        removedExercises: {
          $splice: [[payload.index, 1]]
        }
      });

    case REMOVE_ALL_PRESCRIPTION_EXERCISES:
      return {
        ...state,
        removedExercises: [...state.removedExercises, ...payload.exercises]
      };

    case CLEAR_PRESCRIPTION_EXERCISES:
      return {
        ...state,
        selectedExercises: []
      };

    case CLEAR_REMOVED_PRESCRIPTION_EXERCISES:
      return {
        ...state,
        removedExercises: []
      };

    case RESET_PRESCRIPTION_PROCESS:
      return {
        ...state,
        currentStep: 0
      };

    case NEXT_PRESCRIPTION_FORM_STEP:
      return state.currentStep < PrescriptionStepsOrder.length - 1
        ? {
            ...state,
            currentStep: state.currentStep + 1
          }
        : state;

    case PREV_PRESCRIPTION_FORM_STEP:
      return state.currentStep === 0
        ? state
        : {
            ...state,
            currentStep: state.currentStep - 1
          };

    case OPEN_PRESCRIPTION_EXERCISE_MODAL:
      return {
        ...state,
        exerciseDetailModalOpen: true,
        currentModalExerciseDetail: payload.exercise
      };

    case CLOSE_PRESCRIPTION_EXERCISE_MODAL:
      return {
        ...state,
        exerciseDetailModalOpen: false,
        currentModalExerciseDetail: {}
      };

    case OPEN_ADD_CUSTOM_EXERCISE_MODAL:
      return {
        ...state,
        addCustomExerciseModalOpen: true
      };

    case CLOSE_ADD_CUSTOM_EXERCISE_MODAL:
      return {
        ...state,
        addCustomExerciseModalOpen: false
      };

    case `${SEARCH_EXERCISES}_${PENDING}`:
      return {
        ...state,
        isSearching: true,
        doneSearching: false
      };

    case `${SEARCH_EXERCISES}_${FULFILLED}`:
      return {
        ...state,
        isSearching: false,
        doneSearching: true,
        searchResults: payload.exercises.map((e) =>
          mapModel('exercise', e, false)
        )
      };

    case RESET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: [],
        doneSearching: false
      };

    case `${EDIT_PATIENT_PROFILE}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      };

    case `${EDIT_PATIENT_PROFILE}_${FULFILLED}`:
      return {
        ...state,
        isFetching: false,
        fetched: true
      };

    case `${SEARCH_PATIENTS}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false,
        patients: []
      };

    case `${SEARCH_PATIENTS}_${FULFILLED}`:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        patients: payload.map((p) => p._source)
      };

    case `${GET_PATIENT}_${PENDING}`:
      return {
        ...state,
        isFetching: true,
        fetched: false
      };

    case `${GET_PATIENT}_${FULFILLED}`:
      return {
        ...state,
        isFetching: false,
        fetched: true
      };

    case `${POST_PATIENT}_${PENDING}`:
      return {
        ...state,
        isSubmitting: true,
        submitted: false
      };

    case `${POST_PATIENT}_${FULFILLED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: true
      };

    case `${POST_PATIENT}_${REJECTED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: false
      };

    case CHANGE_WEEK:
      return {
        ...state,
        weekChange: payload
      };

    case CURRENT_WEEK:
      return {
        ...state,
        currentWeek: payload
      };

    case OPEN_MODAL:
      return {
        ...state,
        modalOpen: true
      };

    case PRINT_OPEN_MODAL:
      return {
        ...state,
        printModalOpen: true
      };

    case CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false,
        printModalOpen: false
      };

    case UPDATE_CURRENT_USER:
      try {
        const { Prescription } = payload.values;

        return {
          ...state,
          currentPatient: {
            ...payload.values,
            Prescription: {
              ...Prescription,
              exercises: Prescription.exercises ? Prescription.exercises : []
            }
          }
        };
      } catch (err) {
        return { ...state, currentPatient: { ...payload.values } };
      }

    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case DONE_LOADING:
      return {
        ...state,
        isLoading: false
      };

    case `${UPDATE_PATIENT}_${PENDING}`:
      return {
        ...state,
        isSubmitting: true,
        submitted: false
      };

    case `${UPDATE_PATIENT}_${FULFILLED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: true
      };

    case `${UPDATE_PATIENT}_${REJECTED}`:
      return {
        ...state,
        isSubmitting: false,
        submitted: false
      };

    case UPDATE_PATIENT_SUBMISSION_TYPE:
      return {
        ...state,
        lastPatientSubmissionType: payload
      };

    case `${GET_ANALYTICS_DATA}_${PENDING}`:
      return {
        ...state,
        loadingAnalyticsData: true
      };

    case `${GET_ANALYTICS_DATA}_${FULFILLED}`:
      return {
        ...state,
        loadingAnalyticsData: false,
        analyticsData: payload
      };

    case `${GET_ANALYTICS_DATA}_${REJECTED}`:
      return {
        ...state,
        loadingAnalyticsData: false,
        analyticsDataError: payload
      };

    case `${GET_PATIENT_DATAPOINTS}_${PENDING}`:
      return {
        ...state,
        loadingDataPoints: true
      };

    case `${GET_PATIENT_DATAPOINTS}_${FULFILLED}`:
      return {
        ...state,
        loadingDataPoints: false,
        dataPoints: payload
      };

    case `${GET_PATIENT_DATAPOINTS}_${REJECTED}`:
      return {
        ...state,
        loadingDataPoints: false,
        dataPointsError: payload
      };

    case `${GET_CLINIC_MONITOR_LOGS}_${PENDING}`:
      return {
        ...state,
        loadingClinigLogs: true
      };

    case `${GET_CLINIC_MONITOR_LOGS}_${FULFILLED}`:
      return {
        ...state,
        loadingClinigLogs: false,
        clinicLogs: payload
      };

    case `${GET_CLINIC_MONITOR_LOGS}_${REJECTED}`:
      return {
        ...state,
        loadingClinigLogs: false,
        clinicLogsError: payload
      };

    case `${GET_RTM_BILLING}_${PENDING}`:
      return {
        ...state,
        loadingBilling: true
      };

    case `${GET_RTM_BILLING}_${FULFILLED}`:
      return {
        ...state,
        loadingBilling: false,
        rtmBilling: payload
      };

    case `${GET_RTM_BILLING}_${REJECTED}`:
      return {
        ...state,
        loadingBilling: false,
        rtmBillingError: payload
      };

    case START_LOADING_TIMER:
      return {
        ...state,
        loadingTimer: true
      };

    case STOP_LOADING_TIMER:
      return {
        ...state,
        loadingTimer: false
      };

    case SET_ASSIGNED_PROVIDERS:
      return {
        ...state,
        assignedProviders: payload
      };

    case `${GET_PATIENT_ANALYTICS_DATA}_${FULFILLED}`:
      return {
        ...state,
        patientAnalytics: payload
      };

    case SET_PATIENT_ANALYTICS_DATA:
      return {
        ...state,
        patientAnalytics: payload
      };

    case CHECK_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: payload
      };

    case UPDATE_TIMER_LOG_QUERY:
      return {
        ...state,
        timerLogQuery: payload
      };

    case `${GET_ALL_PROGRAMS}_${FULFILLED}`:
      return {
        ...state,
        carePlanPrograms: payload.data
      };

    case SET_CARE_PLAN_PROPERTIES:
      return {
        ...state,
        carePlanProperties: payload
      };

    default:
      return state;
  }
};
