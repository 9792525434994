import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Modal, notification, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  exitCurrentMonitoredPatient,
  setCurrentMonitoredPatient,
} from '../../features/monitor-timer/actions';
import {
  getMessageBoardProviders,
  postCarePlan,
  setCarePlanProperties,
  setMessageBoardProviders,
} from '../../features/patients/actions/patients';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

import PrintPreview from '../../features/multiple-prescriptions/components/PrintPreviewModal/PrintPreview';
import Stepper from './Stepper';
import SelectExercises from './SelectExercises';
import EditPrescriptionDetails from './EditPrescriptionDetails';

import html2pdf from 'html2pdf.js';
import states from '../../states';
import services from '../../services';

const getLoadingModal = () => {
  return Modal.info({
    title: (
      <Typography.Title level={1} className="text-center">
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 48 }} />}
          size="large"
        />
      </Typography.Title>
    ),
    content: (
      <Typography.Paragraph className="text-center">
        Updating prescription, please wait...
      </Typography.Paragraph>
    ),
    centered: true,
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
    icon: null,
  });
};

const EditPrescription = ({
  match,
  dispatch,
  visibleProfile,
  currentPatient,
  currentMonitoredPatient,
  carePlanProperties,
  siteDesign,
  setExitActivity,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [prescription, setPrescription] = useRecoilState(states.prescription);
  const [params, setParams] = useRecoilState(states.params);

  const user = useRecoilValue(states.user);
  const patients = useRecoilValue(states.patients);
  const groups = useRecoilValue(states.groups);

  const pdfPrintRef = useRef(null);

  // assign the current prescription to edited prescription state
  useEffect(() => {
    window.scrollTo(0, 0);

    if (!!currentPatient.EnableRTM) {
      setExitActivity('View Adherence Log').then(() => {
        let currentMonitoredPatientObj = null;

        if (currentMonitoredPatient) {
          currentMonitoredPatientObj = {
            ...currentMonitoredPatient,
            activity: 'Edit Exercise Program',
          };
        } else {
          currentMonitoredPatientObj = {
            patient: currentPatient,
            groupName: groups.user.name,
            provider: user.details.emailAddress,
            activity: 'Edit Exercise Program',
          };
        }

        dispatch(setCurrentMonitoredPatient(currentMonitoredPatientObj));
      });
    }
  }, []);

  const handleAssignPrescription = async () => {
    const { form, list } = prescription;
    if (form && form.prescription.exercises.length) {
      if (
        form.prescription.formError ||
        form.prescription?.exercises.some(
          exercise => exercise?.formError === true
        )
      ) {
        return;
      }

      const loadingModal = getLoadingModal();

      let emailType = 'updatePrescription';
      if (list.length === 1 && !list[0].prescription.exercises.length) {
        emailType = 'newPatient';
      }

      try {
        const isNewPatient = emailType === 'newPatient';
        const paths = match.path.split('/');
        const patientSub = paths[paths.length - 2];

        const requests = [
          saveCarePlan(),
          services.prescription.updatePrescription(isNewPatient, patientSub),
        ];

        if (!!visibleProfile.GroupInfo.EnableRaintree) {
          const element = document.getElementById('hep-pdf');
          const options = {
            html2canvas: {
              useCORS: true,
            },
          };

          requests.push(
            html2pdf().set(options).from(element.firstChild).outputPdf()
          );
        }

        const response = await Promise.all(requests);

        if (response[1].status === 200) {
          const { details } = patients;

          const otherRequests = [
            services.email.send(
              emailType,
              details.sub,
              user.details.firstName,
              user.details.lastName
            ),
            dispatch(getMessageBoardProviders(details.sub)).then(async res => {
              const { providers } = res.value;
              if (Array.isArray(providers)) {
                const isNewProvider = !providers
                  .map(p => p.Sub)
                  .includes(user.details.sub);

                if (isNewProvider) {
                  await dispatch(
                    setMessageBoardProviders({
                      Action: 'MANAGE_BOARDS',
                      therapists: [
                        ...providers.map(p => toCamelCaseObjKeys(p)),
                        {
                          sub: user.details.sub,
                          firstName: user.details.firstName,
                          lastName: user.details.lastName,
                        },
                      ],
                      boardData: {
                        to: details.sub,
                        firstName: details.firstName,
                        lastName: details.lastName,
                        GroupId: groups.user.id,
                      },
                    })
                  );
                }
              }
            }),
          ];

          if (response[2]) {
            const caseId = form.caseId || params.caseId;
            const base64 = window.btoa(response[2]);

            if (currentPatient.SystemId) {
              otherRequests.push(
                services.documents.sendPdf({
                  groupId: visibleProfile.GroupId,
                  systemId: currentPatient.SystemId,
                  caseId: caseId || 'PT001',
                  file: base64,
                })
              );
            }
          }

          await Promise.all(otherRequests);

          const { data } = response[1];
          const updatedRx = toCamelCaseObjKeys(data);
          const rxListCopy = prescription.list.slice();
          const updatedRxList = [...rxListCopy];

          if (prescription.action === 'ADD') {
            updatedRxList.push(updatedRx);
          } else if (prescription.action === 'EDIT') {
            if (rxListCopy.length > 1) {
              const index = rxListCopy.findIndex(rx => rx.id === updatedRx.id);
              if (index >= 0) {
                updatedRxList[index] = updatedRx;
              } else {
                const i = rxListCopy.findIndex(rx => !rx.id);
                updatedRxList.splice(i, 1);
                updatedRxList.unshift(updatedRx);
              }
            } else {
              updatedRxList[0] = updatedRx;
            }
          }

          setPrescription({
            ...prescription,
            list: updatedRxList,
            form: updatedRx,
            action: '',
          });

          if (params.caseId) {
            setParams({
              ...params,
              caseId: '',
            });
          }

          let notifMessage = 'Prescription successfully updated.';
          if (!!visibleProfile.GroupInfo.EnableRaintree) {
            notifMessage =
              "Prescription has been successfully updated and saved to this patient's chart in Raintree.";
          }

          notification.success({
            message: 'Success!',
            description: notifMessage,
            duration: !!visibleProfile.GroupInfo.EnableRaintree ? 6 : 3,
          });

          dispatch(exitCurrentMonitoredPatient());
          setRedirect(true);
        }
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Error!',
          description: 'An error occurred while updating prescription.',
          duration: 3,
        });
      } finally {
        loadingModal.destroy();
      }
    }
  };

  const saveCarePlan = async () => {
    if (!!groups.user.enablePTU) {
      if (carePlanProperties) {
        await dispatch(postCarePlan(groups.user.name, carePlanProperties)).then(
          () => {
            dispatch(setCarePlanProperties(null));
          }
        );
      }
    }
  };

  const renderCurrentStepComponent = () => {
    switch (currentStep) {
      case 0:
        return <p>Setup Care Plan</p>;
      case 1:
        return <SelectExercises />;
      case 2:
        return <EditPrescriptionDetails />;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {redirect && <Redirect to={`/patients/${patients.details.sub}`} />}

      <div className="ptw-collapse-body">
        {renderCurrentStepComponent()}
        <Stepper
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleAssignPrescription={handleAssignPrescription}
        />
      </div>

      {!!visibleProfile.GroupInfo.EnableRaintree &&
        !!prescription.form?.prescription && (
          <div id="hep-pdf" style={{ display: 'none' }}>
            <PrintPreview
              printRef={pdfPrintRef}
              printProps={{
                currentPatient: {
                  ...currentPatient,
                  Creator: visibleProfile.EmailAddress,
                  GroupInfo: {
                    ...currentPatient.GroupInfo,
                    PrintEmail:
                      currentPatient.GroupInfo.PrintEmail || 'provider',
                  },
                },
                prescription: prescription.form.prescription,
                visibleProfile,
                siteDesign,
              }}
              raintreePDF={true}
            />
          </div>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = states => ({
  visibleProfile: states.visibleProfile,
  currentPatient: states.patients.currentPatient,
  currentMonitoredPatient: states.monitorTimer.currentMonitoredPatient,
  carePlanProperties: states.patients.carePlanProperties,
  siteDesign: states.publicPage.design,
});

export default connect(mapStateToProps)(EditPrescription);
