import React from 'react';
import { Card, Button, Row } from 'antd';
import { ExpandOutlined, RightOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import states from '../../../states';
import BarChart from '../../../components/BarChart';

const PreviewCard = ({ type, handleExpand, loading }) => {
  const reports = useRecoilValue(states.reports);

  const title =
    type === 'PATIENTS'
      ? 'Patients Added'
      : type === 'HEPS'
      ? 'Home Exercise Programs Assigned'
      : 'Care Plans Assigned';

  const data = reports.usage[type.toLowerCase()];

  return (
    <Card
      title={title}
      extra={[
        <Button type="primary" onClick={handleExpand} disabled={loading}>
          Expand <ExpandOutlined />
        </Button>
      ]}
    >
      <BarChart
        chartTitle={`${title} (Last 30 days) Report`}
        loading={loading}
        data={data}
      />
      {!loading && (
        <Row style={{ marginTop: 24 }} align="center" justify="center">
          <Button type="primary" onClick={handleExpand}>
            Load more <RightOutlined />
          </Button>
        </Row>
      )}
    </Card>
  );
};

export default PreviewCard;
