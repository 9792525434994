import React from 'react';
import { Form, Input, Select, Button, Typography } from 'antd';
import { ChromePicker } from 'react-color';

const RewardsForm = props => {
  const {
    rewardItem,
    setRewardItem,
    event,
    handleSubmitCustomization,
    isLoading
  } = props;

  return (
    <Form
      name="basic"
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 12 }}
      initialValues={rewardItem}
      onFinish={() => handleSubmitCustomization(event, { rewardItem })}
    >
      <Typography.Title level={4}>Metrics</Typography.Title>
      <Form.Item
        label="Metric Type"
        name="MetricType"
        rules={[{ required: true, message: 'Metric Type is required.' }]}
      >
        <Select
          onChange={value =>
            setRewardItem({ ...rewardItem, MetricType: value })
          }
          value={rewardItem.MetricType}
        >
          <Select.Option value="Review">Review</Select.Option>
          <Select.Option value="ExerciseCompletion">
            Exercise Completion
          </Select.Option>
          <Select.Option value="RoutineCompletion">
            Routine Completion
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Metric Target"
        name="MetricTarget"
        rules={[{ required: true, message: 'Metric Target is required.' }]}
      >
        <Input
          type="number"
          name="MetricTarget"
          onChange={e =>
            setRewardItem({ ...rewardItem, [e.target.name]: e.target.value })
          }
          value={rewardItem.MetricTarget}
        />
      </Form.Item>
      <Form.Item
        label="Color"
        name="Color"
        rules={[{ required: true, message: 'Color is required.' }]}
      >
        <ChromePicker
          color={rewardItem.Color}
          onChangeComplete={color => {
            setRewardItem({ ...rewardItem, Color: color.hex });
          }}
        />
      </Form.Item>
      <Typography.Title level={4}>Completion and Contents</Typography.Title>
      <Form.Item
        label="Completion Top"
        name="CompletionTop"
        rules={[{ required: true, message: 'Completion Top is required.' }]}
      >
        <Input
          type="text"
          name="CompletionTop"
          onChange={e =>
            setRewardItem({ ...rewardItem, [e.target.name]: e.target.value })
          }
          value={rewardItem.CompletionTop}
        />
      </Form.Item>
      <Form.Item
        label="Completion Bottom"
        name="CompletionBottom"
        rules={[{ required: true, message: 'Completion Bottom is required.' }]}
      >
        <Input
          type="text"
          name="CompletionBottom"
          onChange={e =>
            setRewardItem({ ...rewardItem, [e.target.name]: e.target.value })
          }
          value={rewardItem.CompletionBottom}
        />
      </Form.Item>
      <Form.Item
        label="Content Top"
        name="ContentTop"
        rules={[{ required: true, message: 'Content Top is required.' }]}
      >
        <Input
          type="text"
          name="ContentTop"
          onChange={e =>
            setRewardItem({ ...rewardItem, [e.target.name]: e.target.value })
          }
          value={rewardItem.ContentTop}
        />
      </Form.Item>
      <Form.Item
        label="Content Bottom"
        name="ContentBottom"
        rules={[{ required: true, message: 'Content Bottom is required.' }]}
      >
        <Input
          type="text"
          name="ContentBottom"
          onChange={e =>
            setRewardItem({ ...rewardItem, [e.target.name]: e.target.value })
          }
          value={rewardItem.ContentBottom}
        />
      </Form.Item>
      <Typography.Title level={4}>Links</Typography.Title>
      <Form.Item
        label="Facebook Link"
        name="FacebookLink"
        rules={[{ required: true, message: 'Facebook Link is required.' }]}
      >
        <Input
          type="url"
          name="FacebookLink"
          onChange={e =>
            setRewardItem({ ...rewardItem, [e.target.name]: e.target.value })
          }
          value={rewardItem.FacebookLink}
        />
      </Form.Item>
      <Form.Item
        label="Google Link"
        name="GoogleLink"
        rules={[{ required: true, message: 'Google Link is required.' }]}
      >
        <Input
          type="url"
          name="GoogleLink"
          onChange={e =>
            setRewardItem({ ...rewardItem, [e.target.name]: e.target.value })
          }
          value={rewardItem.GoogleLink}
        />
      </Form.Item>
      <Form.Item
        label="Image Location"
        name="ImageLocation"
        rules={[{ required: true, message: 'Image Location is required.' }]}
      >
        <Input
          type="url"
          name="ImageLocation"
          onChange={e =>
            setRewardItem({ ...rewardItem, [e.target.name]: e.target.value })
          }
          value={rewardItem.ImageLocation}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 3, span: 12 }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RewardsForm;
