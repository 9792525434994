import { Fragment, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Typography
} from 'antd';
import { v4 as uuid } from 'uuid';
import { DeleteOutlined } from '@ant-design/icons';

const getItemStyle = draggableStyle => ({
  userSelect: 'none',
  background: '#fff',
  ...draggableStyle
});

const CustomizableButtonsForm = ({
  buttons,
  event,
  handleSubmitCustomization,
  isLoading
}) => {
  const [buttonItems, setButtonItems] = useState([]);
  const [hasEmptyField, setHasEmptyField] = useState(false);

  useEffect(() => {
    let items = [
      {
        Id: uuid(),
        Title: '',
        Body: '',
        Icon: '',
        Url: ''
      }
    ];

    if (buttons?.length) {
      items = buttons.map(item => ({
        ...item,
        Id: uuid()
      }));
    }

    setButtonItems(items);
  }, [buttons]);

  const handleDrag = result => {
    const { destination, source } = result;

    const updatedItems = [...buttonItems];
    const item = updatedItems.splice(source.index, 1)[0];

    updatedItems.splice(destination.index, 0, item);
    setButtonItems(updatedItems);
  };

  const handleAdd = () => {
    const updatedItems = [
      ...buttonItems,
      {
        Id: uuid(),
        Title: '',
        Body: '',
        Icon: '',
        Url: ''
      }
    ];

    setButtonItems(updatedItems);
    setHasEmptyField(false);
  };

  const handleChange = updatedItems => {
    setButtonItems(updatedItems);
  };

  const handleRemove = id => {
    const updatedItems = [...buttonItems].filter(item => item.Id !== id);
    setButtonItems(updatedItems);
  };

  const handleSubmit = () => {
    const hasEmpty = buttonItems.some(item =>
      Object.values(item).some(value => !value)
    );

    if (hasEmpty) {
      setHasEmptyField(true);
    } else {
      handleSubmitCustomization(event, {
        buttons: buttonItems.map(item => {
          const button = {
            ...item
          };

          delete button.Id;
          return button;
        })
      });
    }
  };

  return (
    <Form layout="vertical">
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {buttonItems.map((item, i) => (
                <Fragment key={i}>
                  <Draggable key={item.Id} draggableId={item.Id} index={i}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={getItemStyle(provided.draggableProps.style)}
                      >
                        <ButtonField
                          hasError={hasEmptyField}
                          list={buttonItems}
                          item={item}
                          onChange={handleChange}
                          onRemove={handleRemove}
                        />
                      </div>
                    )}
                  </Draggable>
                </Fragment>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Divider />

      <Space size="middle">
        <Button onClick={handleAdd}>Add Customizable Button</Button>

        <Button type="primary" loading={isLoading} onClick={handleSubmit}>
          Save Changes
        </Button>
      </Space>
    </Form>
  );
};

const ButtonField = ({ hasError, list, item, onChange, onRemove }) => {
  const [empty, setEmpty] = useState({
    title: false,
    body: false,
    icon: false,
    url: false
  });

  const button = list.find(btn => btn.Id === item.Id);
  const title = button.Title || '';
  const confirmNote = `Are you sure you want to delete ${
    title ? `Button (${title})` : 'Untitled Button'
  }?`;

  const showError = (field, value) => {
    return (hasError || empty[field]) && !value;
  };

  const handleChange = (field, input) => {
    const index = list.findIndex(btn => btn.Id === item.Id);
    const value = list.splice(index, 1)[0];

    const updatedItems = [...list];
    updatedItems.splice(index, 0, {
      ...value,
      [field]: input
    });

    onChange(updatedItems);
    setEmpty(state => ({
      ...state,
      [field.toLowerCase()]: !!input
    }));
  };

  const handleRemove = () => {
    onRemove(button.Id);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography.Title
          level={5}
          style={{
            marginBottom: 0
          }}
        >
          {title ? `Button (${title})` : 'Untitled Button'}
        </Typography.Title>

        <Popconfirm
          placement="left"
          okText="Yes"
          cancelText="Cancel"
          title={confirmNote}
          onConfirm={handleRemove}
        >
          <Button type="link" size="large" icon={<DeleteOutlined />} />
        </Popconfirm>
      </div>

      <Row gutter={12}>
        <Col span={12}>
          <Field
            label="Title"
            showError={showError('title', button.Title)}
            value={button.Title}
            onChange={val => {
              handleChange('Title', val);
            }}
          />
        </Col>

        <Col span={12}>
          <Field
            label="Description"
            showError={showError('body', button.Body)}
            value={button.Body}
            onChange={val => {
              handleChange('Body', val);
            }}
          />
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={12}>
          <Field
            label="Icon"
            showError={showError('icon', button.Icon)}
            value={button.Icon}
            onChange={val => {
              handleChange('Icon', val);
            }}
          />
        </Col>

        <Col span={12}>
          <Field
            label="URL"
            showError={showError('url', button.Url)}
            value={button.Url}
            onChange={val => {
              handleChange('Url', val);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

const Field = ({ label, value, onChange, showError }) => {
  const status = showError ? 'error' : '';

  const handleOnChange = e => {
    onChange(e.target.value);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography.Text
        style={{
          marginBottom: 4
        }}
      >
        {label}
      </Typography.Text>

      <Input status={status} value={value} onChange={handleOnChange} />

      {showError ? (
        <Typography.Text
          style={{
            color: 'red'
          }}
        >
          This field is required.
        </Typography.Text>
      ) : (
        <div
          style={{
            height: 22
          }}
        />
      )}
    </div>
  );
};

export default CustomizableButtonsForm;
