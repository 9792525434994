import {
  DeleteOutlined,
  EditOutlined,
  StarFilled,
  StarOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import {
  Modal,
  Typography,
  Button,
  Row,
  Col,
  Space,
  Input,
  Form,
  Tooltip,
  Divider,
  Select,
  Popconfirm
} from 'antd';
import { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ImageSelector } from '../file-uploader';
import { uploadImageDirect } from '../file-uploader/actions';
import { exerciseImgValidator } from '../../features/groups/validators/groupExercises';
import {
  popName,
  popDescription,
  popParameters,
  popVideo,
  popImageCompatibleFormats,
  popImage
} from '../../features/groups/group-exercises/pops';
import { getEmbeddedUrl } from '../../utils/videoUrl.utils';
import { parseFavorites } from '../../utils/exercises.utils';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

import _ from 'lodash';
import V from 'voca';
import cptCodes from '../../features/export-hep-to-prompt/CptCodes.json';
import services from '../../services';
import states from '../../states';
import setNotification from '../../utils/setNotification.utils';

import NoResults from '../../components/NoResults';
import ProfileImage from '../../features/patients/Modals/ProfileImage';

const YOUTUBE_REGEX = `(youtu.*be.*)(com)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))`;
const IMAGE_URI = 'https://d2p805pqn3eul9.cloudfront.net/';

const ExerciseDetailsModal = ({
  handleExerciseDetailsModal,
  visibleProfile,
  uploadImage
}) => {
  const [editing, setEditing] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paramsLoading, setParamsLoading] = useState(false);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [parametersError, setParametersError] = useState(false);
  const [imageData, setImageData] = useState({
    validImage: true,
    img: null,
    base64: null,
    file: null,
    cropping: false,
    removeFile: null
  });

  const [exercises, setExercises] = useRecoilState(states.exercises);
  const [favorites, setFavorites] = useRecoilState(states.favorites);
  const [prescription, setPrescription] = useRecoilState(states.prescription);

  const user = useRecoilValue(states.user);
  const modals = useRecoilValue(states.modal);
  const imgSelectorRef = useRef();

  useEffect(() => {
    if (exercises.details) {
      setImageData((prevState) => ({
        ...prevState,
        img: exercises.details.image || null
      }));
    }
  }, [exercises.details]);

  if (!exercises.details || !modals.exerciseDetails) {
    return null;
  }

  const { video, exerciseName, sets, reps, hold, instructions, id, groupId } =
    exercises.details;

  const promptIntegrated = visibleProfile?.GroupInfo?.EnablePrompt;
  const item = favorites.list.find((fav) => fav.id === id);
  const star = item ? true : !!exercises.details?.star;
  const embeddedUrl = exercises.details ? getEmbeddedUrl(video) : undefined;
  const params = exercises.custom.parameter.find((p) => p.ExerciseId === id);
  const custom = exercises.custom.exercises.find((ex) => ex.Id === id);

  const validateVideoUrl = async (str) => {
    if (str.match(YOUTUBE_REGEX)) {
      return getYoutubeThumbnail(str).length === 11;
    } else if (str.startsWith('https://vimeo.com')) {
      return getVimeoThumbnail(str).then((videoId) => {
        return !!videoId;
      });
    } else {
      setImageData((prevState) => ({
        ...prevState,
        img: null
      }));

      return false;
    }
  };

  const getYoutubeThumbnail = (url) => {
    const results = url.match('[\\?&]v=([^&#]*)');
    const videoId = results === null ? url : results[1];

    setImageData((prevState) => ({
      ...prevState,
      img: `http://img.youtube.com/vi/${videoId}/0.jpg`
    }));

    return videoId;
  };

  const getVimeoThumbnail = async (url) => {
    let videoId = '';
    await fetch(`https://vimeo.com/api/oembed.json?url=${url}`).then(
      async (response) => {
        await response.json().then((data) => {
          videoId = data.video_id;

          setImageData((prevState) => ({
            ...prevState,
            img: data.thumbnail_url
          }));
        });
      }
    );

    return videoId;
  };

  const onFileSelected = async (file, errors) => {
    const isValid = _.isEmpty(errors);

    if (isValid) {
      setImageData((prevState) => ({
        ...prevState,
        validImage: true,
        file,
        cropping: true
      }));
    } else {
      setNotification(
        'error',
        'Upload Error!',
        'Image size must be less than 2.5MB. Use images less than 1MB for faster loading time.'
      );
    }
  };

  const onFileRemoved = () => {
    setImageData((prevState) => ({
      ...prevState,
      validImage: true,
      removeFile: null
    }));
  };

  const handleImageCrop = (blob, base64Str) => {
    setImageData((prevState) => ({
      ...prevState,
      base64: base64Str,
      file: blob,
      cropping: false
    }));
  };

  const handleExitCrop = () => {
    setImageData((prevState) => ({
      ...prevState,
      img: null,
      cropping: false,
      file: null
    }));
  };

  const handleFavorite = (star) => {
    if (star) {
      handleRemoveFavorite();
    } else {
      handleAddFavorite();
    }
  };

  const handleAddFavorite = async () => {
    try {
      setFavoriteLoading(true);

      let response = await services.exercises.addFavorite(
        user.details.favoriteId,
        [{ Id: id, GroupId: groupId }]
      );

      if (response.status === 200) {
        setFavorites({
          ...favorites,
          list: parseFavorites(response.data.exercises)
        });
        handleExerciseDetailsModal(null);
        setNotification(
          'success',
          'Success!',
          'Exercise has been added to favorites.'
        );
      }
    } catch (err) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while adding exercise to favorites.'
      );
    } finally {
      setFavoriteLoading(false);
    }
  };

  const handleRemoveFavorite = async () => {
    try {
      setFavoriteLoading(true);

      let response = await services.exercises.deleteFavorite(
        user.details.favoriteId,
        id
      );

      if (response.status === 200) {
        setFavorites({
          ...favorites,
          list: parseFavorites(response.data.exercises)
        });
        handleExerciseDetailsModal(null);
        setNotification(
          'success',
          'Success!',
          'Exercise has been removed from favorites.'
        );
      }
    } catch (err) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while removing exercise to favorites.'
      );
    } finally {
      setFavoriteLoading(false);
    }
  };

  const handleRestoreDefault = async () => {
    try {
      setParamsLoading(true);

      const paramId = params?.Id;
      const userSub = visibleProfile.Sub;

      await services.exercises
        .deleteCustomParameter(paramId, userSub)
        .then((res) => {
          if (res.status === 204) {
            setExercises((prevState) => {
              const parameter = prevState.custom.parameter;
              const item = prevState.unmapped.find((ex) => ex.id === id);

              return {
                ...prevState,
                custom: {
                  ...prevState.custom,
                  parameter: parameter.filter((ex) => ex.Id !== paramId)
                },
                list: prevState.list.map((ex) => {
                  if (ex.id !== id) {
                    return ex;
                  }

                  return item;
                })
              };
            });

            handleExerciseDetailsModal(null);
            setNotification(
              'success',
              'Success!',
              'Default instructions restored successfully.'
            );
          } else {
            throw new Error('Delete Failed!');
          }
        });
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while restoring default instructions.'
      );
    } finally {
      setParamsLoading(false);
    }
  };

  const handleSaveExercise = async (values) => {
    try {
      setLoading(true);

      if (!!custom) {
        const exercise = {
          ...toCamelCaseObjKeys(custom),
          ...values,
          name: values.exerciseName.toLowerCase(),
          image:
            values.video === exercises.details.video
              ? exercises.details.image
              : imageData.img
        };

        delete exercise.cPTCode;
        delete exercise.exerciseName;

        if (imageData.base64) {
          const type = imageData.base64.split(';')[0].split('/')[1];
          const filename = `${visibleProfile.GroupId}/${id}.${type}`;

          await uploadImage(filename, imageData.file).then(() => {
            exercise.image = IMAGE_URI + filename;
          });
        }

        await services.exercises.addCustomExercise(exercise).then((res) => {
          if (res.status === 200) {
            const updatedExercise = {
              ...res.data,
              imgBase64: imageData.base64
            };

            const callbackFn = (item) => {
              if (item.id === id) {
                const updatedItem = {
                  ...toCamelCaseObjKeys(updatedExercise)
                };

                delete updatedItem.cPTCode;
                return updatedItem;
              }

              return item;
            };

            setExercises((prevState) => ({
              ...prevState,
              unmapped: prevState.unmapped.map(callbackFn),
              list: prevState.list.map(callbackFn),
              custom: {
                ...prevState.custom,
                exercises: prevState.custom.exercises.map((item) => {
                  if (item.Id !== id) {
                    return item;
                  }

                  return updatedExercise;
                })
              }
            }));

            if (prescription.form) {
              setPrescription((prevState) => ({
                ...prevState,
                form: {
                  ...prevState.form,
                  prescription: {
                    ...prevState.form.prescription,
                    exercises:
                      prevState.form.prescription.exercises.map(callbackFn)
                  }
                }
              }));
            }
          }
        });
      } else {
        const { Sub, GroupId } = visibleProfile;
        const parameters = {
          ...values,
          id: params?.Id,
          exerciseId: id,
          exerciseName: values.exerciseName.toLowerCase(),
          sub: Sub,
          groupId: GroupId
        };

        await services.exercises.addCustomParameters(parameters).then((res) => {
          if (res.status === 200) {
            setExercises((prevState) => {
              const parameter = [...prevState.custom.parameter];
              const index = parameter.findIndex((p) => p.Id === res.data.Id);

              if (index > -1) {
                parameter.splice(index, 1, res.data);
              } else {
                parameter.push(res.data);
              }

              return {
                ...prevState,
                custom: {
                  ...prevState.custom,
                  parameter
                },
                list: prevState.list.map((item) => {
                  if (res.data.ExerciseId !== item.id) {
                    return item;
                  }

                  return {
                    ...item,
                    exerciseName: res.data.ExerciseName || item.exerciseName,
                    instructions: res.data.Instructions || item.instructions,
                    sets: res.data.Sets || item.sets,
                    reps: res.data.Reps || item.reps,
                    hold: res.data.Hold || item.hold
                  };
                })
              };
            });
          }
        });
      }

      handleExerciseDetailsModal(null);
      setNotification(
        'success',
        'Success!',
        `Custom exercise ${!!custom ? '' : 'parameters'} successfully ${
          !!custom ? 'updated' : 'added'
        }.`
      );
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        `An error occurred while ${
          !!custom ? 'updating' : 'adding'
        } custom exercise ${!!custom ? '' : 'parameters'}.`
      );
    } finally {
      setEditing(false);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setDropdown(!dropdown);

    try {
      const { Sub } = visibleProfile;
      await services.exercises.deleteCustomExercise(id, Sub).then((res) => {
        if (res.status === 204) {
          setExercises((prevState) => {
            const exercises = prevState.custom.exercises;

            return {
              ...prevState,
              custom: {
                ...prevState.custom,
                exercises: exercises.filter((ex) => ex.Id !== id)
              },
              unmapped: prevState.unmapped.filter((ex) => ex.id !== id),
              list: prevState.list.filter((ex) => ex.id !== id)
            };
          });

          handleExerciseDetailsModal(null);
          setNotification(
            'success',
            'Success!',
            'This exercise has been deleted.'
          );
        } else {
          throw new Error('Delete Failed!');
        }
      });
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while deleting custom exercise.'
      );
    }
  };

  const handleCloseModal = () => {
    setEditing(false);
    setImageData({
      validImage: true,
      img: null,
      base64: null,
      file: null,
      cropping: false,
      removeFile: null
    });

    handleExerciseDetailsModal(null);
  };

  return (
    <>
      {imageData.cropping && (
        <ProfileImage
          show={imageData.cropping}
          image={imageData.file}
          onSubmit={handleImageCrop}
          onExit={handleExitCrop}
          onHide={handleExitCrop}
          closeButton={handleExitCrop}
          isExerciseImage={true}
        />
      )}

      <Modal
        visible
        centered
        onCancel={handleCloseModal}
        footer={null}
        className="preview-exercise-modal"
        width={700}
        bodyStyle={{
          maxHeight: '80vh',
          overflow: 'auto'
        }}
      >
        {embeddedUrl ? (
          <div className="preview-iframe-wrapper">
            <iframe
              title="Exercise Video"
              src={embeddedUrl}
              width="100%"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        ) : (
          <NoResults content="No video embedded on this exercise." />
        )}

        <div className="preview-modal-body">
          <div className="tab-header">
            <Typography.Title level={4}>
              {editing ? 'Edit Exercise' : V.titleCase(exerciseName || '')}
            </Typography.Title>

            <Space
              direction="horizontal"
              size={4}
              style={{
                alignItems: 'flex-start'
              }}
            >
              {editing ? (
                <div
                  style={{
                    justifyContent: 'center'
                  }}
                >
                  <Divider orientation="center" type="vertical" />

                  <Space direction="horizontal" size="small">
                    {!!params && (
                      <Popconfirm
                        title="Are you sure you want to restore to default instructions?"
                        okText="Restore"
                        onConfirm={handleRestoreDefault}
                        zIndex={9999}
                        align={{
                          offset: [0, 6]
                        }}
                      >
                        <Tooltip
                          title="Restore Default Instructions"
                          align={{
                            offset: [0, 5]
                          }}
                        >
                          <Button
                            className="ptw-btn"
                            size="large"
                            type="primary"
                          >
                            <FontAwesomeIcon
                              icon={faUndo}
                              style={{
                                fontSize: 20,
                                paddingTop: 3
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </Popconfirm>
                    )}

                    <Button
                      htmlType="submit"
                      className="ptw-btn"
                      type="primary"
                      size="large"
                      form="exerciseForm"
                      loading={loading}
                    >
                      Save Changes
                    </Button>

                    <Button
                      type="default"
                      size="large"
                      className="ptw-btn"
                      disabled={loading}
                      onClick={() => {
                        setEditing(!editing);
                      }}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              ) : (
                <Fragment>
                  {Array.isArray(favorites.list) && (
                    <Tooltip
                      title={`${star ? 'Remove from' : 'Add to'} Favorites`}
                    >
                      <Button
                        loading={favoriteLoading}
                        type={star ? 'link' : 'text'}
                        icon={star ? <StarFilled /> : <StarOutlined />}
                        onClick={() => {
                          handleFavorite(star);
                        }}
                        size="large"
                        shape="circle"
                      />
                    </Tooltip>
                  )}

                  {!!custom ? (
                    <>
                      <Tooltip title="Edit Parameters">
                        <Button
                          type="text"
                          shape="circle"
                          size="large"
                          onClick={() => {
                            setEditing(!editing);
                          }}
                          icon={<EditOutlined />}
                        />
                      </Tooltip>

                      <Popconfirm
                        placement="left"
                        title="Are you sure you want to delete this custom exercise?"
                        okText="Delete"
                        okButtonProps={{
                          type: 'danger'
                        }}
                        onConfirm={handleDelete}
                      >
                        <Tooltip title="Delete Exercise">
                          <Button
                            type="text"
                            shape="circle"
                            size="large"
                            icon={<DeleteOutlined />}
                          />
                        </Tooltip>
                      </Popconfirm>
                    </>
                  ) : (
                    <Tooltip title="Edit Parameters">
                      <Button
                        type="text"
                        shape="circle"
                        size="large"
                        onClick={() => {
                          setEditing(!editing);
                        }}
                        icon={<EditOutlined />}
                      />
                    </Tooltip>
                  )}
                </Fragment>
              )}
            </Space>
          </div>

          <Form
            layout="vertical"
            id="exerciseForm"
            initialValues={{
              exerciseName: V.titleCase(exerciseName || ''),
              instructions: instructions || '',
              hold: hold || '',
              reps: reps || '',
              sets: sets || '',
              cptCode: params?.CPTCode || custom?.CPTCode || '',
              video: video || ''
            }}
            onFinish={handleSaveExercise}
          >
            <Space
              size={24}
              direction="vertical"
              className="preview-modal-space"
            >
              <Row gutter={[8, 8]}>
                {editing && (
                  <Fragment>
                    <Col span={promptIntegrated ? 16 : 24}>
                      <Form.Item
                        label="Exercise Name"
                        name="exerciseName"
                        tooltip={popName}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your exercise name.'
                          },
                          { max: 100, message: 'Max length is 100 only.' }
                        ]}
                      >
                        <Input
                          type="text"
                          size="large"
                          name="exerciseName"
                          value={exerciseName}
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        style={{ marginBottom: -40, fontWeight: 'bold' }}
                        label="Parameters"
                        tooltip={popParameters}
                      />
                    </Col>

                    {promptIntegrated && (
                      <Col span={8}>
                        <Form.Item
                          label="CPT Code"
                          name="cptCode"
                          tooltip="Assign a default CPT Code to this exercise. When using the Export HEP to Visit in Prompt feature, this CPT Code will automatically be assigned. You can still update it later if needed."
                        >
                          <Select
                            size="large"
                            showSearch
                            placeholder="CPT Code"
                          >
                            {cptCodes.map((item, i) => (
                              <Fragment key={i}>
                                <Select.Option value={item.CPT_Code}>
                                  <Tooltip
                                    placement="right"
                                    title={item.Description}
                                  >
                                    {item.CPT_Code}
                                  </Tooltip>
                                </Select.Option>
                              </Fragment>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Fragment>
                )}

                <Col lg={8} md={8} sm={24} xs={24}>
                  {editing ? (
                    <Form.Item
                      label="Sets"
                      name="sets"
                      dependencies={['reps', 'hold']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value &&
                              !getFieldValue('reps') &&
                              !getFieldValue('hold')
                            ) {
                              setParametersError(true);
                              return Promise.reject();
                            } else {
                              setParametersError(false);
                              return Promise.resolve();
                            }
                          }
                        })
                      ]}
                    >
                      <Input
                        type="text"
                        size="large"
                        name="sets"
                        value={sets || ''}
                      />
                    </Form.Item>
                  ) : (
                    sets && (
                      <Typography.Title level={5} className="text-center">
                        {sets} Sets
                      </Typography.Title>
                    )
                  )}
                </Col>

                <Col lg={8} md={8} sm={24} xs={24}>
                  {editing ? (
                    <Form.Item
                      label="Reps"
                      name="reps"
                      dependencies={['sets', 'hold']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value &&
                              !getFieldValue('sets') &&
                              !getFieldValue('hold')
                            ) {
                              setParametersError(true);
                              return Promise.reject();
                            } else {
                              setParametersError(false);
                              return Promise.resolve();
                            }
                          }
                        })
                      ]}
                    >
                      <Input
                        type="text"
                        size="large"
                        name="reps"
                        value={reps || ''}
                      />
                    </Form.Item>
                  ) : (
                    reps && (
                      <Typography.Title level={5} className="text-center">
                        {reps} Reps
                      </Typography.Title>
                    )
                  )}
                </Col>

                <Col lg={8} md={8} sm={24} xs={24}>
                  {editing ? (
                    <Form.Item
                      label="Hold"
                      name="hold"
                      dependencies={['sets', 'reps']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value &&
                              !getFieldValue('sets') &&
                              !getFieldValue('reps')
                            ) {
                              setParametersError(true);
                              return Promise.reject();
                            } else {
                              setParametersError(false);
                              return Promise.resolve();
                            }
                          }
                        })
                      ]}
                    >
                      <Input
                        type="text"
                        size="large"
                        name="hold"
                        value={hold || ''}
                      />
                    </Form.Item>
                  ) : (
                    hold && (
                      <Typography.Title level={5} className="text-center">
                        {hold} Hold
                      </Typography.Title>
                    )
                  )}
                </Col>
              </Row>

              {parametersError && (
                <Typography.Paragraph type="danger" style={{ marginTop: -35 }}>
                  Please fill in at least one parameter field.
                </Typography.Paragraph>
              )}
              <Row style={{ marginTop: editing ? -24 : 0 }}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  {editing ? (
                    <Form.Item
                      label="Instructions"
                      name="instructions"
                      tooltip={popDescription}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your instructions.'
                        },
                        {
                          max: 1000,
                          message: 'Max length is 1000 characters.'
                        }
                      ]}
                    >
                      <Input.TextArea
                        size="large"
                        value={instructions || ''}
                        rows={4}
                      />
                    </Form.Item>
                  ) : (
                    instructions && (
                      <Typography.Text>{instructions}</Typography.Text>
                    )
                  )}
                </Col>
              </Row>

              {!!custom && editing && (
                <Row
                  gutter={[12, 0]}
                  style={{
                    marginTop: -24
                  }}
                >
                  <Col span={12}>
                    <Form.Item
                      name="video"
                      label="Exercise Video URL"
                      validateTrigger="onBlur"
                      tooltip={popVideo}
                      rules={[
                        {
                          message: 'Please input a valid exercise video URL.',
                          validator: async (_, value) => {
                            if (!value || value === video) {
                              return Promise.resolve();
                            }

                            const valid = await validateVideoUrl(value);

                            if (valid) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject();
                            }
                          }
                        }
                      ]}
                      style={{
                        marginBottom: 4
                      }}
                    >
                      <Input
                        name="videoUrl"
                        type="text"
                        size="large"
                        placeholder="Enter exercise video URL"
                      />
                    </Form.Item>

                    <Tooltip
                      placement="topLeft"
                      title={popImageCompatibleFormats}
                      overlayInnerStyle={{
                        width: 320
                      }}
                    >
                      <Typography.Link>
                        See Compatible URL Formats
                      </Typography.Link>
                    </Tooltip>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Exercise Image"
                      tooltip={popImage}
                      help={
                        !imageData.validImage && (
                          <Typography.Paragraph type="danger">
                            Selected image is either not in JPG, JPEG, or PNG
                            format or has a size of more than 2.5MB.
                          </Typography.Paragraph>
                        )
                      }
                    >
                      <div className={!imageData.validImage ? 'has-error' : ''}>
                        <ImageSelector
                          width="auto"
                          height={180}
                          ref={imgSelectorRef}
                          isUser={false}
                          defaultImageUrl={imageData.base64 || imageData.img}
                          src={imageData.base64 || imageData.img}
                          onFileSelected={onFileSelected}
                          onFileRemoved={onFileRemoved}
                          imageValidator={exerciseImgValidator}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Space>
          </Form>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  visibleProfile: state.visibleProfile
});

const mapDispatchToProps = (dispatch) => ({
  uploadImage: (name, file) => dispatch(uploadImageDirect(name, file))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseDetailsModal);
