import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  Row,
  Col,
  Button,
  Statistic,
  Space,
  List,
  notification,
  Typography,
} from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getIntegrationCode } from './actions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import DropdownNavigation from '../../components/DropdownNavigation';

function Integration(props) {
  const [code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [dataString, setDataString] = useState(null);

  useEffect(() => {
    if (!code) {
      return;
    }
    const subdomain = window.location.host.split('.')[1]
      ? window.location.host.split('.')[0]
      : null;

    const dataObject = { domain: subdomain, key: code };

    const str = JSON.stringify(dataObject);

    const enc = new Buffer(str).toString('hex');

    setDataString(enc);
  }, [code]);

  const getCode = async () => {
    const { dispatch, visibleProfile } = props;

    setIsLoading(true);
    try {
      const response = await dispatch(
        getIntegrationCode(visibleProfile.GroupId)
      );

      setCode(response.value.code);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const integrationData = [
    {
      title: 'Prompt EMR',
      siteUrl: 'https://promptemr.com/',
      imgUrl: 'https://ptw-mobile-images.s3.amazonaws.com/prompt/Logo_iso.png',
    },
    // {
    //   title: 'pVerify',
    //   siteUrl: 'https://www.pverify.com/',
    //   imgUrl:
    //     'https://www.pverify.com/wp-content/uploads/2021/09/pVerify-logo.png',
    // },
  ];

  const openNotification = () => {
    notification.open({
      message: 'Data Copied!',
      description: 'Please paste the corresponding data into Prompt EMR.',
    });
  };

  return (
    <React.Fragment>
      <DropdownNavigation />
      <div className="ptw-main-body">
        <Row>
          <Col md={24}>
            <PageHeader
              className="site-page-header"
              onBack={() => props.history.push('/administrator')}
              title="EMR Integration Partners"
              subTitle="Integrate with our current EMR Partners"
            />
          </Col>
        </Row>
        <Row style={{ margin: 30 }}>
          <Typography.Title level={2}>Single Sign On</Typography.Title>
          <Col md={24}>
            <List
              itemLayout="horizontal"
              dataSource={integrationData}
              renderItem={item => (
                <List.Item actions={[,]} style={{ padding: 12 }}>
                  <List.Item.Meta
                    avatar={
                      <img
                        src={item.imgUrl}
                        alt={item.title}
                        style={{
                          height: 'auto',
                          width: 250,
                          border: '1px solid #ccc',
                          padding: 12,
                        }}
                      />
                    }
                    title={item.title}
                    description={item.siteUrl}
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col>
            <Space align="center">
              {!code && (
                <Button type="primary" onClick={getCode} disabled={isLoading}>
                  Get Integration Code
                  {isLoading && (
                    <i className="fa fa-spinner fa-pulse fa-fw text-icon" />
                  )}
                </Button>
              )}
              {code && (
                <div>
                  <CopyToClipboard
                    text={dataString}
                    onCopy={() => openNotification()}
                  >
                    <div>
                      <Statistic title="SSO Integration Code" value={code} />
                      <Button>
                        <span>Click to Copy to Clipboard &nbsp;</span>
                        <CopyOutlined />
                      </Button>
                    </div>
                  </CopyToClipboard>
                </div>
              )}
            </Space>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  currentLocation: state.routerReducer.location.pathname,
  visibleProfile: state.visibleProfile,
});

export default connect(mapStateToProps)(withRouter(Integration));
