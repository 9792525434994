import React from 'react';
import { Form, Input, Popover, Select, Space, Switch, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import states from '../../../states';
import { useRecoilState } from 'recoil';

const exerciseFrequencyContent = (
  <span>
    Rather than choosing a single frequency for all exercises in the
    prescription, <br />
    use Exercise Level Frequency to choose a specific frequency for each
    exercise.
  </span>
);

const TemplateHeader = ({
  initialValues,
  editHeader,
  importedTemplate,
  exerciseFrequency,
  setExerciseFrequency,
}) => {
  let freq;
  let day;
  let inst;

  if (importedTemplate && importedTemplate.frequency) {
    /*eslint-disable-next-line*/
    freq = parseInt(importedTemplate.frequency);
    day = importedTemplate.perdiem
      ? importedTemplate.perdiem
      : initialValues.Perdiem;
    inst = importedTemplate.instructions;
  } else {
    /*eslint-disable-next-line*/
    freq = parseInt(initialValues.Frequency);
    day = importedTemplate.Perdiem
      ? importedTemplate.Perdiem
      : initialValues.Perdiem;
    inst = importedTemplate.Instructions;
  }

  return (
    <Form layout="vertical" className="mt-3">
      <Space direction="horizontal" className="mb-3">
        <div>
          <Typography.Paragraph>Prescription Frequency</Typography.Paragraph>
          <div className="mt-1">
            <Select
              size="large"
              value={freq}
              defaultValue={freq}
              virtual={false}
              name="frequency"
              placeholder="Enter frequency"
              onChange={value => editHeader('Frequency', value)}
              style={{ minWidth: 120 }}
              disabled={exerciseFrequency}
            >
              <Select.Option value={1}>1x</Select.Option>
              <Select.Option value={2}>2x</Select.Option>
              <Select.Option value={3}>3x</Select.Option>
              <Select.Option value={4}>4x</Select.Option>
              <Select.Option value={5}>5x</Select.Option>
              <Select.Option value={6}>6x</Select.Option>
              <Select.Option value={7}>7x</Select.Option>
              <Select.Option value={8}>8x</Select.Option>
              <Select.Option value={9}>9x</Select.Option>
              <Select.Option value={10}>10x</Select.Option>
            </Select>
            <span className="ml-2 mr-2">Every</span>
            <Select
              size="large"
              value={day}
              defaultValue={day}
              virtual={false}
              name="perdiem"
              placeholder="Enter per diem"
              onChange={value => editHeader('Perdiem', value)}
              style={{ minWidth: 120 }}
              disabled={exerciseFrequency}
            >
              <Select.Option value={'day'}>Day</Select.Option>
              <Select.Option value={'week'}>Week</Select.Option>
              <Select.Option value={'month'}>Month</Select.Option>
            </Select>
          </div>
        </div>
        <div className="ml-5">
          <Typography.Paragraph>
            Exercise-level Frequency{' '}
            <Popover
              title="Exercise-level Frequency"
              content={exerciseFrequencyContent}
            >
              <InfoCircleOutlined />
            </Popover>
          </Typography.Paragraph>
          <Switch
            checked={exerciseFrequency}
            size="large"
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={() => setExerciseFrequency(!exerciseFrequency)}
          />
        </div>
      </Space>
      <Form.Item
        name="instructions"
        label="Prescription Instructions"
        rules={[
          {
            validator: async (_, value) => {
              if (!value.length) {
                editHeader('formError', true);
                return Promise.reject('Please input your instructions.');
              }

              if (value.length > 1000) {
                editHeader('formError', true);
                return Promise.reject('Max length is 1000 characters.');
              }

              editHeader('formError', false);
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input.TextArea
          size="large"
          placeholder="Enter instructions"
          rows={4}
          defaultValue={inst}
          value={inst}
          onChange={e => editHeader('Instructions', e.target.value)}
        />
      </Form.Item>
    </Form>
  );
};

export default TemplateHeader;
