import isEmail from 'validator/lib/isEmail';
import config from '../config';

const regExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const asyncValidate = (values, dispatch, { initialValues }, key) =>
  new Promise((resolve, reject) => {
    if (!key) {
      resolve();
    }

    const value = values[key].trim();
    const contact = key === 'contact' ? 'email or phone number' : 'email';

    if (!value || value === initialValues[key]) {
      resolve();
    } else {
      if (!isEmail(value) && !value.match(regExp)) {
        reject({ [key]: `Invalid ${contact}.` });
      }

      let input = value.trim().toLowerCase();
      if (!isEmail(input)) {
        const phoneNumber = input.replace(/[()\s\-]/g, '');
        if (phoneNumber.length < 11 && !phoneNumber.startsWith('+1')) {
          input = '+1' + phoneNumber;
        } else if (phoneNumber.length < 12 && !phoneNumber.startsWith('+')) {
          input = '+' + phoneNumber;
        }
      }

      fetch(
        `${config.root}/users/external?input=${encodeURIComponent(input)}`
      ).then((response) => {
        response.json().then((data) => {
          if (data.sub === input) {
            resolve();
          } else {
            const isEmailAdd = isEmail(value);
            const type = isEmailAdd ? 'Email' : 'Phone number';

            reject({
              [key]: `${type} must be unique.`
            });
          }
        });
      });
    }
  });

export default asyncValidate;
