import React, { Component } from 'react';
import { Modal, Typography, Popover, Slider } from 'antd';
import setNotification from '../../../utils/setNotification.utils';
import { InfoCircleOutlined } from '@ant-design/icons';

const marks = {
  0: 'No Further Access',
  1: '1 Week',
  2: '2 Weeks',
  3: '3 Weeks',
  4: '1 Month',
  5: '3 Months',
  6: '6 Months',
  7: '1 Year',
  8: 'Unlimited'
};

class DischargeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeValue: 2,
      loading: false
    };
  }

  setActiveIndex = (value) => {
    this.setState({ activeValue: value });
  };

  markValueToWeeks = (value) => {
    switch (value) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      case 4:
        return 4;
      case 5:
        return 12;
      case 6:
        return 24;
      case 7:
        return 52;
      case 8:
        return 9999;
      default:
        return 0;
    }
  };

  handleDischarge = async () => {
    const { dischargeAction, doDischarge, onHide } = this.props;
    const { activeValue } = this.state;

    const weeks = this.markValueToWeeks(activeValue);

    try {
      this.setState({ loading: true });
      await dischargeAction(weeks, !doDischarge ? true : false);
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while discharging the patient.'
      );
    } finally {
      this.setState({ loading: false });
      onHide();
    }
  };

  renderTitle = () => {
    const { doDischarge } = this.props;
    const styles = {
      marginBottom: 0
    };

    if (!doDischarge) {
      const content = (
        <div style={{ width: 450 }}>
          <Typography.Text>
            You can choose to have a discharged patient's Home Exercise Program
            expire after a certain amount of time. After a prescription has
            expired, the patient will be told that they need to book a new
            appointment to access an exercise program prescription. They will
            still be able to access other app features.{' '}
            <Typography.Link>Learn More</Typography.Link>
          </Typography.Text>
        </div>
      );

      return (
        <Typography.Title style={styles} level={5}>
          Discharge Patient?{' '}
          <Popover content={content} placement="top">
            <InfoCircleOutlined />
          </Popover>
        </Typography.Title>
      );
    }

    return (
      <Typography.Title style={styles} level={5}>
        Readmit Patient?
      </Typography.Title>
    );
  };

  renderFormatter = (value) => {
    return marks[value];
  };

  render() {
    const { onHide, doDischarge, hasPendingBill } = this.props;
    const { activeValue, loading } = this.state;

    return (
      <Modal
        title={this.renderTitle()}
        visible={true}
        confirmLoading={loading}
        maskClosable={false}
        centered
        width={1000}
        okText={!doDischarge ? 'Discharge' : 'Readmit'}
        okButtonProps={{
          size: 'large',
          type: 'primary',
          className: 'ptw-btn btn-primary'
        }}
        onOk={this.handleDischarge}
        cancelText="Cancel"
        cancelButtonProps={{
          size: 'large',
          type: 'default',
          className: 'ptw-btn'
        }}
        onCancel={onHide}
      >
        {!doDischarge ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography.Paragraph style={{ textAlign: 'center' }}>
              How long do you want this patient to have continued access to
              their Home Exercise Program?
            </Typography.Paragraph>

            <Slider
              style={{
                width: 800,
                marginBottom: hasPendingBill ? 0 : 46
              }}
              tooltipVisible
              autoFocus
              dots
              max={8}
              value={activeValue}
              tooltip={{
                formatter: (value) => this.renderFormatter(value),
                placement: 'bottom'
              }}
              onChange={(value) => {
                this.setActiveIndex(value);
              }}
            />

            {hasPendingBill && (
              <Typography.Paragraph
                strong
                type="danger"
                style={{
                  marginTop: 46
                }}
              >
                NOTE: There are pending billable RTM codes available, are you
                sure you want to discharge the patient?
              </Typography.Paragraph>
            )}
          </div>
        ) : (
          <React.Fragment>
            <Typography.Paragraph>
              After readmission, this patient will be able to access their Home
              Exercise Program. They will also receive an email notifying them
              with instructions to access the app.
            </Typography.Paragraph>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

export default DischargeModal;
