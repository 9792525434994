import React, { useState, useEffect } from 'react';
import {
  editCustomizations,
  getCustomizations
} from '../groups/actions/groups';
import { Link } from 'react-router-dom';
import { Col, Row, Breadcrumb } from 'react-bootstrap';
import { Alert, Collapse, Typography } from 'antd';
import IntroMessageForm from '../groups/group-app-customizations/IntroMessageForm';
import CustomHomeContentForm from '../groups/group-app-customizations/CustomHomeContentForm';
import Loading from '../../components/Loading';
import setNotification from '../../utils/setNotification.utils';

//Events for backend
const EDIT_INTRO_MESSAGE = 'EDIT_INTRO_MESSAGE',
  EDIT_CUSTOM_CONTENT = 'EDIT_CUSTOM_CONTENT';

const AnnouncementsPage = props => {
  const { groupId, dispatch } = props;

  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // states
  const [introText, setIntroText] = useState('');
  const [customContent, setCustomContent] = useState('');

  useEffect(() => {
    fetchCustomizationData();
  }, []);

  //get saved information
  const fetchCustomizationData = async () => {
    setPageLoading(true);
    if (groupId) {
      try {
        const res = await dispatch(getCustomizations(groupId));
        const Item = res.action.payload.Item;

        if (Item) {
          setIntroText(Item.IntroText);
          setCustomContent(Item.CustomContent);
        }

        setPageLoading(false);
      } catch (err) {
        setPageLoading(false);
        setNotification(
          'error',
          'Error!',
          'An error occurred while fetching costumization data.'
        );
      }
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setErrorMessage(null);
      }, 5000);
    }
  }, [error]);

  // handle form submit functions
  const handleSubmitCustomization = async (event, values) => {
    let requestBody = { ...values, groupId, customizationEvent: event };

    setIsLoading(true);
    try {
      const response = await dispatch(editCustomizations(event, requestBody));

      if (response) {
        setIsLoading(false);
        setNotification('success', 'Success!', 'Changes has been saved.');
      }
    } catch (err) {
      setIsLoading(false);
      setNotification(
        'error',
        'Error!',
        'An error occurred while saving data.'
      );
    }
  };

  return (
    <div style={{ padding: '15px' }}>
      {error && <Alert message={errorMessage} type="error" />}
      {pageLoading ? (
        <Loading isLoading={pageLoading}>
          <Typography.Text>Loading...</Typography.Text>
        </Loading>
      ) : (
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={'/administrator'}>Admin</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item active>In-App Customizations</Breadcrumb.Item>
          </Breadcrumb>
          <Collapse accordion defaultActiveKey={['1']}>
            <Collapse.Panel header="Set Intro Pop-up Message" key="1">
              <IntroMessageForm
                introText={introText}
                setIntroText={setIntroText}
                event={EDIT_INTRO_MESSAGE}
                handleSubmitCustomization={handleSubmitCustomization}
                isLoading={isLoading}
              />
            </Collapse.Panel>
            <Collapse.Panel header="Set Home Page Announcement" key="2">
              <CustomHomeContentForm
                customContent={customContent}
                setCustomContent={setCustomContent}
                event={EDIT_CUSTOM_CONTENT}
                handleSubmitCustomization={handleSubmitCustomization}
                isLoading={isLoading}
              />
            </Collapse.Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default AnnouncementsPage;
