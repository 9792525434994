import { useState, useRef, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRecoilState } from 'recoil';
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { titleCase } from 'voca';

import { ImageSelector } from '../../../components/file-uploader';
import { uploadImageDirect } from '../../../components/file-uploader/actions';
import { getGroupExercises } from '../actions/groupExercises';
import {
  popDescription,
  popImage,
  popImageCompatibleFormats,
  popName,
  popParameters,
  popTag,
  popVideo,
} from './pops';
import { exerciseImgValidator } from '../validators/groupExercises';
import { toCamelCaseObjKeys } from '../../../utils/object.utils';

import cptCodes from '../../../features/export-hep-to-prompt/CptCodes.json';
import states from '../../../states';
import services from '../../../services';

import ModalForm from '../../../components/ModalForm';
import ProfileImage from '../../patients/Modals/ProfileImage';
import setNotification from '../../../utils/setNotification.utils';

const YOUTUBE_REGEX = `(youtu.*be.*)(com)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))`;
const IMAGE_URI = 'https://d2p805pqn3eul9.cloudfront.net/';

const ClinicExerciseForm = ({
  visibleProfile,
  getGroupExercises,
  uploadImage,
  values,
  isOpen,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [parametersError, setParametersError] = useState(false);
  const [formData, setFormData] = useState({
    validImage: true,
    img: null,
    base64: null,
    file: null,
    cropping: false,
    removeFile: null,
  });

  const [exercises, setExercises] = useRecoilState(states.exercises);
  const [form] = Form.useForm();

  const imgSelectorRef = useRef();
  const promptIntegrated = visibleProfile?.GroupInfo?.EnablePrompt;

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        exerciseName: titleCase(values.ExerciseName || ''),
        sets: values.Sets || '',
        reps: values.Reps || '',
        hold: values.Hold || '',
        tags: values.Tag || '',
        instructions: values.Instructions || '',
        videoUrl: values.Video || '',
        cptCode: values.CPTCode || '',
      });

      setFormData(prevState => ({
        ...prevState,
        img: values.image || values.imageUrl || null,
      }));
    }
  }, [isOpen]);

  const onFileSelected = async (file, errors) => {
    const isValid = _.isEmpty(errors);

    if (isValid) {
      setFormData({
        ...formData,
        cropping: true,
        validImage: true,
        file,
      });
    } else {
      console.log('errors', errors);
      setNotification(
        'error',
        'Upload Error!',
        'Image size must be less than 2.5MB. Use images less than 1MB for faster loading time.'
      );
    }
  };

  const onFileRemoved = () => {
    setFormData(prevState => ({
      ...prevState,
      validImage: true,
      removeFile: null,
    }));
  };

  const handleImageCrop = (blob, base64Str) => {
    setFormData(prevState => ({
      ...prevState,
      base64: base64Str,
      file: blob,
      cropping: false,
    }));
  };

  const handleExitCrop = () => {
    setFormData(prevState => ({
      ...prevState,
      img: null,
      cropping: false,
      file: null,
    }));
  };

  const validateExerciseName = str => {
    if (str.length === 0) {
      return true;
    } else if (!!values?.Id) {
      return false;
    } else {
      return exercises.list.some(ex => ex.exerciseName === str.toLowerCase());
    }
  };

  const validateVideoUrl = async str => {
    if (str.match(YOUTUBE_REGEX)) {
      return getYoutubeThumbnail(str).length === 11;
    } else if (str.startsWith('https://vimeo.com')) {
      return getVimeoThumbnail(str).then(videoId => {
        return !!videoId;
      });
    } else {
      setFormData(prevState => ({
        ...prevState,
        img: null,
      }));

      return false;
    }
  };

  const getYoutubeThumbnail = url => {
    const results = url.match('[\\?&]v=([^&#]*)');
    const videoId = results === null ? url : results[1];

    setFormData(prevState => ({
      ...prevState,
      img: `http://img.youtube.com/vi/${videoId}/0.jpg`,
    }));

    return videoId;
  };

  const getVimeoThumbnail = async url => {
    let videoId = '';
    await fetch(`https://vimeo.com/api/oembed.json?url=${url}`).then(
      async response => {
        await response.json().then(data => {
          videoId = data.video_id;

          setFormData(prevState => ({
            ...prevState,
            img: data.thumbnail_url,
          }));
        });
      }
    );

    return videoId;
  };

  const handleSubmit = async form => {
    try {
      setLoading(true);

      const name = form.exerciseName.trim().toLowerCase();
      const params = {
        active: true,
        Active: true,
        exerciseName: name,
        ExerciseName: name,
        GroupId: visibleProfile.GroupId,
        SearchTerm: name.replace(/\s/g, ''),
        Instructions: form.instructions,
        Reps: form.reps || '',
        Sets: form.sets || '',
        Hold: form.hold || '',
        Tag: form.tags || '',
        Video: form.videoUrl || '',
      };

      if (form.cptCode) {
        params.CPTCode = form.cptCode;
      }

      let response = null;
      if (!!values?.Id) {
        let image = params.Video === values.Video ? values.image : formData.img;
        if (formData.base64) {
          const type = formData.base64.split(';')[0].split('/')[1];
          const filename = `${visibleProfile.GroupId}/${values.Id}.${type}`;

          await uploadImage(filename, formData.file).then(() => {
            image = IMAGE_URI + filename;
          });
        }

        params.image = image;
        response = await services.exercises.updateClinicExercise({
          ...params,
          Id: values.Id,
          Created: values.Created,
        });
      } else {
        params.image = formData.img;
        response = await services.exercises.addClinicExercise(params);

        if (formData.base64) {
          const id = response.data.id;
          const type = formData.base64.split(';')[0].split('/')[1];
          const filename = `${visibleProfile.GroupId}/${id}.${type}`;

          params.image = IMAGE_URI + filename;

          await Promise.all([
            uploadImage(filename, formData.file),
            services.exercises.updateClinicExercise({
              ...params,
              Id: id,
            }),
          ]);
        }
      }

      if (response.status === 200) {
        const newExercise = {
          ...toCamelCaseObjKeys({
            Id: response.data.id,
            Active: params.Active,
            ExerciseName: params.ExerciseName,
            GroupId: params.GroupId,
            Image: params.image,
            SearchTerm: params.SearchTerm,
            Instructions: params.Instructions,
            Reps: params.Reps,
            Sets: params.Sets,
            Tag: params.Tag,
            Video: params.Video,
          }),
        };

        setExercises(prevState => {
          const index = prevState.custom.exercises.length;
          const listCopy = [...prevState.list];
          const unmappedCopy = [...prevState.unmapped];

          listCopy.splice(index, 0, newExercise);
          unmappedCopy.splice(index, 0, newExercise);

          return {
            ...prevState,
            unmapped: unmappedCopy,
            list: listCopy,
          };
        });

        notification.success({
          message: 'Success!',
          description: `Clinic exercise successfully ${
            !!values?.Id ? 'updated' : 'added'
          }.`,
        });

        handleCancelModal();
        getGroupExercises(params.GroupId);
      } else {
        throw response;
      }
    } catch (error) {
      console.log('[clinic exercise]', error);

      notification.error({
        message: 'Error!',
        description: `An error occurred while ${
          !!values?.Id ? 'updating' : 'adding'
        } clinic exercise.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelModal = () => {
    form.resetFields();

    setParametersError(false);
    setFormData(prevState => ({
      ...prevState,
      base64: null,
    }));

    handleExitCrop();
    onCancel();
  };

  return (
    <Fragment>
      {formData.cropping && (
        <ProfileImage
          show={formData.cropping}
          image={formData.file}
          onSubmit={handleImageCrop}
          onExit={handleExitCrop}
          onHide={handleExitCrop}
          closeButton={handleExitCrop}
          isExerciseImage={true}
        />
      )}

      <ModalForm
        width="60%"
        form="clinicExercise"
        header={(!!values?.Id ? 'Edit' : 'Add') + ' Exercise'}
        showModal={isOpen}
        handleClose={handleCancelModal}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <Form
          layout="vertical"
          id="clinicExercise"
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={[24, 0]}>
            <Col lg={12} md={12} sm={12}>
              <Row gutter={[16, 0]}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="exerciseName"
                    label="Exercise Name"
                    tooltip={popName}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your exercise name.',
                      },
                      { max: 256, message: 'Max length is 256 only.' },
                      {
                        message: 'Exercise name already exists.',
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                          }

                          return validateExerciseName(value)
                            ? Promise.reject()
                            : Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      size="large"
                      placeholder="Enter exercise name"
                      name="exerciseName"
                    />
                  </Form.Item>
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    style={{ marginBottom: -40, fontWeight: 'bold' }}
                    label="Parameters"
                    tooltip={popParameters}
                  />

                  <Row gutter={[16, 0]}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                      <Form.Item
                        name="sets"
                        label="Sets"
                        dependencies={['reps', 'hold']}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value &&
                                !getFieldValue('reps') &&
                                !getFieldValue('hold')
                              ) {
                                setParametersError(true);
                                return Promise.reject();
                              } else {
                                setParametersError(false);
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          size="large"
                          placeholder="Enter sets"
                          name="sets"
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={24} xs={24}>
                      <Form.Item
                        name="reps"
                        label="Reps"
                        dependencies={['sets', 'hold']}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value &&
                                !getFieldValue('sets') &&
                                !getFieldValue('hold')
                              ) {
                                setParametersError(true);
                                return Promise.reject();
                              } else {
                                setParametersError(false);
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          size="large"
                          placeholder="Enter reps"
                          name="reps"
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={24} xs={24}>
                      <Form.Item
                        name="hold"
                        label="Hold"
                        dependencies={['sets', 'reps']}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value &&
                                !getFieldValue('sets') &&
                                !getFieldValue('reps')
                              ) {
                                setParametersError(true);
                                return Promise.reject();
                              } else {
                                setParametersError(false);
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          size="large"
                          placeholder="Enter hold"
                          name="hold"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {parametersError && (
                    <Typography.Paragraph
                      type="danger"
                      style={{ marginTop: -20 }}
                    >
                      Please fill in at least one parameter field.
                    </Typography.Paragraph>
                  )}
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={promptIntegrated ? 12 : 24}>
                      <Form.Item name="tags" label="Tags" tooltip={popTag}>
                        <Input
                          type="text"
                          size="large"
                          placeholder="Enter tags"
                          name="tags"
                        />
                      </Form.Item>
                    </Col>

                    {promptIntegrated && (
                      <Col span={12}>
                        <Form.Item
                          name="cptCode"
                          label="CPT Code"
                          tooltip="Assign a default CPT Code to this exercise. When using the Export HEP to Visit in Prompt feature, this CPT Code will automatically be assigned. You can still update it later if needed."
                        >
                          <Select
                            showSearch
                            size="large"
                            placeholder="CPT Code"
                          >
                            {cptCodes.map((item, i) => (
                              <Fragment key={i}>
                                <Select.Option value={item.CPT_Code}>
                                  <Tooltip
                                    placement="right"
                                    title={item.Description}
                                  >
                                    {item.CPT_Code}
                                  </Tooltip>
                                </Select.Option>
                              </Fragment>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="instructions"
                    label="Instructions"
                    tooltip={popDescription}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your instructions.',
                      },
                      { max: 1000, message: 'Max length is 1000 characters.' },
                    ]}
                  >
                    <Input.TextArea
                      size="large"
                      placeholder="Enter instructions"
                      name="instructions"
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col lg={12} md={12} sm={12}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                  name="videoUrl"
                  label="Exercise Video URL"
                  tooltip={popVideo}
                  validateTrigger="onBlur"
                  rules={[
                    {
                      message: 'Please input a valid exercise video URL.',
                      validator: async (_, value) => {
                        if (!value || value === values.Video) {
                          return Promise.resolve();
                        }

                        const valid = await validateVideoUrl(value);
                        if (valid) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject();
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    type="text"
                    size="large"
                    placeholder="Enter exercise video URL"
                    name="videoUrl"
                    rows={4}
                  />
                </Form.Item>
                <Tooltip
                  placement="bottomRight"
                  title={popImageCompatibleFormats}
                  overlayInnerStyle={{
                    width: 400,
                  }}
                >
                  <Typography.Link
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      zIndex: 9999,
                    }}
                  >
                    See Compatible URL Formats
                  </Typography.Link>
                </Tooltip>
              </Col>

              <Col lg={24} md={24}>
                <Form.Item
                  label="Exercise Image"
                  tooltip={popImage}
                  help={
                    !formData.validImage && (
                      <Typography.Paragraph type="danger">
                        Selected image is either not in JPG, JPEG, or PNG format
                        or has a size of more than 2.5MB.
                      </Typography.Paragraph>
                    )
                  }
                >
                  <div className={!formData.validImage ? 'has-error' : ''}>
                    <ImageSelector
                      width="auto"
                      height={326}
                      ref={imgSelectorRef}
                      isUser={false}
                      defaultImageUrl={formData.base64 || formData.img}
                      src={formData.base64 || formData.img}
                      onFileSelected={onFileSelected}
                      onFileRemoved={onFileRemoved}
                      imageValidator={exerciseImgValidator}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </Form>
      </ModalForm>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
});

const mapDispatchToProps = dispatch => ({
  getGroupExercises: id => dispatch(getGroupExercises(id)),
  uploadImage: (name, file) => dispatch(uploadImageDirect(name, file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClinicExerciseForm);
