import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Space, Tooltip, Button, Typography, Switch, Modal, Spin } from 'antd';
import ReactGA from 'react-ga';
import v from 'voca';
import {
  PrinterOutlined,
  EditOutlined,
  FileOutlined,
  BookOutlined,
  LoadingOutlined,
  FileAddOutlined
} from '@ant-design/icons';
import '../../../../styles/atomic.css';
import { exportPrescriptionToTemplate } from '../../../patients/actions/prescription';
import { currentUserGroupSelector } from '../../../exercise-library/selectors';
import DocumentNowModal from '../../../patients/Modals/DocumentNowModal';
import { exportHEP } from '../../../patients/actions/prompt';

import setNotification from '../../../../utils/setNotification.utils';
import ExportHEPModal from '../../../patients/Modals/ExportHEPModal';
import ExportHEPGeneratedIdModal from '../../../patients/Modals/ExportHEPGeneratedIdModal';
import PopupBlockerModal from '../../../patients/Modals/PopupBlockerModal';
import PatientExercisesGrid from '../../../patients/PatientExercisesGrid';
import ExerciseDetailModal from '../../../patients/Modals/ExerciseDetailModal';

import { getRecoil, setRecoil } from 'recoil-nexus';
import { toCamelCaseObjKeys } from '../../../../utils/object.utils';
import states from '../../../../states';
import services from '../../../../services';
import PrintPreviewModal from '../PrintPreviewModal';
import CaseIdModal from '../../../../components/CaseIdModal';

const loadingToggleRx = () =>
  Modal.info({
    title: (
      <Typography.Title level={1} className="text-center">
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 48 }} />}
          size="large"
        />
      </Typography.Title>
    ),
    content: (
      <Typography.Paragraph className="text-center">
        Updating prescription, please wait...
      </Typography.Paragraph>
    ),
    centered: true,
    okButtonProps: {
      style: {
        display: 'none'
      }
    },
    icon: null
  });

class PrescriptionDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      base64images: [],
      page: 1,
      generatingPDF: false,
      showPopupBlockerModal: false,
      showDocumentNowModal: false,
      showExportHEPModal: false,
      showExportHEPGeneratedIdModal: false,
      generatedId: null,
      videoPreview: false,
      printPreview: false,
      previewEx: null,
      loadingExport: false
    };

    this.printRef = React.createRef();
  }

  hidePopupBlockerModal = () => {
    this.setState({ showPopupBlockerModal: false });
  };

  hideDocumentNow = () => {
    this.setState({ showDocumentNowModal: false });
  };

  createTemplate = (prescription) => {
    const { history, dispatch, group } = this.props;
    dispatch(exportPrescriptionToTemplate(prescription));

    ReactGA.event({
      category: 'Template',
      action: 'Export Template from prescription',
      label: group.id
    });

    history.push('/patients?toTemplate=true');
  };

  documentNow = () => {
    const { group } = this.props;

    this.setState({ showDocumentNowModal: true });
    ReactGA.event({
      category: 'Patient',
      action: 'Document Now Button',
      label: group.name
    });
  };

  previewVideo = (ex) => {
    this.setState({ videoPreview: true, previewEx: ex });
  };

  closePreviewVideo = () => {
    this.setState({ videoPreview: false, previewEx: null });
  };

  togglExportHEPModal = () => {
    const { showExportHEPModal } = this.state;
    this.setState({
      showExportHEPModal: !showExportHEPModal
    });
  };

  togglExportHEPGeneratedIdModal = () => {
    const { showExportHEPGeneratedIdModal } = this.state;
    this.setState({
      showExportHEPGeneratedIdModal: !showExportHEPGeneratedIdModal
    });
  };

  handleSetExportData = async (data) => {
    const { dispatch, currentPatient } = this.props;
    if (currentPatient) {
      if (data) {
        if (currentPatient.Sub) {
          this.setState({
            loadingExport: true
          });

          dispatch(
            exportHEP({
              Sub: currentPatient.Sub,
              HEP: data
            })
          )
            .then((res) => {
              if (res.value.message === 'Success') {
                this.setState({
                  loadingExport: false,
                  generatedId: res.value.data,
                  showExportHEPGeneratedIdModal: true
                });

                setNotification(
                  'success',
                  'Success!',
                  'Export HEP Id has been generated.'
                );
              }
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                loadingExport: false
              });
              setNotification('error', 'Error!', 'Export HEP failed.');
            });
        }
      }
    }
  };

  handleTogglePrescription = async (checked) => {
    const { prescriptionItem, currentPatient } = this.props;
    if (prescriptionItem) {
      const loadingToggle = loadingToggleRx();

      try {
        const { id } = prescriptionItem;
        const response = await services.prescription.togglePrescription(
          id,
          currentPatient.Sub,
          checked
        );

        const prescription = getRecoil(states.prescription);
        const rxListCopy = prescription.list.slice();
        const updatedRxList = [...rxListCopy];
        const data = toCamelCaseObjKeys(response.data);

        if (!id) {
          updatedRxList.shift();
          updatedRxList.push(data);
        } else {
          const index = rxListCopy.findIndex((rx) => rx.id === data.id);
          updatedRxList[index] = data;
        }

        setRecoil(states.prescription, {
          ...prescription,
          form: data,
          list: updatedRxList
        });

        setNotification(
          'success',
          'Success!',
          checked
            ? 'Prescription has been enabled.'
            : 'Prescription has been disabled.'
        );
      } catch (error) {
        setNotification(
          'error',
          'Error!',
          'An error occurred while updating prescription.'
        );
      } finally {
        loadingToggle.destroy();
      }
    }
  };

  render() {
    const {
      siteDesign,
      currentPatient,
      visibleProfile,
      visibleProfile: { GroupInfo },
      dispatch,
      onEditClick,
      prescriptionItem
    } = this.props;
    const {
      showPopupBlockerModal,
      showDocumentNowModal,
      showExportHEPModal,
      showExportHEPGeneratedIdModal,
      videoPreview,
      printPreview,
      previewEx,
      generatedId,
      loadingExport
    } = this.state;
    const prescription = getRecoil(states.prescription);
    const isRxActive =
      prescriptionItem && prescriptionItem.hasOwnProperty('active')
        ? prescriptionItem.active
        : true;

    const printProps = {
      currentPatient: {
        ...currentPatient,
        Creator: visibleProfile.EmailAddress,
        GroupInfo: {
          ...currentPatient.GroupInfo,
          PrintEmail: currentPatient.GroupInfo?.PrintEmail || 'provider'
        }
      },
      prescription: prescriptionItem.prescription,
      visibleProfile,
      siteDesign
    };

    const rxId = prescriptionItem.id;
    const rxItem = prescription.list.find((rx) => rx.id === rxId);
    const rxCaseId = rxItem?.caseId;

    return (
      <React.Fragment>
        <PrintPreviewModal
          currentPatient={currentPatient}
          open={printPreview}
          handleClose={() => this.setState({ printPreview: false })}
          prescription={prescriptionItem.prescription}
          printProps={printProps}
        />
        {videoPreview && (
          <ExerciseDetailModal
            currentExercise={previewEx}
            closeModal={this.closePreviewVideo}
            favorites={null}
          />
        )}

        {showPopupBlockerModal && (
          <PopupBlockerModal closeWindow={this.hidePopupBlockerModal} />
        )}

        {showDocumentNowModal && (
          <DocumentNowModal
            dispatch={dispatch}
            prescription={prescriptionItem.prescription}
            closeWindow={this.hideDocumentNow}
          />
        )}

        {showExportHEPModal && (
          <ExportHEPModal
            togglExportHEPModal={this.togglExportHEPModal}
            exercises={prescriptionItem.prescription.exercises}
            setExportData={this.handleSetExportData}
            loading={loadingExport}
          />
        )}

        {showExportHEPGeneratedIdModal && (
          <ExportHEPGeneratedIdModal
            showExportHEPGeneratedIdModal={showExportHEPGeneratedIdModal}
            togglExportHEPGeneratedIdModal={this.togglExportHEPGeneratedIdModal}
            generatedId={generatedId}
          />
        )}
        <div className="tab-header">
          <div />

          <Space direction="horizontal" size={20}>
            {prescription.list.length > 1 && (
              <Tooltip title="Enable/Disable Prescription">
                <Switch
                  defaultChecked={isRxActive}
                  onChange={this.handleTogglePrescription}
                />
              </Tooltip>
            )}

            <Tooltip title="Edit Prescription">
              <Button
                onClick={onEditClick}
                disabled={
                  prescription.form.active === undefined
                    ? false
                    : !prescription.form.active
                }
                shape="circle"
                type="link"
                className="btn-link"
                icon={<EditOutlined style={{ fontSize: 24 }} />}
              />
            </Tooltip>

            {/* <ReactToPrint
              removeAfterPrint={true}
              onBeforeGetContent={()=>{document.title = `${currentPatient.FirstName}_${currentPatient.LastName}`}}
              onAfterPrint={()=>{document.title = "PT Wired"}}
              content={() => this.printRef.current}
              trigger={() => (
                <Tooltip title="Print Prescription">
                  <Button
                    shape="circle"
                    type="link"
                    className="btn-link"
                    disabled={generatingPDF}
                    icon={<PrinterOutlined style={{ fontSize: 24 }} />}
                  />
                </Tooltip>
              )}
            /> */}
            <Tooltip title="Print Prescription">
              <Button
                shape="circle"
                type="link"
                className="btn-link"
                // disabled={generatingPDF}
                onClick={() => this.setState({ printPreview: true })}
                icon={<PrinterOutlined style={{ fontSize: 24 }} />}
              />
            </Tooltip>

            <Tooltip title="Document">
              <Button
                onClick={() => {
                  this.documentNow(prescriptionItem.prescription);
                }}
                shape="circle"
                type="link"
                className="btn-link"
                icon={<FileOutlined style={{ fontSize: 24 }} />}
              />
            </Tooltip>

            <Tooltip title="Save as Template">
              <Button
                onClick={() => {
                  this.createTemplate(prescriptionItem.prescription);
                }}
                shape="circle"
                type="link"
                className="btn-link"
                icon={<BookOutlined style={{ fontSize: 24 }} />}
              />
            </Tooltip>

            {(!!GroupInfo.EnablePrompt || !!GroupInfo.EnableRaintree) && (
              <CaseIdModal
                component={
                  <Tooltip title="Assign Case Id">
                    <Button
                      shape="circle"
                      type="link"
                      className="btn-link"
                      icon={
                        <FileAddOutlined
                          style={{
                            fontSize: 24
                          }}
                        />
                      }
                    />
                  </Tooltip>
                }
                prescriptionId={prescriptionItem.id}
                patientSub={currentPatient.Sub}
              />
            )}

            {!!GroupInfo.EnablePrompt && (
              <Tooltip title="Export to Visit in Prompt">
                <Button
                  onClick={this.togglExportHEPModal}
                  shape="circle"
                  type="link"
                  className="btn-link"
                  style={{ marginTop: -12 }}
                  icon={
                    <img
                      src="https://ptw-mobile-images.s3.amazonaws.com/prompt/Brand+Mark+Red.png"
                      alt="prompt-logo"
                      style={{ height: 30, width: 'auto' }}
                    />
                  }
                />
              </Tooltip>
            )}
          </Space>
        </div>
        <Space direction="vertical" size={16} className="full-width">
          {prescriptionItem.name && (
            <div>
              <Typography.Text type="secondary">Name</Typography.Text>
              <Typography.Title level={5} style={{ marginTop: 0 }}>
                {prescriptionItem.name}
              </Typography.Title>
            </div>
          )}

          {prescriptionItem &&
            prescriptionItem.prescription.exercises &&
            prescriptionItem.prescription.exercises[0] &&
            !prescriptionItem.prescription.exercises[0]['frequency'] && (
              <div>
                <Typography.Text type="secondary">Frequency</Typography.Text>
                <Typography.Paragraph>
                  {prescriptionItem.prescription.perdiem
                    ? `${prescriptionItem.prescription.frequency}x per ${prescriptionItem.prescription.perdiem}`
                    : v.capitalize(prescriptionItem.frequency)}
                </Typography.Paragraph>
              </div>
            )}

          {prescriptionItem && prescriptionItem.prescription.instructions && (
            <div>
              <Typography.Text type="secondary">Instructions</Typography.Text>
              <Typography.Paragraph>
                {v.capitalize(prescriptionItem.prescription.instructions)}
              </Typography.Paragraph>
            </div>
          )}

          {(!!GroupInfo.EnablePrompt || !!GroupInfo.EnableRaintree) &&
            !!rxCaseId && (
              <div>
                <Typography.Text type="secondary">Case Id</Typography.Text>
                <Typography.Paragraph>{rxCaseId}</Typography.Paragraph>
              </div>
            )}

          {prescriptionItem && prescriptionItem.prescription.exercises && (
            <div style={{ marginTop: 24 }}>
              <PatientExercisesGrid
                notClickable={true}
                exercises={prescriptionItem.prescription.exercises}
                displayAddCustomButton={false}
                groupId={currentPatient.GroupId}
                fromPatientPrescription={true}
                onExerciseCardClick={this.previewVideo}
                onExerciseTitleClick={this.previewVideo}
              />
            </div>
          )}
        </Space>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    visibleProfile: state.visibleProfile,
    currentPatient: state.patients.currentPatient,
    printModalOpen: state.patients.printModalOpen,
    siteDesign: state.publicPage.design,
    group: currentUserGroupSelector(state)
  };
}

export default withRouter(connect(mapStateToProps)(PrescriptionDetails));
