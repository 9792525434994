import { Tabs } from 'antd';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DropdownNavigation from '../../components/DropdownNavigation';
import UsageReports from '../UsageReports';
import RTMReports from '../RTMReports';
import NPSReports from '../NPSReports';

const Reports = ({ visibleProfile }) => {
  const [active, setActive] = useState('usage');

  const rtmEnabled = !!visibleProfile?.GroupInfo?.EnableRTM;
  const ptuEnabled = !!visibleProfile?.GroupInfo?.EnablePTU;

  return (
    <>
      <DropdownNavigation />

      <div style={{ padding: '0 3em' }}>
        <Breadcrumb style={{ paddingTop: 7, paddingBottom: 12 }}>
          <Breadcrumb.Item>
            <Link to={'/administrator/'}>Admin</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Reports</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Tabs
        defaultActiveKey="usage"
        activeKey={active}
        onChange={key => setActive(key)}
      >
        <Tabs.TabPane key="usage" tab="Usage Reports">
          <UsageReports />
        </Tabs.TabPane>

        {rtmEnabled && (
          <Tabs.TabPane key="rtm" tab="RTM Reports">
            <RTMReports />
          </Tabs.TabPane>
        )}

        {ptuEnabled && (
          <Tabs.TabPane key="nps" tab="NPS Reports">
            <NPSReports />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile
});

export default connect(mapStateToProps)(withRouter(Reports));
