import React, { Component } from 'react';
import GroupForm from './GroupForm';
import { Tabs, Tab, Breadcrumb } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ClinicsPage from './group-clinics/ClinicsPage';
import { FULFILLED } from 'redux-promise-middleware';
import voca from 'voca';
import _ from 'lodash';
import {
  postGroup,
  updateGroup,
  getGroup,
  getGroups,
  addingGroup,
  editingGroup,
  GET_GROUP,
  updateCurrentGroup,
  setGroupFormType,
  FormTypes
} from './actions/groups';
import GroupAdmin from './group-admin';
import { titleCase } from './stringHelpers';
import DispatchLinkItem from '../../components/DispatchLinkItem';
import GroupDesignPage from './group-design';
import Loading from '../../components/Loading';
import { isLoading, doneLoading } from './actions/groupsLoadingState';
import { success, error } from 'react-notification-system-redux';
import DropdownNavigation from '../../components/DropdownNavigation';
import AppCustomizations from './group-app-customizations';
import GroupNotifications from './group-notifications';
import RtmConsentForm from './rtm-consent-form';
import Utilities from '../../pages/SuperAdmin/Groups/Utilities';

const isCurrentlySelector = (state) => state.currentUser.isCurrently;
const loadingMS = 500;

class GroupPage extends Component {
  constructor(props) {
    super(props);
    this.disableConfirmMessage =
      'Are you sure you want to inactivate this Group and any users associated with it?';
    this.enableConfirmMessage =
      'Are you sure you want to enable this Group and any Clinics and Users associated with it?';
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { dispatch, match, routedGroup } = this.props;
    dispatch(isLoading());
    //if not a new group
    if (match.params.id !== 'new') {
      dispatch(editingGroup());
      dispatch(getGroup(match.params.id)).then(({ action }) => {
        if (action.type === `${GET_GROUP}_${FULFILLED}`) {
          dispatch(setGroupFormType(FormTypes.EDIT, routedGroup));
        } else {
          //show 404 error
        }
        setTimeout(() => {
          dispatch(doneLoading());
        }, loadingMS);
      });
    } else {
      //adding new group
      dispatch(addingGroup());
      dispatch(setGroupFormType(FormTypes.NEW));
      setTimeout(() => {
        dispatch(doneLoading());
      }, loadingMS);
    }
  }

  handleSubmit(values) {
    const { dispatch, formType, routedGroup, history, currentGroup } =
      this.props;

    _.each(values, (val, key) => {
      if (typeof val === 'string') values[key] = val.trim();
    });

    if (values && values.Integrations) {
      if (values.Integrations[0]) {
        const BetterPTUrl = values.Integrations[0].Url;
        values = { ...values, BetterPTUrl };
      }

      if (values.Integrations[1]) {
        const GoCardConnectUrl = values.Integrations[1].Url;
        values = { ...values, GoCardConnectUrl };
      }
    }

    try {
      values.EnableRTM = JSON.parse(values.EnableRTM);
    } catch (error) {
      values.EnableRTM = false;
    }

    try {
      values.EnableRTMConsent = JSON.parse(values.EnableRTMConsent);
    } catch (error) {
      values.EnableRTMConsent = false;
    }

    try {
      values.EnablePTU = JSON.parse(values.EnablePTU);
    } catch (error) {
      values.EnablePTU = false;
    }

    try {
      values.EnablePrompt = JSON.parse(values.EnablePrompt);
    } catch (error) {
      values.EnablePrompt = false;
    }

    try {
      values.EnableRaintree = JSON.parse(values.EnableRaintree);
    } catch (error) {
      values.EnableRaintree = false;
    }

    try {
      values.EnableMultipleRx = JSON.parse(values.EnableMultipleRx);
    } catch (error) {
      values.EnableMultipleRx = false;
    }

    try {
      values.EnablePhoneNumber = JSON.parse(values.EnablePhoneNumber);
    } catch (error) {
      values.EnablePhoneNumber = false;
    }

    try {
      values.EnableAppointments = JSON.parse(values.EnableAppointments);
    } catch (error) {
      values.EnableAppointments = true;
    }

    try {
      values.EnableTelehealth = JSON.parse(values.EnableTelehealth);
    } catch (error) {
      values.EnableTelehealth = true;
    }

    let expoProjectName = values.ExpoProjectName;
    if (expoProjectName && !expoProjectName.startsWith('@ptwired/')) {
      expoProjectName = '@ptwired/' + expoProjectName;
    }

    const params = {
      ...values,
      ExpoProjectName: expoProjectName
    };

    let submitAction;
    if (formType === FormTypes.EDIT) {
      params.id = routedGroup.id;
      if (values.active !== currentGroup.active) {
        if (values.active === 'true') {
          params.active = 'true';
          submitAction = updateGroup(params);
        } else {
          params.active = 'false';
          submitAction = updateGroup(params);
        }
      } else {
        submitAction = updateGroup(params);
      }
    } else {
      submitAction = postGroup(params);
    }

    return dispatch(submitAction).then(({ action }) => {
      if (
        action.payload.status === 200 ||
        action.payload.message === 'Success' ||
        action.payload === 'Success'
      ) {
        if (formType === FormTypes.NEW) {
          dispatch(setGroupFormType(FormTypes.EDIT, values));
        } else {
          dispatch(updateCurrentGroup(values));
        }
        dispatch(getGroups()).then(() =>
          history.push(`/groups/${encodeURI(voca.lowerCase(values.name))}`)
        );
      } else {
        this.props.dispatch(
          error({
            title: 'Could not update group',
            position: 'tr',
            color: 'red'
          })
        );
        return;
      }
      history.push('/groups');
      this.props.dispatch(
        success({ title: 'Group Successfully Updated', position: 'tr' })
      );
      return;
    });
  }

  handleSelect(key) {
    const { isCurrently, dispatch } = this.props;
    switch (key) {
      case 1: //info tab
        return isCurrently.isNewGroup
          ? dispatch(addingGroup())
          : dispatch(editingGroup());
      default:
    }
  }

  render() {
    const {
      handleSubmit,
      props: { match, formType, currentGroup, routedGroup, dispatch }
    } = this;

    const groupName = match.params.id;
    const isNewForm = formType === FormTypes.NEW;
    Object.keys(currentGroup).forEach((val) => {
      if (
        val === 'name' ||
        val === 'address' ||
        val === 'city' ||
        val === 'contactName'
      ) {
        currentGroup[val] = titleCase(currentGroup[val]);
      }
    });

    currentGroup.hasOwnProperty('PrintEmail')
      ? currentGroup
      : (currentGroup.PrintEmail = '');

    return (
      <div className="ptw-main-body">
        <DropdownNavigation />
        <Breadcrumb>
          <DispatchLinkItem title="Groups&nbsp;" url="/groups" />
          <Breadcrumb.Item active>
            {isNewForm ? 'Add' : titleCase(groupName)}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Tabs
          defaultActiveKey={1}
          id="group-page-tabs"
          mountOnEnter
          onSelect={this.handleSelect}
        >
          <Tab eventKey={1} title="Info">
            <Loading isLoading={this.props.isLoading}>
              <GroupForm
                onSubmit={handleSubmit}
                initialValues={currentGroup}
                enableReinitialize
              />
            </Loading>
          </Tab>
          <Tab eventKey={2} title="Clinics" disabled={isNewForm}>
            <ClinicsPage group={routedGroup} />
          </Tab>
          <Tab eventKey={3} title="Admins" disabled={isNewForm}>
            <GroupAdmin
              currentGroup={this.props.currentGroup}
              dispatch={this.props.dispatch}
            />
          </Tab>
          <Tab eventKey={4} title="Designs" disabled={isNewForm}>
            <GroupDesignPage group={routedGroup} />
          </Tab>
          <Tab eventKey={5} title="Utilities" disabled={isNewForm}>
            <Utilities group={routedGroup} />
          </Tab>
          <Tab eventKey={6} title="App Customizations" disabled={isNewForm}>
            <AppCustomizations group={routedGroup} dispatch={dispatch} />
          </Tab>
          <Tab eventKey={7} title="Device Tokens">
            <GroupNotifications group={routedGroup} dispatch={dispatch} />
          </Tab>
          {currentGroup.EnableRTMConsent && (
            <Tab eventKey={8} title="RTM Consent Form">
              <RtmConsentForm group={routedGroup} dispatch={dispatch} />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentGroup: state.groups.currentGroup,
    formType: state.groups.formType,
    isCurrently: isCurrentlySelector(state),
    isLoading: state.groups.isLoading,
    routedGroup: state.groups.items[ownProps.match.params.id],
    utilities: state.utilities
  };
}

export default withRouter(connect(mapStateToProps)(GroupPage));
