import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Typography, Alert, Space, Divider } from 'antd';
import { getRecoil, setRecoil } from 'recoil-nexus';
import v from 'voca';
import Auth from '@aws-amplify/auth';
import './membership.css';
import CryptoES from 'crypto-es';
import { emailValidator, passwordValidator } from '../../validators';
import { loginSuccess, clearData } from './actions';
import AppstoreLinks from './AppstoreLinks';
import AutoLoginModal from './AutoLoginModal';
import LoginForm from './LoginForm';

import defaultLogo from '../../img/default-logo.png';
import setNotification from '../../utils/setNotification.utils';
import config from '../../config';
import services from '../../services';
import states from '../../states';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      capsLockOn: false,
      hasSubmitted: false,
      hasError: false,
      submitting: false,
      autoLoginOpen: false
    };

    this.submitLogin = this.submitLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getValidation = this.getValidation.bind(this);
    this.autoLogin = this.autoLogin.bind(this);
  }

  async componentDidMount() {
    const queryString = this.props.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('key');
    const sub = urlParams.get('sub');

    if (code) {
      try {
        this.setState({
          autoLoginOpen: true,
          submitting: true
        });

        const response = await this.autoLogin(code);
        const {
          AuthenticationResult: {
            AccessToken,
            IdToken,
            RefreshToken,
            ExpiresIn
          },
          user: { Role, EmailAddress, PhoneNumber, GroupId, EnableTelehealth }
        } = response;

        const isH2Group = GroupId === 'b592cd97-5fb7-4d73-b4de-255af54d7df9';
        const telehealthDisabled = EnableTelehealth === false;

        if (Role === 'p' && (isH2Group || telehealthDisabled)) {
          const appLink = this.getPatientAppLink();
          const encoded = CryptoES.AES.encrypt(
            JSON.stringify({ code }),
            'ptwcreds'
          ).toString();

          return window.location.replace(`${appLink}/?auth=${encoded}`);
        }

        const auth = getRecoil(states.authentication);
        setRecoil(states.authentication, {
          ...auth,
          promptCode: code,
          token: IdToken
        });

        let path = await this.getRoutePath(Role);
        if (sub) {
          const input = decodeURIComponent(sub);
          if (!path.includes(input)) {
            path += `/${input}`;
          }
        }

        this.props.dispatch(push(path));
        this.props.dispatch(
          loginSuccess({
            token: IdToken,
            accessToken: AccessToken,
            refreshToken: RefreshToken,
            username: PhoneNumber || v.lowerCase(EmailAddress),
            role: Role,
            expires: ExpiresIn
          })
        );
      } catch (error) {
        console.log('[auto-login]', error);
        setNotification(
          'error',
          'Error!',
          'Incorrect credentials. Please double check your code, token and email address.'
        );
      } finally {
        this.setState({
          autoLoginOpen: false,
          submitting: false
        });
      }
    }
  }

  async autoLogin(code) {
    const response = await fetch(`${config.root}/integration`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({ code: code }) // body data type must match "Content-Type" header
    });

    return response.json();
  }

  getPatientAppLink() {
    const origin = window.location.origin;
    const search = window.location.search;

    const params = new URLSearchParams(search.substring(1));
    const groupName = params.get('groupName');

    let subdomain = groupName || window.location.hostname.split('.')[0];
    if (
      subdomain.includes('localhost') ||
      subdomain.includes('d1unot56jm67or') ||
      subdomain.includes('d3e78nxgge12jj')
    ) {
      subdomain = 'ptw';
    }

    let domain = '';
    if (origin.includes('localhost') || origin.includes('d1unot56jm67or')) {
      domain = 'mobile-dev.ptwired.com';
    } else if (origin.includes('d3e78nxgge12jj')) {
      domain = 'mobile-staging.ptwired.com';
    } else {
      domain = 'mobile.ptwired.com';
    }

    return `${window.location.protocol}//${subdomain}.${domain}`;
  }

  async getRoutePath(role) {
    const queryString = this.props.location.search;
    const urlParams = new URLSearchParams(queryString);
    const patientEmail = urlParams.get('patientEmail');
    const phoneNumber = urlParams.get('phoneNumber');
    const caseId = urlParams.get('caseId');

    let path;
    switch (role) {
      case 'p':
        path = '/exercises';
        break;

      case 'ga':
      case 'pt':
        path = '/patients';
        break;

      case 'sa':
        path = '/administrator';
        break;

      default:
        break;
    }

    if (role === 'p' || role === 'sa') {
      return path;
    }

    if (patientEmail || phoneNumber) {
      let input = decodeURIComponent(patientEmail || phoneNumber);

      if (/^\d+$/.test(input)) {
        if (input.length < 11 && !input.startsWith('+1')) {
          input = '+1' + input;
        } else if (input.length < 12 && !input.startsWith('+')) {
          input = '+' + input;
        }
      }

      await services.patients
        .getPatientDetails('search', input)
        .then(({ data }) => {
          if (data) {
            path += `/${data.Sub}`;
          }
        });
    }

    if (caseId) {
      const paramsState = getRecoil(states.params);
      setRecoil(states.params, {
        ...paramsState,
        caseId
      });
    }

    return path;
  }

  validateUsername(arg) {
    return emailValidator(arg) ? null : 'warning';
  }

  validatePassword(arg) {
    return passwordValidator(arg) ? null : 'warning';
  }

  getValidation(arg) {
    if (this.state.hasSubmitted) {
      switch (arg) {
        case 'username':
          return this.validateUsername(this.state[arg]);
        case 'password':
          return this.validatePassword(this.state[arg]);
        default:
          return null;
      }
    }
  }

  async submitLogin() {
    const queryString = this.props.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sub = urlParams.get('sub');

    try {
      this.props.dispatch(clearData());

      this.setState({
        hasSubmitted: true,
        submitting: true
      });

      const { username, password } = this.state;
      const hostname = config.root;
      const trimmedUname = username.trim();
      const trimmedPword = password.trim();
      const input = encodeURIComponent(v.lowerCase(trimmedUname));

      const user = await fetch(`${hostname}/users/external?input=${input}`);
      const userData = await user.json();
      const response = await Auth.signIn(userData.sub, trimmedPword);

      const {
        challengeName,
        signInUserSession: {
          idToken: { jwtToken },
          accessToken,
          refreshToken
        },
        attributes: { ['custom:role']: role, ['custom:groupId']: groupId }
      } = response;

      if (!challengeName) {
        const isH2Group = groupId === 'b592cd97-5fb7-4d73-b4de-255af54d7df9';
        const telehealthDisabled = userData.telehealth === false;

        if (role === 'p' && (isH2Group || telehealthDisabled)) {
          const appLink = this.getPatientAppLink();
          const encoded = CryptoES.AES.encrypt(
            JSON.stringify({ username: response.username, password }),
            'ptwcreds'
          ).toString();

          return window.location.replace(`${appLink}/?auth=${encoded}`);
        }

        const auth = getRecoil(states.authentication);
        setRecoil(states.authentication, {
          ...auth,
          token: jwtToken
        });

        let path = await this.getRoutePath(role);
        if (sub) {
          const input = decodeURIComponent(sub);
          if (!path.includes(input)) {
            path += `/${input}`;
          }
        }

        this.props.dispatch(push(path));
        this.props.dispatch(
          loginSuccess({
            token: jwtToken,
            accessToken: accessToken.jwtToken,
            refreshToken: refreshToken.token,
            username: v.lowerCase(trimmedUname),
            role: role
          })
        );
      }
    } catch (error) {
      console.log('[form-login]', error);
      this.setErrorText(error.message);
    } finally {
      this.setState({
        hasSubmitted: false,
        submitting: false
      });
    }
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    });
  }

  handleCapsLockOn = (e) => {
    if (e.getModifierState('CapsLock')) {
      this.setState({ capsLockOn: true });
    } else {
      this.setState({ capsLockOn: false });
    }
  };

  submitEnabled() {
    return (
      emailValidator(this.state['username']) &&
      passwordValidator(this.state['password'])
    );
  }

  setErrorText = (text) => {
    this.setState({ errorText: text });
    setTimeout(() => this.setState({ errorText: null }), 20000);
  };

  render() {
    const {
      hasError,
      isLocked,
      design,
      notAuthorized,
      dispatch,
      location,
      message
    } = this.props;

    const {
      username,
      password,
      capsLockOn,
      errorText,
      submitting,
      autoLoginOpen
    } = this.state;

    return (
      <React.Fragment>
        <AutoLoginModal autoLoginOpen={autoLoginOpen} />
        <div className="container">
          <Row gutter={0}>
            <Col lg={7} md={6} xs={1} />
            <Col lg={10} md={12} xs={22}>
              <Card bordered={false} className="ptw-card">
                <AppstoreLinks
                  location={location}
                  design={design}
                  dispatch={dispatch}
                />

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '1em'
                  }}
                >
                  {design.brandType === 'logo' ? (
                    <img
                      src={design.logoUrl}
                      alt="logo"
                      style={{
                        height: '6em',
                        width: 'auto'
                      }}
                    />
                  ) : (
                    <img
                      src={defaultLogo}
                      alt="logo"
                      style={{
                        height: '6em',
                        width: 'auto'
                      }}
                    />
                  )}
                </div>

                {message && (
                  <Alert
                    message=" Congratulations! Your account is now active. Please login to
                    continue."
                    type="success"
                    closable
                  />
                )}

                {isLocked && (
                  <Alert
                    message="Account locked. Please wait five minutes before trying to
                    log in again."
                    type="error"
                    closable
                  />
                )}

                {errorText && (
                  <Alert
                    message="Login Failed"
                    description={
                      <Space direction="vertical">
                        {errorText === 'Password attempts exceeded' ? (
                          <Typography.Text>
                            You have exceeded the maximum number of password
                            attempts.
                            <br />
                            Please reset your password or contact support for
                            assistance.
                          </Typography.Text>
                        ) : (
                          <>
                            <Typography.Text>
                              Incorrect Username or Password.{' '}
                              <Link to="/forgot-password">
                                Click here to reset your password!
                              </Link>
                            </Typography.Text>

                            <Typography.Text>
                              If you are still having issues, contact us at{' '}
                              <Link to="mailto:admin@ptwired.com">
                                admin@ptwired.com
                              </Link>
                            </Typography.Text>
                          </>
                        )}
                      </Space>
                    }
                    type="error"
                    showIcon
                    closable
                  />
                )}

                <LoginForm
                  username={username}
                  password={password}
                  capsLockOn={capsLockOn}
                  onChange={this.handleChange}
                  handleCapsLockOn={this.handleCapsLockOn}
                  onSubmit={this.submitLogin}
                  submitEnabled={this.submitEnabled()}
                  hasError={hasError}
                  submitting={submitting}
                  isLocked={isLocked}
                  notAuthorized={notAuthorized}
                />

                <Divider plain style={{ margin: 4 }}>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </Divider>
              </Card>
            </Col>
            <Col lg={7} md={6} xs={1} />
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasError: state.currentUser.hasError || false,
    hasLoggedOut: state.currentUser.hasLoggedOut,
    isLocked: state.currentUser.isLocked,
    submitting: state.currentUser.submitting,
    design: state.publicPage.design,
    notAuthorized: state.currentUser.notAuthorized
  };
};

export default connect(mapStateToProps)(withRouter(LoginPage));
