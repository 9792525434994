import React from 'react';
import { Row, Col, Button, Tooltip } from 'antd';
import {
  AppstoreOutlined,
  BarsOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import SearchField from '../../SearchField';

const ExerciseAction = ({
  handleSearch,
  searchTerm,
  displayFilter,
  handleDisplayFilter,
  currentView,
  setCurrentView,
}) => {
  return (
    <Row gutter={[8, 8]} className="mb-3">
      <Col lg={16} md={16} sm={24} xs={24}>
        <SearchField
          placeholder="Search exercise..."
          handleSearch={handleSearch}
          value={searchTerm}
        />
      </Col>
      <Col lg={8} md={8} sm={24} xs={24}>
        <Tooltip title="Filters">
          <Button
            shape="circle"
            type={displayFilter ? 'link' : 'text'}
            icon={<FilterOutlined style={{ fontSize: 18 }} />}
            onClick={() => handleDisplayFilter(!displayFilter)}
          />
        </Tooltip>
        <Tooltip title="Exercises View">
          <Button
            className="pull-right"
            shape="circle"
            type="text"
            icon={
              currentView === 'card' ? (
                <AppstoreOutlined style={{ fontSize: 18 }} />
              ) : (
                <BarsOutlined style={{ fontSize: 18 }} />
              )
            }
            onClick={() =>
              setCurrentView(prevView =>
                prevView === 'card' ? 'list' : 'card'
              )
            }
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default ExerciseAction;
