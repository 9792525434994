import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col, Select, Divider } from 'antd';
import { orderBy } from 'lodash';

import {
  firstNameValidator,
  lastNameValidator,
  emailValidator,
  requiredValidator,
  phoneNumberValidator
} from './validators/patients.js';
import asyncValidate from './validators/asyncValidate';
import FormInput from '../groups/Input';

const orderClinics = (clinics) => {
  try {
    const sorted = orderBy(clinics, ['ClinicName'], ['asc']);
    return sorted;
  } catch (error) {
    return [];
  }
};

const EditPatientForm = (props) => {
  const {
    initialValues,
    groupClinics,
    handleSubmit,
    rtmEnabled,
    patientFormValues,
    visibleProfile
  } = props;

  const [patientForm, setPatientForm] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [inputType, setInputType] = useState('tel');

  useEffect(() => {
    if (patientFormValues) {
      setPatientForm(patientFormValues);
      setClinics(orderClinics(groupClinics));
    }
  }, [patientFormValues]);

  const handleKeyPress = (e) => {
    if (e.key === 'Backspace' || (e.ctrlKey && e.key === 'a')) {
      setInputType('text');
    } else {
      setInputType('tel');
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Row gutter={[24, 8]}>
        <Col md={12} xs={24}>
          <Field
            isRequired
            label="First Name"
            placeholder="Enter first name"
            type="text"
            name="FirstName"
            component={FormInput}
            validate={[requiredValidator, firstNameValidator]}
          />
        </Col>

        <Col md={12} xs={24}>
          <Field
            isRequired
            label="Last Name"
            placeholder="Enter last name"
            type="text"
            name="LastName"
            component={FormInput}
            validate={[requiredValidator, lastNameValidator]}
          />
        </Col>

        <Col md={12} xs={24}>
          <Field
            isRequired
            label="Email"
            placeholder="Enter email"
            type="email"
            name="EmailAddress"
            component={FormInput}
            validate={[emailValidator]}
          />
        </Col>

        {!!visibleProfile?.GroupInfo.EnablePhoneNumber && (
          <Col md={12} xs={24}>
            <Field
              isRequired
              inputType={inputType}
              label="Phone Number"
              placeholder="Enter phone number"
              type="text"
              name="PhoneNumber"
              component={FormInput}
              validate={[phoneNumberValidator]}
              onKeyDown={handleKeyPress}
            />
          </Col>
        )}

        {clinics && clinics.length > 0 && (
          <Col md={12} xs={24}>
            {patientForm && !!!patientForm.Clinic && (
              <strong style={{ color: 'red', fontSize: 14 }}>
                Please Add a Location
              </strong>
            )}

            <Field
              isRequired
              label="Location"
              placeholder="Select location"
              type="select"
              name="Clinic"
              component={FormInput}
              validate={[requiredValidator]}
            >
              {clinics
                .filter((item) => item.ClinicName !== 'null')
                .map((item, i) => (
                  <Select.Option key={i} value={item.Id}>
                    {item.ClinicName}
                  </Select.Option>
                ))}
            </Field>
          </Col>
        )}

        {!!rtmEnabled && (
          <Col md={12} xs={24}>
            {patientForm && !!!patientForm.Clinic && (
              <div style={{ height: 22 }} />
            )}

            <Field
              label="Enable RTM"
              type="switch"
              name="EnableRTM"
              checkedChildren="YES"
              unCheckedChildren="NO"
              component={FormInput}
              defaultChecked={initialValues?.EnableRTM || false}
            />
          </Col>
        )}

        {!!visibleProfile?.GroupInfo.EnableRaintree && (
          <>
            <Col md={24} xs={24}>
              <Divider
                plain
                orientation="left"
                orientationMargin={0}
                style={{
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                Raintree Integration
              </Divider>
            </Col>

            <Col md={24} xs={24}>
              <Field
                label="Patient Number"
                placeholder="Enter patient number"
                type="text"
                name="SystemId"
                component={FormInput}
              />
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default reduxForm({
  form: 'patient',
  asyncValidate,
  asyncBlurFields: ['EmailAddress', 'PhoneNumber']
})(withRouter(EditPatientForm));
