import React from 'react';
import { Form, Input, Button } from 'antd';
import { OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const IntroMessageForm = props => {
  const {
    introText,
    setIntroText,
    event,
    handleSubmitCustomization,
    isLoading,
  } = props;

  const delayHide = 2000;

  const popContent = (
    <Popover id="popover-basic">
      <Row>
        <Col sm={6}>
          <strong>Intro Pop-up Message</strong>
        </Col>
        <Col sm={6}>
          <a
            className={'pull-right'}
            href={'https://ptwired.zendesk.com/hc/en-us/articles/4411528633235'}
            target={'_blank'}
          >
            Learn More
          </a>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          When the patient first logs into the app, they will see this message
          underneath your logo. After this initial login, they will not see this
          message again.
        </Col>
      </Row>
    </Popover>
  );

  return (
    <Form
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 12 }}
      initialValues={introText}
      onFinish={() => {
        handleSubmitCustomization(event, { customizationTextValue: introText });
      }}
      autoComplete={false}
    >
      <Row>
        <Col md={1} style={{ width: '10px', marginTop: '4px' }}>
          <OverlayTrigger
            trigger={['hover']}
            placement="right"
            overlay={popContent}
            delayHide={delayHide}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </OverlayTrigger>
        </Col>
        <Col md={11}>
          <Form.Item
            label="Enter Intro Pop-up Message"
            name="introText"
            rules={[
              { required: true, message: 'Intro Pop-up Message is required.' },
            ]}
          >
            <Input.TextArea
              defaultValue={introText}
              onChange={e => setIntroText(e.target.value)}
              value={introText}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save Changes
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default IntroMessageForm;
