import React, { Component, Fragment } from 'react';
import _, { orderBy } from 'lodash';
import { Breadcrumb } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import {
  CloseOutlined,
  EditFilled,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { uploadImageDirect } from '../../components/file-uploader/actions';
import { ImageSelector } from '../../components/file-uploader';
import { imageValidator } from '../groups/validators/groupExercises';
import { updateUserProfile, updateCornerPhoto } from '../users/actions/users';
import { toPascalCaseObjKeys } from '../../utils/object.utils';

import DropdownNavigation from '../../components/DropdownNavigation';
import ProfileContainer from './containers/profileContainer';
import ProfileImage from '../patients/Modals/ProfileImage';
import defaultImageUrl from '../../img/user.png';
import setNotification from '../../utils/setNotification.utils';
import states from '../../states';

class profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: null,
      cropping: false,
      validImg: false,
      closed: false,
      loading: false,
      savingThreshold: false,
      uploadLoading: false,
      editDefaultLocation: false,
      editPainScaleThreshold: false,
      showRequired: false,
      editAll: false,
      saveAll: false,
      preferences: {
        messaging: 'enabled',
        location: null,
        rtmStatus: 'disabled',
        notifyBillableCodes: 'enabled',
        promptLocationPopup: 'disabled',
        painScaleThreshold: null,
        painScaleNotification: 'enabled',
      },
    };
  }

  componentDidMount() {
    const {
      state: { preferences },
    } = this;

    const userState = getRecoil(states.user);
    if (userState.details && userState.details.preferences) {
      const messaging =
        userState.details.preferences.messaging || preferences.messaging;
      const location =
        userState.details.preferences.location || preferences.location;
      const rtmStatus =
        userState.details.preferences.rTMStatus || preferences.rtmStatus;
      const notifyBillableCodes =
        userState.details.preferences.notifyBillableCodes ||
        preferences.notifyBillableCodes;
      const promptLocationPopup =
        userState.details.preferences.promptLocationPopup ||
        preferences.promptLocationPopup;
      const painScaleThreshold =
        userState.details.preferences.painScaleThreshold ||
        preferences.painScaleThreshold;
      const painScaleNotification =
        userState.details.preferences.painScaleNotification ||
        preferences.painScaleNotification;

      this.setState({
        preferences: {
          messaging,
          location,
          rtmStatus,
          notifyBillableCodes,
          promptLocationPopup,
          painScaleThreshold,
          painScaleNotification,
        },
      });
    }

    this.setState({
      img: this.createBaseImageUrl(),
    });
  }

  createBaseImageUrl = () => {
    const { GroupId, Sub } = this.props.visibleProfile;
    const url = encodeURI(
      `https://ptwired-exercise-images-prod.s3.amazonaws.com/${GroupId}/${Sub}.jpg`
    );

    return url.replace('@', '%40');
  };

  handleFiles = files => {
    const {
      visibleProfile: { EmailAddress, GroupId },
    } = this.props;
    const { base64 } = files;
    this.setState({ img: base64 });
    console.log(GroupId, EmailAddress);
    this.uploadImage(GroupId, EmailAddress, base64);
  };

  startCropping = () => {
    const { img } = this.state;

    img && this.setState({ cropping: true });
  };

  stopCropping = () => {
    this.setState({ cropping: false });
  };

  uploadImage = (groupId, emailAddress, file) => {
    const { dispatch } = this.props;
    dispatch(uploadImageDirect(`${groupId}/${emailAddress}.jpg`, file));
  };

  toggleMessaging = async value => {
    const messagingBool = value ? 'enabled' : 'disabled';

    const {
      props: {
        visibleProfile: { Sub, Preferences },
        dispatch,
      },
      state: { editAll, preferences },
    } = this;

    if (editAll) {
      this.setState({
        preferences: {
          ...preferences,
          messaging: messagingBool,
        },
      });

      return;
    }

    try {
      const preferencesObj = {
        ...Preferences,
        Messaging: messagingBool,
      };

      this.setState({
        loading: true,
      });

      await dispatch(updateUserProfile(Sub, preferencesObj)).then(() => {
        this.setState({
          preferences: {
            ...preferences,
            messaging: messagingBool,
          },
        });

        if (!value) {
          setRecoil(states.messages, {
            list: [],
          });
        }

        notification.success({
          message: 'Success!',
          description: 'You have set your messaging status.',
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while saving, please try again.',
      });
    } finally {
      this.setState({ loading: false });

      const userState = getRecoil(states.user);
      setRecoil(states.user, {
        ...userState,
        details: {
          ...userState.details,
          preferences: {
            ...userState.details.preferences,
            messaging: messagingBool,
          },
        },
      });
    }
  };

  toggleRTMStatus = async value => {
    const rtmStatusBool = value ? 'enabled' : 'disabled';

    const {
      props: {
        visibleProfile: { Sub, Preferences },
        dispatch,
      },
      state: { editAll, preferences },
    } = this;

    if (editAll) {
      this.setState({
        preferences: {
          ...preferences,
          rtmStatus: rtmStatusBool,
        },
      });

      return;
    }

    try {
      const preferencesObj = {
        ...Preferences,
        RTMStatus: rtmStatusBool,
      };

      this.setState({
        loading: true,
      });

      await dispatch(updateUserProfile(Sub, preferencesObj)).then(() => {
        this.setState({
          preferences: {
            ...preferences,
            rtmStatus: rtmStatusBool,
          },
        });

        notification.success({
          message: 'Success!',
          description: 'You have set your default RTM status.',
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while saving, please try again.',
      });
    } finally {
      this.setState({ loading: false });

      const userState = getRecoil(states.user);
      setRecoil(states.user, {
        ...userState,
        details: {
          ...userState.details,
          preferences: {
            ...userState.details.preferences,
            rTMStatus: rtmStatusBool,
          },
        },
      });
    }
  };

  toggleNotification = async value => {
    const notifyBillableCodesBool = value ? 'enabled' : 'disabled';

    const {
      props: {
        visibleProfile: { Sub, Preferences },
        dispatch,
      },
      state: { editAll, preferences },
    } = this;

    if (editAll) {
      this.setState({
        preferences: {
          ...preferences,
          notifyBillableCodes: notifyBillableCodesBool,
        },
      });

      return;
    }

    try {
      const preferencesObj = {
        ...Preferences,
        NotifyBillableCodes: notifyBillableCodesBool,
      };

      this.setState({ loading: true });

      await dispatch(updateUserProfile(Sub, preferencesObj)).then(() => {
        this.setState({
          preferences: {
            ...preferences,
            notifyBillableCodes: notifyBillableCodesBool,
          },
        });

        notification.success({
          message: 'Success!',
          description: `You have ${notifyBillableCodesBool} your notification for RTM billable codes.`,
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while saving, please try again.',
      });
    } finally {
      this.setState({ loading: false });

      const userState = getRecoil(states.user);
      setRecoil(states.user, {
        ...userState,
        details: {
          ...userState.details,
          preferences: {
            ...userState.details.preferences,
            notifyBillableCodes: notifyBillableCodesBool,
          },
        },
      });
    }
  };

  togglePromptLocationPopup = async value => {
    const promptLocationPopupBool = value ? 'enabled' : 'disabled';

    const {
      props: {
        visibleProfile: { Sub, Preferences },
        dispatch,
      },
      state: { editAll, preferences },
    } = this;

    if (editAll) {
      this.setState({
        preferences: {
          ...preferences,
          promptLocationPopup: promptLocationPopupBool,
        },
      });

      return;
    }

    try {
      const preferencesObj = {
        ...Preferences,
        PromptLocationPopup: promptLocationPopupBool,
      };

      this.setState({
        loading: true,
      });

      await dispatch(updateUserProfile(Sub, preferencesObj)).then(() => {
        this.setState({
          preferences: {
            ...preferences,
            promptLocationPopup: promptLocationPopupBool,
          },
        });

        notification.success({
          message: 'Success!',
          description: `You have ${promptLocationPopupBool} the Prompt Location Pop-up display.`,
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while saving, please try again.',
      });
    } finally {
      this.setState({ loading: false });

      const userState = getRecoil(states.user);
      setRecoil(states.user, {
        ...userState,
        details: {
          ...userState.details,
          preferences: {
            ...userState.details.preferences,
            promptLocationPopup: promptLocationPopupBool,
          },
        },
      });
    }
  };

  setDefaultLocation = async location => {
    const {
      props: {
        visibleProfile: { Sub, Preferences },
        dispatch,
      },
      state: { editAll, preferences },
    } = this;

    if (editAll) {
      this.setState({
        preferences: {
          ...preferences,
          location: location.Location,
        },
      });

      return;
    }

    try {
      const preferencesObj = {
        ...Preferences,
        ...location,
      };

      this.setState({
        loading: true,
      });

      await dispatch(updateUserProfile(Sub, preferencesObj)).then(() => {
        this.setState({
          editDefaultLocation: false,
          preferences: {
            ...preferences,
            location: location.Location,
          },
        });

        notification.success({
          message: 'Success!',
          description: 'You have set your default location.',
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description:
          'An error occurred while saving default location, please try again.',
      });
    } finally {
      this.setState({ loading: false });

      const userState = getRecoil(states.user);
      setRecoil(states.user, {
        ...userState,
        details: {
          ...userState.details,
          preferences: {
            ...userState.details.preferences,
            location: location.Location,
          },
        },
      });
    }
  };

  getSelectedClinicName = clinicId => {
    const {
      visibleProfile: { Clinics },
    } = this.props;

    let selectedClinicDetails =
      Clinics && Clinics.find(clinic => clinic.Id === clinicId);

    return selectedClinicDetails ? selectedClinicDetails.ClinicName : null;
  };

  setPainScaleThreshold = async () => {
    const {
      props: {
        visibleProfile: { Sub, Preferences },
        dispatch,
      },
      state: { preferences },
    } = this;

    try {
      if (!preferences.painScaleThreshold) {
        this.setState({
          showRequired: true,
        });

        return;
      }

      this.setState({
        savingThreshold: true,
      });

      const preferencesObj = {
        ...Preferences,
        PainScaleThreshold: Number(preferences.painScaleThreshold),
        PainScaleNotification: preferences.painScaleNotification,
      };

      await dispatch(updateUserProfile(Sub, preferencesObj)).then(() => {
        notification.success({
          message: 'Success!',
          description: `You have set settings for Daily Outcome Score.`,
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description:
          'An error occurred while saving Daily Outcome Score settings, please try again.',
      });
    } finally {
      this.setState({
        savingThreshold: false,
      });

      const userState = getRecoil(states.user);
      setRecoil(states.user, {
        ...userState,
        details: {
          ...userState.details,
          preferences: {
            ...userState.details.preferences,
            painScaleThreshold: Number(preferences.painScaleThreshold),
            painScaleNotification: preferences.painScaleNotification,
          },
        },
      });
    }
  };

  handleCancelEditAll = () => {
    const userState = getRecoil(states.user);
    const userPreferences = userState.details.preferences;

    this.setState({
      editAll: false,
      preferences: {
        messaging: userPreferences.messaging || 'enabled',
        location: userPreferences.location || null,
        rtmStatus: userPreferences.rTMStatus || 'disabled',
        notifyBillableCodes: userPreferences.notifyBillableCodes || 'enabled',
        promptLocationPopup: userPreferences.promptLocationPopup || 'disabled',
        painScaleThreshold: userPreferences.painScaleThreshold || null,
        painScaleNotification:
          userPreferences.painScaleNotification || 'enabled',
      },
    });
  };

  handleSaveEditAll = async () => {
    const {
      props: {
        visibleProfile: { Sub, Preferences },
        dispatch,
      },
      state: { preferences },
    } = this;

    try {
      this.setState({
        saveAll: true,
      });

      const preferencesObj = {
        ...Preferences,
        ...toPascalCaseObjKeys(preferences),
        RTMStatus: preferences.rtmStatus,
      };

      delete preferencesObj.RtmStatus;

      await dispatch(updateUserProfile(Sub, preferencesObj)).then(() => {
        notification.success({
          message: 'Success!',
          description: `You have saved all your changes.`,
        });
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description:
          'An error occurred while saving profile settings, please try again.',
      });
    } finally {
      this.setState({
        saveAll: false,
      });

      const userState = getRecoil(states.user);
      const userDetails = userState.details;

      setRecoil(states.user, {
        ...userState,
        details: {
          ...userDetails,
          preferences: {
            ...Preferences,
            ...preferences,
          },
        },
      });
    }
  };

  handleFileSelected = (x, errors) => {
    const isValid = _.isEmpty(errors);

    if (isValid) {
      this.file = x;
      this.setState({ cropping: true });
    } else {
      console.log('errors', errors);
      setNotification(
        'error',
        'Upload Failed!',
        'Image size must be less than 2.5MB. Use images less than 1MB for faster loading time.'
      );
    }
  };

  handleImageCrop = async (file, base64) => {
    const {
      props: {
        dispatch,
        visibleProfile: { GroupId, Sub },
      },
    } = this;

    try {
      this.setState({ uploadLoading: true });

      await dispatch(
        uploadImageDirect(`${GroupId}/${Sub}.jpg`, file),
        await dispatch(updateCornerPhoto(base64))
      );

      this.setState({ img: base64, cropping: false });
      this.file = null;

      setNotification(
        'success',
        'Upload Successful',
        'You have updated your profile photo.'
      );
    } catch (error) {
      console.log('error', error);
      setNotification(
        'error',
        'Upload Failed!',
        'An error occurred while saving profile photo.'
      );
    } finally {
      this.setState({ uploadLoading: false });
    }
  };

  handleFileRemoved = x => {
    this.removeFile = x;
  };

  onSubmit = file => {
    console.log(file);
  };

  hideCrop = () => {
    this.setState({ cropping: false });
  };

  onExit = () => {
    this.setState({ img: null });
  };

  closeButton = () => {
    this.setState({ cropping: false });
  };

  orderClinics = clinics => {
    try {
      const sorted = orderBy(clinics, ['ClinicName'], ['asc']);
      return sorted;
    } catch (error) {
      return [];
    }
  };

  render() {
    const {
      toggleMessaging,
      setDefaultLocation,
      setPainScaleThreshold,
      toggleRTMStatus,
      toggleNotification,
      togglePromptLocationPopup,
      handleFileSelected,
      handleFileRemoved,
      handleCancelEditAll,
      handleSaveEditAll,
      file,
      hideCrop,
      handleImageCrop,
      onExit,
      closeButton,
      state: {
        img,
        cropping,
        loading,
        editDefaultLocation,
        editPainScaleThreshold,
        showRequired,
        savingThreshold,
        uploadLoading,
        preferences,
        editAll,
        saveAll,
      },
      props: {
        visibleProfile: {
          Role,
          imgUrl,
          Clinics,
          GroupInfo: { EnableRTM },
        },
      },
    } = this;

    const clinicList = this.orderClinics(Clinics);

    return (
      <React.Fragment>
        {cropping && (
          <ProfileImage
            show={cropping}
            onHide={hideCrop}
            image={file}
            onSubmit={handleImageCrop}
            closeButton={closeButton}
            onExit={onExit}
            uploadLoading={uploadLoading}
          />
        )}

        <DropdownNavigation />
        <div className="ptw-main-body">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={Role === 'ga' ? '/administrator/' : '/patients'}>
                {Role === 'ga' ? 'Admin' : 'Patients'}
              </Link>
            </Breadcrumb.Item>
            <span> &raquo; </span>
            <Breadcrumb.Item active>My Profile</Breadcrumb.Item>
          </Breadcrumb>
          <Row gutter={[16, 16]}>
            <Col md={10} lg={10}>
              <ImageSelector
                ref={selector => {
                  this.selector = selector;
                }}
                isUser={true}
                selectorType={'PROFILE_PHOTO'}
                src={imgUrl ? imgUrl : img}
                defaultImageUrl={defaultImageUrl}
                imageValidator={imageValidator}
                onFileSelected={handleFileSelected}
                onFileRemoved={handleFileRemoved}
                height={125}
                width={125}
              >
                onExit={onExit}
              </ImageSelector>
            </Col>
            <Col md={14} lg={14}>
              <ProfileContainer />

              <Row gutter={[0, 16]} className="mt-4 mb-4">
                <Col lg={2} md={2}>
                  <Switch
                    style={{ marginTop: 14 }}
                    disabled={loading || saveAll}
                    checked={preferences.messaging === 'enabled'}
                    onClick={toggleMessaging}
                  />
                </Col>

                <Col md={22} lg={22}>
                  <h4>Enable Messaging</h4>
                  <p>
                    Allow your patients to send you secure messages through the
                    patient application.
                  </p>
                </Col>

                {EnableRTM && typeof EnableRTM === 'boolean' && !!EnableRTM && (
                  <>
                    <Fragment>
                      <Col lg={2} md={2}>
                        <Switch
                          style={{ marginTop: 14 }}
                          disabled={loading || saveAll}
                          checked={preferences.rtmStatus === 'enabled'}
                          onClick={toggleRTMStatus}
                        />
                      </Col>

                      <Col lg={22} md={22}>
                        <h4>Default RTM Status</h4>
                        <p>
                          RTM status is <strong>{preferences.rtmStatus}</strong>{' '}
                          by default.
                        </p>
                      </Col>
                    </Fragment>

                    <Fragment>
                      <Col lg={2} md={2}>
                        <Switch
                          style={{ marginTop: 14 }}
                          disabled={loading}
                          checked={
                            preferences.notifyBillableCodes === 'enabled'
                          }
                          onClick={toggleNotification}
                        />
                      </Col>

                      <Col lg={22} md={22}>
                        <h4>Enable Billable Codes Notification</h4>
                        <p>
                          Email notification for RTM billable codes is{' '}
                          <strong>{preferences.notifyBillableCodes}</strong>.
                        </p>
                      </Col>
                    </Fragment>
                  </>
                )}

                <Col md={2} lg={2}>
                  <Switch
                    style={{ marginTop: 14 }}
                    disabled={loading || saveAll}
                    checked={preferences.promptLocationPopup === 'enabled'}
                    onClick={togglePromptLocationPopup}
                  />
                </Col>

                <Col md={22} lg={22}>
                  <Space align="start">
                    <h4>Display Location & RTM Pop-up</h4>
                    <Tooltip title="This pop-up will be displayed when you import a patient from your EMR. It will allow you to assign a Location to the patient and enable RTM tracking. Both fields can be edited later on the patient profile.">
                      <InfoCircleOutlined
                        style={{
                          fontSize: 18,
                        }}
                      />
                    </Tooltip>
                  </Space>

                  <p>
                    Location and RTM Pop-up display is currently{' '}
                    <strong>{preferences.promptLocationPopup}</strong>.
                  </p>
                </Col>

                {Array.isArray(Clinics) && Clinics.length > 0 && (
                  <Fragment>
                    <Col md={2} lg={2}>
                      <Tooltip title="Edit Default Location">
                        <Button
                          type="link"
                          disabled={loading}
                          onClick={() => {
                            if (!editAll) {
                              this.setState({
                                editDefaultLocation: !editDefaultLocation,
                              });
                            }
                          }}
                          style={{
                            paddingLeft: 8,
                            color: loading ? '' : 'black',
                          }}
                        >
                          {editDefaultLocation ? (
                            <CloseOutlined
                              style={{
                                fontSize: 28,
                              }}
                            />
                          ) : (
                            <EditFilled
                              style={{
                                fontSize: 28,
                              }}
                            />
                          )}
                        </Button>
                      </Tooltip>
                    </Col>

                    <Col md={22} lg={22}>
                      <h4>Default Location</h4>

                      {editDefaultLocation || editAll ? (
                        <Form
                          layout="inline"
                          initialValues={{
                            Location: preferences.location,
                          }}
                          onFinish={val => {
                            setDefaultLocation(val);
                          }}
                        >
                          <Form.Item
                            name="Location"
                            rules={[
                              {
                                required: true,
                                message: 'Default location is required.',
                              },
                            ]}
                            style={{
                              width: '45%',
                            }}
                          >
                            <Select
                              showSearch
                              name="Location"
                              placeholder="Select default location"
                              size="large"
                              disabled={loading || saveAll}
                              onChange={value => {
                                this.setState({
                                  preferences: {
                                    ...preferences,
                                    location: value,
                                  },
                                });
                              }}
                            >
                              {clinicList.map((clinic, index) => {
                                const { Id, ClinicName } = clinic;

                                if (index === 0) {
                                  return (
                                    <Select.Option selected key={Id} value={Id}>
                                      {ClinicName}
                                    </Select.Option>
                                  );
                                } else {
                                  return (
                                    <Select.Option key={Id} value={Id}>
                                      {ClinicName}
                                    </Select.Option>
                                  );
                                }
                              })}
                            </Select>
                          </Form.Item>

                          {!editAll && (
                            <Form.Item>
                              <Button
                                className="ptw-btn btn-primary"
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={loading}
                              >
                                Save Changes
                              </Button>
                            </Form.Item>
                          )}
                        </Form>
                      ) : (
                        <Typography.Text>
                          {this.getSelectedClinicName(preferences.location)}{' '}
                          <Typography.Text type="secondary">
                            {!preferences.location &&
                              'Not selected a default location yet.'}
                          </Typography.Text>
                        </Typography.Text>
                      )}
                    </Col>
                  </Fragment>
                )}

                <>
                  <Col md={2} lg={2}>
                    <Tooltip title="Edit Daily Outcome Score Threshold">
                      <Button
                        type="link"
                        disabled={savingThreshold}
                        onClick={() => {
                          if (!editAll) {
                            this.setState({
                              editPainScaleThreshold: !editPainScaleThreshold,
                            });
                          }
                        }}
                        style={{
                          paddingLeft: 8,
                          color: savingThreshold ? '' : 'black',
                        }}
                      >
                        {editPainScaleThreshold ? (
                          <CloseOutlined
                            style={{
                              fontSize: 28,
                            }}
                          />
                        ) : (
                          <EditFilled
                            style={{
                              fontSize: 28,
                            }}
                          />
                        )}
                      </Button>
                    </Tooltip>
                  </Col>

                  <Col md={22} lg={22}>
                    <h4>Daily Outcome Score Threshold</h4>

                    {editPainScaleThreshold || editAll ? (
                      <Form layout="inline">
                        <Space align="baseline">
                          <Space direction="vertical" size={0}>
                            <Form.Item label="Threshold">
                              <input
                                type="number"
                                min="1"
                                max="5"
                                style={{
                                  height: 30,
                                  width: 42,
                                  borderRadius: 4,
                                  border: '1px solid #d9d9d9',
                                }}
                                disabled={savingThreshold || saveAll}
                                value={preferences.painScaleThreshold || ''}
                                onChange={e => {
                                  let value = e.target.value;
                                  if (value > 5) {
                                    value = 5;
                                  } else if (value <= 0) {
                                    value = '';
                                  }

                                  this.setState({
                                    showRequired: false,
                                    preferences: {
                                      ...preferences,
                                      painScaleThreshold: value,
                                    },
                                  });
                                }}
                              />
                            </Form.Item>

                            {showRequired && (
                              <Typography.Text
                                style={{
                                  color: 'red',
                                }}
                              >
                                This field is required.
                              </Typography.Text>
                            )}
                          </Space>

                          <Form.Item label="Notification">
                            <Switch
                              disabled={savingThreshold || saveAll}
                              checked={
                                preferences.painScaleNotification === 'enabled'
                              }
                              onChange={value => {
                                const status = value ? 'enabled' : 'disabled';
                                this.setState({
                                  preferences: {
                                    ...preferences,
                                    painScaleNotification: status,
                                  },
                                });
                              }}
                            />
                          </Form.Item>

                          {!editAll && (
                            <Form.Item
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <Button
                                type="primary"
                                size="large"
                                className="ptw-btn btn-primary"
                                loading={savingThreshold}
                                onClick={setPainScaleThreshold}
                              >
                                Save Changes
                              </Button>
                            </Form.Item>
                          )}
                        </Space>
                      </Form>
                    ) : (
                      <>
                        {preferences.painScaleThreshold ? (
                          <Typography.Text>
                            {preferences.painScaleNotification === 'enabled' ? (
                              <>
                                Threshold for Daily Outcome Score is set to{' '}
                                <strong>
                                  {preferences.painScaleThreshold}
                                </strong>
                                .
                              </>
                            ) : (
                              <>
                                Email notification for Daily Outcome Score is{' '}
                                <strong>
                                  {preferences.painScaleNotification}
                                </strong>
                              </>
                            )}
                          </Typography.Text>
                        ) : (
                          <Typography.Text type="secondary">
                            Threshold for Daily Outcome Score is not set yet.
                          </Typography.Text>
                        )}
                      </>
                    )}
                  </Col>
                </>

                <Col md={24} lg={24}>
                  <Space
                    style={{
                      marginTop: 24,
                    }}
                  >
                    {editAll ? (
                      <>
                        <Button
                          type="secondary"
                          size="large"
                          onClick={handleCancelEditAll}
                        >
                          Cancel
                        </Button>

                        <Button
                          type="primary"
                          size="large"
                          onClick={handleSaveEditAll}
                        >
                          Save Changes
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                          this.setState({
                            editAll: true,
                          });
                        }}
                      >
                        Edit All
                      </Button>
                    )}
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
});

export default connect(mapStateToProps)(profile);
