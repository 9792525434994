import React, { useState, useEffect, Fragment } from 'react';
import { Card, Tag, Typography, Button, Popconfirm } from 'antd';
import {
  CaretDownFilled,
  CaretUpFilled,
  CloseOutlined
} from '@ant-design/icons';

const sortItems = [
  { key: '20_MIN_THRESHOLD', name: 'Time remaining in 20-min threshold' },
  { key: 'CALL_STATUS', name: 'Call Status' },
  { key: 'ENROLLMENT_DATE', name: 'Enrollment Date' },
  {
    key: '30_DAYS_BILLING',
    name: 'Days remaining in 30-day billing period'
  }
];

const SortPatientsContainer = ({ setOpenSort, getSortItems }) => {
  const [sort, setSort] = useState([]);
  const [visible, setVisible] = useState(false);
  const [hasSortItems, setHasSortItems] = useState(false);

  useEffect(() => {
    if (sort.length > 0) {
      setHasSortItems(true);
    } else {
      setHasSortItems(false);
    }

    getSortItems(sort);
  }, [sort]);

  const addSort = key => {
    if (sort.some(item => item.key === key)) {
      return;
    }

    const prioOrder = sort.length + 1;
    setSort([...sort, { key, prioOrder, sortOrder: 'DESC' }]);
  };

  const removeSort = (e, key) => {
    e.preventDefault();

    const updatedSort = sort.filter(item => item.key !== key);
    setSort(updatedSort);
  };

  const updateSortOrder = key => {
    const updateSortOrder = sort.map(item => {
      if (item.key === key) {
        return {
          ...item,
          sortOrder: item.sortOrder === 'ASC' ? 'DESC' : 'ASC'
        };
      }

      return item;
    });

    setSort(updateSortOrder);
  };

  const handleVisibleChange = visible => {
    if (!visible) {
      setVisible(visible);
      return;
    }

    if (!hasSortItems) {
      handleConfirm();
    } else {
      setVisible(visible);
    }
  };

  const handleConfirm = () => {
    setVisible(false);
    setOpenSort(false);
    setSort([]);
    getSortItems([]);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Card className="rtm-sort-container">
      <Popconfirm
        visible={visible}
        title="Are you sure you want to remove sorting?"
        okText="Yes"
        cancelText="Cancel"
        placement="leftBottom"
        onVisibleChange={handleVisibleChange}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      >
        <Button
          style={{ float: 'right', marginTop: -8 }}
          icon={<CloseOutlined />}
          shape="circle"
          type="text"
        />
      </Popconfirm>
      <Typography.Title level={4}>Sort</Typography.Title>

      {sortItems.map((item, i) => {
        const addedSort = sort.find(s => s.key === item.key);

        return (
          <Tag
            className="rtm-sort-tag"
            key={i}
            onClick={() => addSort(item.key)}
            onClose={e => removeSort(e, item.key)}
            closable={addedSort}
          >
            {item.name}
            {addedSort && (
              <div
                className="rtm-btn-sort-order"
                onClick={() => updateSortOrder(item.key)}
              >
                {sort.map((selectedItem, selectedIndex) => {
                  if (item.key === selectedItem.key) {
                    return (
                      <Fragment key={selectedIndex}>
                        {sort.length > 1 && selectedIndex + 1}
                        {selectedItem.sortOrder === 'DESC' ? (
                          <CaretDownFilled />
                        ) : (
                          <CaretUpFilled />
                        )}
                      </Fragment>
                    );
                  }
                })}
              </div>
            )}
          </Tag>
        );
      })}
    </Card>
  );
};

export default SortPatientsContainer;
