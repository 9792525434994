import { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import StatCard from '../../components/StatCard';
import services from '../../services';
import { useRecoilState, useRecoilValue } from 'recoil';
import states from '../../states';
import * as reportsUtils from '../../utils/reports.utils';
import { stats } from './data';
import PreviewCard from './PreviewCard';
import ExpandedCard from './ExpandedCard';

const RTMReports = () => {
  const [loading, setLoading] = useState(false);
  const [statData, setStatsData] = useState(stats);
  const [expandedView, setExpandedView] = useState(null);

  const [reports, setReports] = useRecoilState(states.reports);
  const groups = useRecoilValue(states.groups);

  useEffect(() => {
    fetchReports();
  }, []);

  useEffect(() => {
    if (reports.rtm.totalCodes) {
      setStatsData(prevState => {
        const newStats = [...prevState];
        const codeStat = newStats.find(
          stat => stat.title === 'Total RTM Billed Codes'
        );
        codeStat.value = reports.rtm.totalCodes;
        return newStats;
      });
    }

    if (reports.rtm.totalPatients) {
      setStatsData(prevState => {
        const newStats = [...prevState];
        const patientStat = newStats.find(
          stat => stat.title === 'Active RTM Patients'
        );
        patientStat.value = reports.rtm.totalPatients;
        return newStats;
      });
    }

    if (reports.rtm.totalDuration) {
      setStatsData(prevState => {
        const newStats = [...prevState];
        const durationStat = newStats.find(
          stat => stat.title === 'Total RTM Service Time'
        );
        durationStat.value = `${reports.rtm.totalDuration} hours`;
        return newStats;
      });
    }

    if (groups.clinics) {
      setStatsData(prevState => {
        const newStats = [...prevState];
        const clinicStat = newStats.find(stat => stat.title === 'Locations');
        clinicStat.value = groups.clinics.length;
        return newStats;
      });
    }
  }, [
    reports.rtm.totalCodes,
    reports.rtm.totalPatients,
    reports.rtm.totalDuration,
    groups.clinics
  ]);

  const getInitData = async () => {
    try {
      let clinicsList = groups.clinics;
      if (!groups.clinics.length) {
        const groupClinics = await services.groups.getClinics();
        if (groupClinics.message === 'Success') {
          clinicsList = groupClinics.data;
        }
      }

      let providersList = groups.providers;
      if (!groups.providers.length) {
        const groupProviders = await services.groups.getProviders();
        if (groupProviders.message === 'Success') {
          providersList = groupProviders.data;
        }
      }

      setGroups({
        ...groups,
        clinics: clinicsList,
        providers: providersList
      });
    } catch (error) {
      throw error;
    }
  };
  const fetchReports = async () => {
    try {
      setLoading(true);

      const query = reportsUtils.generateDateQuery();
      const responses = await Promise.all([
        services.reports.getRtmTotals('TOTAL_PATIENTS'),
        services.reports.getRtmTotals('TOTAL_DURATION'),
        services.reports.getRtmTotals('TOTAL_CODES'),
        services.reports.getRtmBilled(query),
        services.reports.getRtmBillers(
          query,
          groups.providers.filter(p => !!p.Active).map(p => p.Sub)
        ),
        services.reports.getRtmCodes(query),
        services.reports.getRtmPatients(query),
        services.reports.getRtmServiceLogs(query)
      ]);

      const [
        totalPatients,
        totalDuration,
        totalCodes,
        billed,
        billers,
        codes,
        patients,
        serviceLogs
      ] = responses.map(res => res.data);

      setReports(prevState => ({
        ...prevState,
        rtm: {
          ...prevState.rtm,
          totalCodes,
          totalPatients,
          totalDuration,
          billed,
          billers,
          codes,
          patients,
          serviceLogs
        }
      }));
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleExpand = type => {
    setExpandedView(type);
    window.scrollTo(0, 0);
  };

  return (
    <div className="ptw-main-body">
      <Row
        gutter={[16, 16]}
        justify="space-around"
        style={{ marginBottom: 20 }}
      >
        {statData.map((stat, i) => (
          <Col key={i} flex={1 / statData.length}>
            <StatCard
              loading={loading}
              title={stat.title}
              value={stat.value}
              icon={stat.icon}
            />
          </Col>
        ))}
      </Row>
      {expandedView ? (
        <Fragment>
          {expandedView && (
            <ExpandedCard
              type={expandedView}
              handleClose={() => handleExpand(null)}
            />
          )}
        </Fragment>
      ) : (
        <Row gutter={[16, 16]} justify="space-between" align="stretch">
          <Col span={12}>
            <PreviewCard
              type="BILLED"
              loading={loading}
              handleExpand={() => {
                handleExpand('BILLED');
              }}
            />
          </Col>
          <Col span={12}>
            <PreviewCard
              type="BILLERS"
              loading={loading}
              handleExpand={() => {
                handleExpand('BILLERS');
              }}
            />
          </Col>
          <Col span={24}>
            <PreviewCard
              type="CODES"
              loading={loading}
              handleExpand={() => {
                handleExpand('CODES');
              }}
            />
          </Col>
          <Col span={24}>
            <PreviewCard
              type="PATIENTS"
              loading={loading}
              handleExpand={() => {
                handleExpand('PATIENTS');
              }}
            />
          </Col>
          <Col span={24}>
            <PreviewCard
              type="SERVICE_LOGS"
              loading={loading}
              handleExpand={() => {
                handleExpand('SERVICE_LOGS');
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default RTMReports;
