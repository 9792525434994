import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { requestPasswordReset } from './actions';
import { Row, Col, Card, Typography, Form, Button, Alert } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import isEmail from 'validator/lib/isEmail';
import TextInput from './TextInput';

const ForgotPassword = ({ dispatch, design }) => {
  const [input, setInput] = useState('');
  const [inputType, setInputType] = useState('email');
  const [submitting, setSubmitting] = useState(false);
  const [requested, setRequested] = useState(false);
  const [error, setError] = useState(false);

  const handleRequestPasswordReset = async () => {
    try {
      setRequested(false);
      setError(false);
      setSubmitting(true);

      let value = input;
      if (!isEmail(input)) {
        const phoneNumber = input.replace(/[()\s\-]/g, '');
        if (phoneNumber.length < 11 && !phoneNumber.startsWith('+1')) {
          value = '+1' + phoneNumber;
        } else if (phoneNumber.length < 12 && !phoneNumber.startsWith('+')) {
          value = '+' + phoneNumber;
        }

        setInputType('phone');
      } else {
        setInputType('email');
      }

      await dispatch(requestPasswordReset(value.trim().toLowerCase()));
      setRequested(true);
    } catch (err) {
      setError(true);
    } finally {
      setSubmitting(false);
      setInput('');
    }
  };

  return (
    <div className="container">
      <Row gutter={0}>
        <Col lg={7} md={6} xs={1} />
        <Col lg={10} md={12} xs={22}>
          <Card bordered={false} className="ptw-card">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1em'
              }}
            >
              {design.brandType === 'logo' ? (
                <img
                  src={design.logoUrl}
                  alt="logo"
                  style={{
                    height: '6em',
                    width: 'auto'
                  }}
                />
              ) : (
                <h2 className="text-center">{design.brandText}</h2>
              )}
            </div>

            {requested && (
              <Alert
                closable
                showIcon
                type="success"
                message="Account Reset Success"
                description={
                  <Typography.Text>
                    {inputType === 'email'
                      ? 'A password reset link has been sent to your email address.'
                      : 'A password reset code has been sent to your phone via SMS.'}{' '}
                    If you do not receive an{' '}
                    {inputType === 'email' ? 'email' : 'SMS'}, please contact
                    your system administrator.
                  </Typography.Text>
                }
              />
            )}

            {error && (
              <Alert
                closable
                showIcon
                type="error"
                message="Account Reset Failed"
                description={
                  <Typography.Text>
                    There is an issue with your account. Please contact us at{' '}
                    <Link to={'mailto:admin@ptwired.com'}>
                      admin@ptwired.com
                    </Link>{' '}
                    to get the issue resolved.
                  </Typography.Text>
                }
              />
            )}

            <div className="text-center" style={{ marginTop: 16 }}>
              <Typography.Text>
                Enter your email or phone number below and we'll send you
                password reset instructions.
              </Typography.Text>
            </div>

            <Form
              layout="vertical"
              className="login-form"
              initialValues={{
                remember: true
              }}
              onFinish={handleRequestPasswordReset}
            >
              <TextInput
                label="Email or Phone Number"
                placeholder="Enter your email or phone number"
                isRequired={true}
                validateEmail={true}
                value={input}
                onChange={value => setInput(value)}
              />

              <Form.Item>
                <Button
                  block
                  className="ptw-btn btn-primary"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={submitting}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>

            <div className="text-center">
              <Link className="small" to="/">
                <ArrowLeftOutlined /> Back to Login
              </Link>
            </div>
          </Card>
        </Col>
        <Col lg={7} md={6} xs={1} />
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  hasError: state.currentUser.hasError || false,
  hasLoggedOut: state.currentUser.hasLoggedOut,
  isLocked: state.currentUser.isLocked,
  submitting: state.currentUser.submitting,
  design: state.publicPage.design
});

export default connect(mapStateToProps)(ForgotPassword);
