import { createAction } from 'redux-actions';
import { API_REQUEST } from '../../membership/middleware';
import moment from 'moment';
export const OPEN_ADD_CUSTOM_EXERCISE_MODAL = 'OPEN_ADD_CUSTOM_EXERCISE_MODAL';
export const CLOSE_ADD_CUSTOM_EXERCISE_MODAL =
  'CLOSE_ADD_CUSTOM_EXERCISE_MODAL';

export const defaultPatient = {
  active: true
};

export const patientSubmissionTypes = {
  SAVE: 'SAVE',
  SAVE_AND_RX: 'SAVE_AND_RX',
  SAVE_AND_CARE: 'SAVE_AND_CARE'
};

const opensearchActions = { query: 'QUERY_BY_NAME' };

export const POST_PATIENTS = 'POST_PATIENTS';
export const CHANGE_WEEK = 'CHANGE_WEEK';
export const CURRENT_WEEK = 'CURRENT_WEEK';
export const GET_PATIENTS = 'GET_PATIENTS';
export const SEARCH_PATIENTS = 'SEARCH_PATIENTS';
export const GET_PATIENT = 'GET_PATIENT';
export const POST_PATIENT = 'POST_PATIENT';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const IS_LOADING = 'PATIENT_IS_LOADING';
export const DONE_LOADING = 'PATIENT_DONE_LOADING';
export const OPEN_MODAL = 'OPEN_MODAL';
export const PRINT_OPEN_MODAL = 'PRINT_OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const UPDATE_PATIENT_SUBMISSION_TYPE = 'UPDATE_PATIENT_SUBMISSION_TYPE';
export const EDIT_PATIENT_PROFILE = 'EDIT_PATIENT_PROFILE';
export const DISCHARGE_PATIENT = 'DISCHARGE_PATIENT';
export const SET_VISIBLE_NEW_MESSAGES = 'SET_VISIBLE_NEW_MESSAGES';
export const GET_MESSAGEBOARD_PROVIDERS = 'GET_MESSAGEBOARD_PROVIDERS';
export const SET_MESSAGEBOARD_PROVIDERS = 'GET_MESSAGEBOARD_PROVIDERS';

export const updatePatientSubmissionType = createAction(
  UPDATE_PATIENT_SUBMISSION_TYPE
);

export const openAddCustomExerciseModal = createAction(
  OPEN_ADD_CUSTOM_EXERCISE_MODAL
);

export const closeAddCustomExerciseModal = createAction(
  CLOSE_ADD_CUSTOM_EXERCISE_MODAL
);

export const changeWeek = (valueWeek) => createAction(CHANGE_WEEK)(valueWeek);

export const SET_ASSIGNED_PROVIDERS = 'SET_ASSIGNED_PROVIDERS';
export const setAssignedProviders = (providersList) =>
  createAction(SET_ASSIGNED_PROVIDERS)(providersList);

export const setCurrentWeek = (currentWeek) =>
  createAction(CURRENT_WEEK)(currentWeek);

export const updateCurrentUser = createAction(
  UPDATE_CURRENT_USER,
  (values) => ({
    values
  })
);

export const isLoading = createAction(IS_LOADING);

export const doneLoading = createAction(DONE_LOADING);

export const openModal = createAction(OPEN_MODAL)();

export const printOpenModal = createAction(PRINT_OPEN_MODAL)();

export const RESET_PATIENT = 'RESET_PATIENT';

export const resetPatient = createAction(RESET_PATIENT);

export const closeModal = createAction(CLOSE_MODAL)();

export const setVisibleNewMessages = createAction(
  SET_VISIBLE_NEW_MESSAGES,
  (count) => ({ count })
);

export const ageCalculation = (dob) =>
  moment().diff(moment(dob, 'MM-DD-YYYY'), 'years');

export const GET_MESSAGES_PATIENT = 'GET_MESSAGES_PATIENT';
export const getMessagesPatient = (owner, therapist, type = 'single') => ({
  [API_REQUEST]: createAction(GET_MESSAGES_PATIENT)({
    resource: `messages?owner=${owner}&therapist=${therapist}&type=${type}`,
    method: 'GET'
  })
});

export const GET_MESSAGES_THERAPIST = 'GET_MESSAGES_THERAPIST';
export const getMessagesTherapist = (therapist, type = 'all') => ({
  [API_REQUEST]: createAction(GET_MESSAGES_THERAPIST)({
    resource: `messages?therapist=${therapist}&type=${type}`,
    method: 'GET'
  })
});

export const getMessageBoardProviders = (owner, isArchive = false) => ({
  [API_REQUEST]: createAction(GET_MESSAGEBOARD_PROVIDERS)({
    resource: `message-board-providers?owner=${owner}&isArchive=${isArchive}`,
    method: 'GET'
  })
});

export const setMessageBoardProviders = (payload) => ({
  [API_REQUEST]: createAction(SET_MESSAGEBOARD_PROVIDERS)({
    resource: `message-board-providers`,
    method: 'POST',
    body: payload
  })
});

export const GET_FAVORITES = 'GET_FAVORITES';

export const getFavorites = (id) => ({
  [API_REQUEST]: createAction(GET_FAVORITES)({
    resource: `favorite?favoriteId=${id}`,
    method: 'GET'
  })
});

export const POST_FAVORITES = 'POST_FAVORITES';

export const postFavorites = (sub) => ({
  [API_REQUEST]: createAction(POST_FAVORITES)({
    resource: `favorite`,
    method: 'POST',
    body: { sub }
  })
});

export const PUT_FAVORITES = 'PUT_FAVORITES';

export const putFavorites = (id, exercises) => ({
  [API_REQUEST]: createAction(PUT_FAVORITES)({
    resource: `favorite`,
    method: 'PUT',
    body: { favoriteId: id, exercises: exercises }
  })
});

export const DELETE_FAVORITES = 'DELETE_FAVORITES';

export const deleteFavorites = (favoriteId, exerciseId) => ({
  [API_REQUEST]: createAction(DELETE_FAVORITES)({
    resource: `favorite`,
    method: 'DELETE',
    body: { favoriteId: favoriteId, exerciseId: exerciseId }
  })
});

export const GET_VALIDATION_LINK = 'GET_VALIDATION_LINK';

export const getValidationLink = (input) => ({
  [API_REQUEST]: createAction(GET_VALIDATION_LINK)({
    resource: `validation?input=${encodeURIComponent(input)}`,
    method: 'GET'
  })
});

export const searchPatients = (groupName, searchTerm, id) => ({
  [API_REQUEST]: createAction(SEARCH_PATIENTS)({
    resource: `groups/${groupName}/patients?query=${searchTerm}&groupId=${id}&action=${opensearchActions.query}`,
    method: 'GET'
  })
});

export const dischargePatient = (
  groupName,
  sub,
  groupId,
  discharge,
  cancelDate
) => ({
  [API_REQUEST]: createAction(DISCHARGE_PATIENT)({
    resource: `groups/${groupName}/patients/discharge`,
    method: 'POST',
    body: { sub, groupId, discharge, cancelDate }
  })
});

export const updatePatientProfile = (payload) => ({
  [API_REQUEST]: createAction(EDIT_PATIENT_PROFILE)({
    resource: `groups/${payload.group}/users/updateProfile`,
    method: 'PUT',
    body: { ...payload }
  })
});

export const postPatient = (groupName, patient) => {
  let patientObj = { ...patient };

  patientObj.firstName = patient.firstName.toLowerCase();
  patientObj.lastName = patient.lastName.toLowerCase();

  if (patient.emailAddress) {
    patientObj.emailAddress = patient.emailAddress.toLowerCase();
  }

  return {
    [API_REQUEST]: createAction(POST_PATIENT)({
      resource: `groups/${groupName}/users?role=p`,
      method: 'POST',
      body: { ...patientObj, role: 'p' }
    })
  };
};

export const DELETE_PATIENT = 'DELETE_PATIENT';

export const deletePatient = (groupName, profile) => {
  return {
    [API_REQUEST]: createAction(DELETE_PATIENT)({
      resource: `groups/${groupName}/patients`,
      method: 'DELETE',
      body: { ...profile }
    })
  };
};

export const getCurrentWeekDates = (week) => {
  let startDayOfWeek;
  let endDayOfWeek;
  if (week > 0) {
    startDayOfWeek = moment().add(week, 'weeks').startOf('isoWeek');
    endDayOfWeek = moment().add(week, 'weeks').endOf('isoWeek');
  } else if (week < 0) {
    startDayOfWeek = moment().subtract(-week, 'weeks').startOf('isoWeek');
    endDayOfWeek = moment().subtract(-week, 'weeks').endOf('isoWeek');
  } else {
    startDayOfWeek = moment().startOf('isoWeek');
    endDayOfWeek = moment().endOf('isoWeek');
  }
  const arrayOfDatesCurrentInWeek = [];
  while (startDayOfWeek < endDayOfWeek) {
    arrayOfDatesCurrentInWeek.push({
      date: startDayOfWeek.format('MM/DD/YYYY'),
      dayN: startDayOfWeek.format('DD'),
      dayS: startDayOfWeek.format('ddd'),
      month: startDayOfWeek.format('MMMM')
    });
    startDayOfWeek = startDayOfWeek.add(1, 'days');
  }
  return arrayOfDatesCurrentInWeek;
};

export const getPatient = (query, input) => ({
  [API_REQUEST]: createAction(GET_PATIENT)({
    resource: `users?${query}=${encodeURIComponent(input)}&role=p`,
    method: 'GET'
  })
});

export const updatePatient = (groupName, user) => ({
  [API_REQUEST]: createAction(UPDATE_PATIENT)({
    resource: `groups/${groupName}/users?role=p`,
    method: 'PUT',
    body: { ...user, role: 'p' }
  })
});

const UPDATE_PATIENT_EMAIL = 'UPDATE_PATIENT_EMAIL';

export const updatePatientEmail = (groupName, user, oldEmail) => {
  let newEmail = user.emailAddress;
  return {
    [API_REQUEST]: createAction(UPDATE_PATIENT_EMAIL)({
      resource: `groups/${groupName}/users?role=p`,
      method: 'PUT',
      body: { ...user, role: 'p', emailAddress: oldEmail, newEmail }
    })
  };
};

export const NEW_PATIENT_EMAIL = 'NEW_PATIENT_EMAIL';

export const newPatientEmail = (groupId, sub, ptFname, ptLname) => ({
  [API_REQUEST]: createAction(NEW_PATIENT_EMAIL)({
    resource: `groups/${groupId}/user-email`,
    method: 'POST',
    body: {
      EmailType: 'newPatient',
      Sub: sub,
      GroupId: groupId,
      PTFirstName: ptFname,
      PTLastName: ptLname
    }
  })
});

export const GET_ANALYTICS_DATA = 'GET_ANALYTICS_DATA';
export const getAnalyticsData = (groupName, groupId, providers) => ({
  [API_REQUEST]: createAction(GET_ANALYTICS_DATA)({
    resource: `groups/${groupName}/rtm?groupId=${groupId}${providers}`,
    method: 'GET'
  })
});

export const TOGGLE_RTM = 'TOGGLE_RTM';
export const toggleRTM = (groupName, emailAddress, enableRTM, provider) => ({
  [API_REQUEST]: createAction(TOGGLE_RTM)({
    resource: `groups/${groupName}/rtm`,
    method: 'PUT',
    body: {
      emailAddress,
      enableRTM,
      provider
    }
  })
});

export const GET_PATIENT_DATAPOINTS = 'GET_PATIENT_DATAPOINTS';
export const getPatientDataPoints = (groupName, sub, groupId, query) => ({
  [API_REQUEST]: createAction(GET_PATIENT_DATAPOINTS)({
    resource: `groups/${groupName}/rtm/datapoints?sub=${sub}&groupId=${groupId}&query=${encodeURIComponent(
      JSON.stringify(query)
    )}&offset=${new Date().getTimezoneOffset()}`,
    method: 'GET'
  })
});

export const GET_CLINIC_MONITOR_LOGS = 'GET_CLINIC_MONITOR_LOGS';
export const getClinicMonitorLogs = (groupName, sub, groupId, query) => ({
  [API_REQUEST]: createAction(GET_CLINIC_MONITOR_LOGS)({
    resource: `groups/${groupName}/rtm/cliniclogs?sub=${sub}&groupId=${groupId}&query=${encodeURIComponent(
      JSON.stringify(query)
    )}&offset=${new Date().getTimezoneOffset()}`,
    method: 'GET'
  })
});

export const DELETE_CLINIC_MONITOR_LOGS = 'DELETE_CLINIC_MONITOR_LOGS';
export const deleteClinicMonitorLogs = (groupName, logId, sub) => ({
  [API_REQUEST]: createAction(DELETE_CLINIC_MONITOR_LOGS)({
    resource: `groups/${groupName}/rtm/cliniclogs/${logId}/${sub}`,
    method: 'DELETE'
  })
});

export const GET_RTM_BILLING = 'GET_RTM_BILLING';
export const getRTMBilling = (groupName, sub, groupId, query) => ({
  [API_REQUEST]: createAction(GET_RTM_BILLING)({
    resource: `groups/${groupName}/rtm/billing?sub=${sub}&groupId=${groupId}&query=${encodeURIComponent(
      JSON.stringify(query)
    )}&offset=${new Date().getTimezoneOffset()}`,
    method: 'GET'
  })
});

export const SET_MANUAL_TIMER = 'SET_MANUAL_TIMER';
export const setManualTimer = (
  groupName,
  emailAddress,
  groupId,
  sub,
  duration,
  activity,
  date,
  provider,
  notes
) => ({
  [API_REQUEST]: createAction(SET_MANUAL_TIMER)({
    resource: `groups/${groupName}/rtm/timer`,
    method: 'POST',
    body: {
      emailAddress,
      groupId,
      sub,
      duration,
      activity,
      date,
      provider,
      notes
    }
  })
});

export const START_LOADING_TIMER = 'START_LOADING_TIMER';
export const startLoadingTimer = createAction(START_LOADING_TIMER);

export const STOP_LOADING_TIMER = 'STOP_LOADING_TIMER';
export const stopLoadingTimer = createAction(STOP_LOADING_TIMER);

export const GET_RTM_BILLING_DOCUMENT = 'GET_RTM_BILLING_DOCUMENT';
export const getRTMDocument = (
  groupName,
  id,
  sub,
  code,
  status,
  patient,
  biller,
  additionalQuery
) => ({
  [API_REQUEST]: createAction(GET_RTM_BILLING_DOCUMENT)({
    resource: `groups/${groupName}/rtm/billing/documents?id=${id}&sub=${sub}&code=${code}&status=${status}&patient=${patient}&biller=${biller}&offset=${new Date().getTimezoneOffset()}&${additionalQuery}`,
    method: 'GET'
  })
});

export const GET_PATIENT_ANALYTICS_DATA = 'GET_PATIENT_ANALYTICS_DATA';
export const getPatientAnalyticsData = (groupName, sub, groupId) => ({
  [API_REQUEST]: createAction(GET_PATIENT_ANALYTICS_DATA)({
    resource: `groups/${groupName}/rtm?sub=${sub}&groupId=${groupId}`,
    method: 'GET'
  })
});

export const SET_PATIENT_ANALYTICS_DATA = 'SET_PATIENT_ANALYTICS';
export const setPatientAnalyticsData = (data) =>
  createAction(SET_PATIENT_ANALYTICS_DATA)(data);

export const CHECK_UNREAD_MESSAGES = 'CHECK_UNREAD_MESSAGES';
export const checkUnreadMessages = (unread) =>
  createAction(CHECK_UNREAD_MESSAGES)(unread);

export const POST_CARE_PLAN = 'POST_CARE_PLAN';
export const postCarePlan = (groupName, payload) => ({
  [API_REQUEST]: createAction(POST_CARE_PLAN)({
    resource: `groups/${groupName}/care-plan`,
    method: 'POST',
    body: payload
  })
});

export const GET_ALL_PROGRAMS = 'GET_ALL_PROGRAMS';
export const getAllPrograms = (groupName) => ({
  [API_REQUEST]: createAction(GET_ALL_PROGRAMS)({
    resource: `groups/${groupName}/care-plan?action=GET_PROGRAMS`,
    method: 'GET'
  })
});

export const GET_PLAN = 'GET_PLAN';
export const getPlan = (groupName, sub) => ({
  [API_REQUEST]: createAction(GET_PLAN)({
    resource: `groups/${groupName}/care-plan?action=GET_PLAN&sub=${sub}`,
    method: 'GET'
  })
});

export const GET_NPS = 'GET_NPS';
export const getNetPromoterScore = (sub) => ({
  [API_REQUEST]: createAction(GET_NPS)({
    resource: `nps-survey?sub=${sub}`,
    method: 'GET'
  })
});

export const SET_CARE_PLAN_PROPERTIES = 'SET_CARE_PLAN_PROPERTIES';
export const setCarePlanProperties = (data) =>
  createAction(SET_CARE_PLAN_PROPERTIES)(data);

export const UPDATE_TIMER_LOG_QUERY = 'UPDATE_TIMER_LOG_QUERY';
export const updateTimerLogQuery = (query) =>
  createAction(UPDATE_TIMER_LOG_QUERY)(query);
