import { atom } from 'recoil';

const defaultState = {
  list: [],
  form: null,
  action: ''
};

const PRESCRIPTION_STATE = atom({
  key: 'PRESCRIPTION_STATE',
  default: defaultState
});

export default PRESCRIPTION_STATE;
