import apiFetch from '../utils/fetch.utils';

export const searchExercises = async (groupId, term, filter, abortOptions) => {
  try {
    const uri = `groups/${groupId}/exercises/query/?search=${
      term || 'NONE'
    }&filter=${filter || 'NONE'}&groupId=${groupId}&includePTWired=true`;
    const method = 'GET';

    return apiFetch(uri, method, undefined, abortOptions);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addFavoriteId = async (sub) => {
  try {
    const uri = `favorite`;
    const method = 'POST';
    const payload = { body: { sub } };
    const response = await apiFetch(uri, method, payload);

    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getFavorites = async (favoriteId) => {
  try {
    const uri = `favorite?favoriteId=${favoriteId}`;
    const method = 'GET';
    const response = await apiFetch(uri, method);

    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addFavorite = async (favoriteId, exercises) => {
  try {
    const uri = `favorite`;
    const method = 'PUT';
    const payload = { body: { favoriteId, exercises } };
    const response = await apiFetch(uri, method, payload);

    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteFavorite = async (favoriteId, exerciseId) => {
  try {
    const uri = `favorite`;
    const method = 'DELETE';
    const payload = { body: { favoriteId, exerciseId } };
    const response = await apiFetch(uri, method, payload);

    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addTag = async (
  groupId,
  sub,
  exerciseId,
  exerciseName,
  customName,
  tag
) => {
  try {
    const uri = `exercises/tags`;
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        groupId,
        provider: sub,
        id: exerciseId,
        name: exerciseName,
        customName,
        tag
      }
    });
  } catch (error) {
    console.log(err);
    throw err;
  }
};

export const addClinicExercise = async (exercise) => {
  try {
    const uri = `groups/${exercise.GroupId}/exercises`;
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        ...exercise
      }
    });
  } catch (error) {
    console.log(err);
    throw err;
  }
};

export const updateClinicExercise = async (exercise) => {
  try {
    const uri = `groups/${exercise.GroupId}/exercises`;
    const method = 'PUT';

    return apiFetch(uri, method, {
      body: {
        ...exercise
      }
    });
  } catch (error) {
    console.log(err);
    throw err;
  }
};

export const getCustomExercises = async (sub) => {
  try {
    const uri = `exercises/custom?type=exercise&sub=${sub}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(err);
    throw err;
  }
};

export const addCustomExercise = async (exercise) => {
  try {
    const uri = 'exercises/custom';
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        ...exercise,
        type: 'exercise'
      }
    });
  } catch (error) {
    console.log(err);
    throw err;
  }
};

export const deleteCustomExercise = async (id, sub) => {
  try {
    const uri = 'exercises/custom';
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        type: 'exercise',
        action: 'delete',
        id,
        sub
      }
    });
  } catch (error) {
    console.log(err);
    throw err;
  }
};

export const getCustomParameter = async (sub) => {
  try {
    const uri = `exercises/custom?type=parameter&sub=${sub}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(err);
    throw err;
  }
};

export const addCustomParameters = async (payload) => {
  try {
    const uri = 'exercises/custom';
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        ...payload,
        type: 'parameter'
      }
    });
  } catch (error) {
    console.log(err);
    throw err;
  }
};

export const deleteCustomParameter = async (id, sub) => {
  try {
    const uri = 'exercises/custom';
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        type: 'parameter',
        action: 'delete',
        id,
        sub
      }
    });
  } catch (error) {
    console.log(err);
    throw err;
  }
};

export const getExerciseDetails = async (id, groupId = '') => {
  try {
    const uri =
      `exercises/details?id=${id}` + (groupId ? `&groupId=${groupId}` : '');
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    console.log(err);
    throw err;
  }
};
