import React, { Fragment, useState } from 'react';
import { Modal, Button, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ExportHepToPrompt from '../../export-hep-to-prompt';

const ExportHEPModal = props => {
  const { togglExportHEPModal, exercises, setExportData, loading } = props;
  const [hepData, setHepData] = useState([]);

  const handleSetHepData = data => {
    const finalExportData = data
      .filter(dt => dt.export === 'YES')
      .map(dt => ({
        Id: dt.id,
        CompletionDates: dt.completionDates,
        CptCode: dt.cptCode ? Number(dt.cptCode) : null,
        ExerciseName: dt.exerciseName,
        Frequency: dt.frequency,
        Hold: dt.hold,
        Instructions: dt.instructions,
        Minutes: dt.minutes || null,
        Perdiem: dt.perdiem,
        Reps: dt.reps,
        Sets: dt.sets,
        Video: dt.video,
      }));

    setHepData(finalExportData);
  };

  return (
    <Modal
      className="export-hep-modal"
      title={
        <Typography.Title level={4} style={{ marginBottom: -8 }}>
          <img
            src="https://ptw-mobile-images.s3.amazonaws.com/prompt/Logo_iso.png"
            alt="prompt-logo"
            style={{ height: 40, width: 'auto' }}
          />{' '}
          Export to Visit in Prompt
        </Typography.Title>
      }
      visible={true}
      onCancel={togglExportHEPModal}
      footer={[
        <Button
          key="cancel"
          size="large"
          type="default"
          className="ptw-btn"
          onClick={togglExportHEPModal}
        >
          Cancel
        </Button>,
        <Button
          key="export"
          size="large"
          type="primary"
          className="ptw-btn btn-primary"
          onClick={() => {
            if (hepData.length > 0) {
              setExportData(hepData);
            }
          }}
          disabled={hepData.length === 0 || loading}
        >
          {loading && (
            <Fragment>
              <LoadingOutlined className="text-icon" />
              &nbsp;
            </Fragment>
          )}
          Export HEP
        </Button>,
      ]}
      width={1000}
    >
      <ExportHepToPrompt exercises={exercises} setHepData={handleSetHepData} />
    </Modal>
  );
};

export default ExportHEPModal;
