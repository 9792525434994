import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Breadcrumb, OverlayTrigger, Popover } from 'react-bootstrap';
import { Alert, Typography } from 'antd';
import PushNotificationsForm from './PushNotificationsForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PushNotificationImg from '../../img/PNDesign.png';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { sendGroupNotification } from './actions';
import setNotification from '../../utils/setNotification.utils';

//Events for backend
const EDIT_PUSH_NOTIF_ANNOUNCEMENT = 'EDIT_PUSH_NOTIF_ANNOUNCEMENT';

const popContent = (
  <Popover id="popover-basic">
    <Row>
      <Col sm={6}>
        <strong>Create Push Notification</strong>
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        Send customized push notification out to all patients who have
        downloaded the app and enabled push notifications.
      </Col>
      <Col sm={12}>
        <img
          src={PushNotificationImg}
          alt="Push Notification"
          style={{ width: '75%', margin: 'auto' }}
        />
      </Col>
    </Row>
  </Popover>
);

const PushNotificationsPage = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [pushNotifAnnouncement, setPushNotifAnnouncement] = useState({
    title: '',
    message: '',
    silent: false
  });

  //get saved information
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setErrorMessage(null);
      }, 5000);
    }
  }, [error]);

  // handle form submit functions
  const handleSubmitPushNotification = async () => {
    const { groupId, emailAddress, dispatch } = props;

    setIsLoading(true);

    await dispatch(
      sendGroupNotification({
        ...pushNotifAnnouncement,
        groupId,
        sender: emailAddress
      })
    )
      .then(() => {
        setNotification(
          'success',
          'Push Notification Sent Successfully!',
          'Patients in this group will be notified with your message.'
        );
      })
      .catch(() => {
        setNotification(
          'error',
          'Push Notification Sending Failed.',
          'Push notification services are temporarily unavailable, please try again later.'
        );
      });

    setIsLoading(false);
  };

  return (
    <div style={{ padding: '15px' }}>
      {error && <Alert message={errorMessage} type="error" />}
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={'/administrator'}>Admin</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>iOS Push Notifications</Breadcrumb.Item>
        </Breadcrumb>
        <Breadcrumb>
          <Typography.Title level={3}>
            Create Push Notification&nbsp;
            <OverlayTrigger
              trigger={['hover']}
              placement="right"
              overlay={popContent}
              delayShow={500}
              delayHide={1500}
            >
              <span style={{ fontSize: '12px', verticalAlign: 'super' }}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            </OverlayTrigger>
          </Typography.Title>
        </Breadcrumb>
        <PushNotificationsForm
          pushNotifAnnouncement={pushNotifAnnouncement}
          setPushNotifAnnouncement={setPushNotifAnnouncement}
          event={EDIT_PUSH_NOTIF_ANNOUNCEMENT}
          handleSubmitCustomization={handleSubmitPushNotification}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default PushNotificationsPage;
