import React, { Fragment } from 'react';
import { formatDateTime } from './mixins';
import BillingCard from './BillingCard';
import NoResults from '../../../../../components/NoResults';

export default function BillingLog({
  rtmBillingData,
  getBillingDocument,
  billingDocument,
  refreshBillingList,
  patient
}) {
  const handleRefreshBillingList = (updated) => {
    refreshBillingList(updated);
  };

  return (
    <Fragment>
      {rtmBillingData && (
        <Fragment>
          {rtmBillingData.length === 0 ? (
            <div className="patient-detail-tab-body-error-container">
              <NoResults content="Billable codes will show up here once requirements are met!" />
            </div>
          ) : (
            <div className="patient-detail-tab-billing-container">
              {rtmBillingData.map((data, i) => {
                const hours = 23 * 60 * 60 * 1000;
                const minutes = 59 * 60 * 1000;
                const seconds = 59 * 1000;

                const isoStart = new Date(data.Start).toISOString();
                const startDate = isoStart.substring(0, isoStart.indexOf('T'));
                const start =
                  data.Code === 98980 || data.Code === 98981
                    ? formatDateTime(startDate).date
                    : new Date(
                        isoStart.substring(0, isoStart.indexOf('T'))
                      ).toLocaleDateString('en-US', {
                        dateStyle: 'long'
                      });

                const isoEnd = new Date(data.End).toISOString();
                const endDate = isoEnd.substring(0, isoEnd.indexOf('T'));
                const end =
                  data.Code === 98980 || data.Code === 98981
                    ? formatDateTime(endDate).date
                    : new Date(
                        Date.parse(isoEnd.substring(0, isoEnd.indexOf('T'))) +
                          hours +
                          minutes +
                          seconds
                      ).toLocaleDateString('en-US', {
                        dateStyle: 'long'
                      });

                let startQuery = data.Start;
                let endQuery = data.End + hours + minutes + seconds;
                if (data.Code === 98980 || data.Code === 98981) {
                  const offset = new Date().getTimezoneOffset();
                  startQuery = startQuery + offset * 60000;
                  endQuery = endQuery + offset * 60000;
                }

                const hasPending = rtmBillingData
                  .filter(({ Id }) => Id !== data.Id)
                  .some(({ Status }) => Status === 'marked');

                return (
                  <Fragment key={i}>
                    <BillingCard
                      date={`${start.split(',')[0]} - ${end}`}
                      bill_id={data.Id}
                      bill_number={data.Code}
                      bill_date={data.BillDate}
                      billed_by={data.BilledBy}
                      status={data.Status}
                      currentPeriod={data.CurrentPeriod}
                      getBillingDocument={() =>
                        getBillingDocument(
                          data.Id,
                          data.Code,
                          data.Status,
                          startQuery,
                          endQuery,
                          hasPending
                        )
                      }
                      billingDocument={billingDocument}
                      refreshBillingList={handleRefreshBillingList}
                      patient={patient}
                    />
                  </Fragment>
                );
              })}
            </div>
          )}
        </Fragment>
      )}
      <div className="patient-detail-tab-pagination" />
    </Fragment>
  );
}
